import { useEffect, useState } from "react";
import useStatsListener from "./useStatsListener";
import { FsDeckStatData } from "../../shared/types";
import { ceilDate, floorDate } from "../../shared/misc";

const sumPlus = (a: number, b: number) => a + b;
const sumConcat = <T>(a: T[], b: T[]) => [...a, ...b];

export type DeckStatsOp<T> = {
  data: (s: DeckStats) => [number, T][]; // [date, underlying data T]
  sum: (a: T, b: T) => T;
};

export const StatsMaturityOp: DeckStatsOp<[string, number][]> = {
  data: (s: DeckStats) => Array.from(s.maturity),
  sum: sumConcat,
};

export const StatsRepetitionOp: DeckStatsOp<number> = {
  data: (s: DeckStats) => s.repetition,
  sum: sumPlus,
};

export const StatsDueOp: DeckStatsOp<number> = {
  data: (s: DeckStats) => s.due,
  sum: sumPlus,
};

export type DeckStats = {
  deckId: string;
  maturity: Map<number, [string, number][]>; // <date, [cardId, diff][]>
  repetition: [number, number][]; // [date, repetition count][]
  due: [number, number][]; // [date, count][]
};

function useStatsParser(): DeckStats[] {
  const rawStats = useStatsListener();
  const [data, setData] = useState<DeckStats[]>([]);

  useEffect(() => {
    const newData = rawStats.map(({ deckId, data }): DeckStats => {
      const { maturity, repetition, due } = parseDeckStatsData(data);
      return { deckId, maturity, repetition, due };
    });
    setData(newData);
  }, [rawStats]);

  return data;
}

function parseDeckStatsData(data: Map<number, FsDeckStatData[]>) {
  const maturity: Map<number, [string, number][]> = new Map();
  const repetition: [number, number][] = [];

  const statsItor = data.entries();
  let statsRes = statsItor.next();
  if (statsRes.done) return { maturity, repetition, due: [] };

  // Iterating through stats will begin from the earliest date -
  // stats are sorted out in ascending order based on date
  let dateItor = new Date(statsRes.value[0]);
  const cards = new Map<string, { diff: number; due: number }>();

  // Parsing of data is done using two iterators. First (dateItor) iterates
  // day by day starting from the earliest date for a given deck.
  // The other iterates through deck's stats entries and moves to the
  // next entry only when it's date is equal to the date of the main
  // "date iterator"
  while (!statsRes.done) {
    const [date, stats] = statsRes.value;
    if (dateItor.getTime() === date) {
      stats.forEach(({ cardId, ...rest }) => cards.set(cardId, rest));
      statsRes = statsItor.next();
    }

    maturity.set(
      dateItor.getTime(),
      Array.from(cards).map(([date, { diff }]) => [date, diff])
    );

    const repFound = data.get(dateItor.getTime());
    repetition.push([dateItor.getTime(), repFound ? repFound.length : 0]);

    dateItor.setDate(dateItor.getDate() + 1);
  }

  const due = prepareDue(Array.from(cards).map(([id, { due }]) => [id, due]));

  return { maturity, repetition, due };
}

function prepareDue(data: [string, number][]) {
  const now = ceilDate(new Date()).getTime();
  const due = data
    // Taking into account the data from tomorrow's date onwards as we're
    // interested only with the repetitions numbers that will happen in future
    .filter(([cardId, due]) => due > now)
    .reduce((acc, [cardId, due]) => {
      const flooredDate = floorDate(new Date(due)).getTime();
      const cardsCount = acc.get(flooredDate) ?? 0;
      return acc.set(flooredDate, cardsCount + 1);
    }, new Map<number, number>());

  return Array.from(due);
}

export default useStatsParser;
