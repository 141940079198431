import { useState } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import CardMedia, { CardMediaProps } from "@mui/material/CardMedia";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import fallbackBookCoverImg from "../../assets/bookcover.png";
import LazyBackgroundImage from "../LazyBackgroundImage/LazyBackgroundImage";

const ComponentImage: React.FC<BoxProps<"img">> = (props) => (
  <Box component="img" {...props} />
);
const ComponentCardMedia: React.FC<CardMediaProps<"img">> = (props) => (
  <CardMedia component="img" {...props} />
);

// TODO: add alt: string?
type BookCoverProps = {
  coverUrl?: string;
  component?: "image" | "cardmedia" | "background";
  disabled?: boolean;
  shadow?: boolean;
  sx?: BoxProps["sx"];
};

export const BookCover: React.FC<BookCoverProps> = ({
  coverUrl,
  component = "image",
  disabled = false,
  shadow,
  sx,
}) => {
  const { theme, isDecolorized } = useCustomTheme();
  const [imgUrl, setImgUrl] = useState(coverUrl);

  if (component === "background") {
    return (
      <LazyBackgroundImage
        alt="Book cover"
        src={imgUrl!}
        fallback={fallbackBookCoverImg}
        isDecolorized={isDecolorized}
        disabled={disabled}
        sx={sx}
      />
    );
  }

  const Component = component === "image" ? ComponentImage : ComponentCardMedia;

  return (
    <Component
      loading="lazy"
      src={imgUrl}
      draggable={false}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        // currentTarget.src = fallbackBookCoverImg;
        setImgUrl(fallbackBookCoverImg);
      }}
      sx={{
        margin: "auto",
        width: "auto",
        height: "128px",
        objectFit: "contain",
        filter: isDecolorized || disabled ? "grayscale(1)" : "none",
        borderRadius: theme.shape.borderRadius * 0.1,
        ...(shadow ? { boxShadow: "0px 0px 5px #0007" } : {}),
        ...sx,
      }}
    />
  );
};
