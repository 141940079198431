import {
  AddressElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAuth } from "../../contexts/AuthContext";
import { tSubscribeReq, tSubscribeRes } from "../../shared/types";
import { RefObject, useState } from "react";

function useSubmit(email: RefObject<string>, name: RefObject<string>) {
  const stripe = useStripe();
  const elements = useElements();
  const { callCloudFn } = useAuth();
  const [processing, setProcessing] = useState(false);

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe is not initialized.");
      return null;
    }

    // Use Stripe Elements to securely collect card details
    // and create a payment method
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      console.error("Card element not found.");
      return null;
    }

    const addressElement = elements.getElement(AddressElement);
    const a = (await addressElement!.getValue()).value;
    /**
 onst a: {
    name: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    address: {
        line1: string;
        line2: string | null;
        city: string;
        state: string;
        postal_code: string;
        country: string;
    };
    phone?: string | undefined;
}
  
 */
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: { address: a.address as any },
      // billing_details: { email: email.current!, name: name.current! },
    });

    if (error) {
      console.error("Error creating payment method:", error);
      return null;
    }

    // Send the payment method to your server to create a subscription
    const paymentMethodId = paymentMethod?.id;
    console.log(paymentMethodId);

    // Send additional information like cardholder name, billing address, etc.

    // Make an API request to your server with the payment method details
    // to create a subscription
    // Example: fetch("/create-subscription", { method: "POST", body: { paymentMethodId, ... } })
    setProcessing(true);
    const res = await callCloudFn<tSubscribeReq, tSubscribeRes>("subscribe", {
      paymentMethodId: paymentMethodId,
    });
    setProcessing(false);

    console.log(res);

    return res.data;
  };

  return { processing, submit };
}

export default useSubmit;
