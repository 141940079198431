import { styled } from "@mui/material/styles";
import { Box, alpha } from "@mui/material/";
import useMobileView from "../hooks/useMobileView";

interface CustomScrollBoxProps {
  marginTop?: number;
  marginBottom?: number;
  width?: number;
}

export const CustomScrollBox = styled(Box)<{ scroll: CustomScrollBoxProps }>(
  ({ theme, scroll: { marginTop = 0, marginBottom = 0, width = 8 } }) => {
    const isMobile = useMobileView();

    return !isMobile
      ? {
          overflowY: "auto",
          "&::-webkit-scrollbar-track-piece": {
            marginTop: `${marginTop}px`,
            marginBottom: `${marginBottom}px`,
          },
          "&::-webkit-scrollbar": {
            width: `${width}px`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: alpha(theme.palette.action.selected, 0.4),
            borderRadius: `${theme.shape.borderRadius}px`,
          },
        }
      : undefined;
  }
);
