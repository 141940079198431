import * as React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { tInsertCardReq, tWordContext } from "../../shared/types";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useAuth } from "../../contexts/AuthContext";
import { useAlert } from "../../contexts/Alert/AlertContext";
import { useAppData } from "../../contexts/AppData/AppDataContext";

interface TermMatchEntryProps extends BoxProps {
  entry: tWordContext;
  onClick: () => void;
}

export const TermMatchEntry: React.FC<TermMatchEntryProps> = ({
  entry: { match, context, definition, example },
  onClick,
  ...rest
}) => {
  const { callCloudFn } = useAuth();
  const { showAlert } = useAlert();
  const { settings } = useAppData();
  const [disabled, setDisabled] = React.useState(false);

  const handleClick = async () => {
    showAlert(`Adding new card: ${match.lang}`);
    setDisabled(true);
    await callCloudFn<tInsertCardReq>("insertcard", {
      selection: match.lang,
      sentence: example,
      lang: settings.newCard.get.lang,
      targetLang: settings.newCard.get.targetLang,
    });
    onClick();
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        width: "100%",
        textAlign: "left",
        ...(disabled ? { color: ({ palette }) => palette.text.disabled } : {}),
      }}
      {...rest}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "150px",
          mr: 1,
          flexShrink: 0,
        }}
      >
        <Typography>{match.lang}</Typography>
        <Typography
          sx={{
            fontSize: "0.75rem",
            color: ({ palette }) =>
              disabled ? palette.text.disabled : palette.text.secondary,
          }}
        >
          {match.target}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontWeight: 500 }}>{definition}</Typography>
        {/* <Typography variant="body2" sx={{ textTransform: "uppercase", mt: 2 }}> */}
        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: ({ palette }) =>
                disabled ? palette.text.disabled : palette.text.secondary,
            }}
          >
            {context}
          </Typography>
          <Typography>{example}</Typography>
        </Box>
      </Box>
      <Tooltip title={"Add to deck"} placement="left">
        <IconButton
          disabled={disabled}
          onClick={handleClick}
          sx={{ m: "auto", flexShrink: 0 }}
        >
          <AddCardIcon
            sx={{
              ...(disabled
                ? { color: ({ palette }) => palette.text.disabled }
                : {}),
            }}
          />
        </IconButton>
      </Tooltip>
      {disabled && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translateX(-50%) translateY(-50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
