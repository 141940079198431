import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { tDeck } from "../../shared/types";
import Card from "@mui/material/Card";
import { CardActionArea, SxProps, Theme } from "@mui/material";
import { ConfigCog } from "./ConfigCog";
import { ConfigPanel } from "./ConfigPanel";
import { FrontCover } from "./FrontCover";
import { useAlert } from "../../contexts/Alert/AlertContext";
import { DialogPrepareDeck } from "../../dialogs/DialogPrepareDeck/DialogPrepareDeck";
import { getDeckNewCount, getDeckReviewCount } from "../../shared/deck";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { BookCover } from "../BookItem/BookCover";
import useFetchCards from "./useFetchCards";
import { FirebaseError } from "firebase/app";
import { Deck } from "../../contexts/AppData/DecksData";
import { c_err, c_log } from "../../shared/console";
import { DragHandle } from "./DragHandle";

export const DeckItem: React.FC<{
  deck: Deck;
  onDragged: (yOffset: number) => boolean;
  onDragEnd: () => void;
  sx?: SxProps<Theme>;
  cardProps?: SxProps<Theme>;
}> = ({ deck, onDragged, onDragEnd, sx, cardProps }) => {
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = React.useState(false);
  const refCard = React.useRef<HTMLDivElement>(null);
  const { showAlert, showDialog } = useAlert();
  const [newCount, setNewCount] = React.useState(0);
  const [reviewCount, setReviewCount] = React.useState(0);
  const [disabled, setDisabled] = React.useState(true);
  const [sync, setSync] = React.useState(false);
  const [prepareLesson, cancelFetching] = useFetchCards(deck.id);
  const { lesson } = useAppData();

  const [hovered, setHovered] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    const newSize = getDeckNewCount(deck);
    const reviewSize = getDeckReviewCount(deck);
    if (deck.config !== undefined) {
      setNewCount(newSize);
      setReviewCount(reviewSize);
    }
    setDisabled(newSize + reviewSize === 0);
    setSync(false);
  }, [deck, newCount, reviewCount]);

  React.useEffect(() => {
    setDisabled(disabled || sync);
    if (sync && showSettings) setShowSettings(false);
  }, [sync]);

  function prepareCardsForLesson() {
    const todayLessonCards = {
      review: deck.data.reviewCards.slice(0, reviewCount),
      new: deck.data.newCards.slice(0, newCount),
    };

    lesson.reset(deck.id, cancelFetching);

    // Fetching today's lesson cards split into two as cards that has already been
    // seen and are being reviewed are to be fetched from local or remote storage.
    // On the other hand, we have new cards that in the worst case scenario have to
    // be created on the go by open ai, which might take a while. In the best scenario
    // the cards has already been created in the previous session and only have to
    // be fetched from the local or remote database - that is the case when the user
    // hasn't finished the previous session

    const fetchingCardsPromises: (Promise<void> | null)[] = [
      prepareLesson(todayLessonCards.review),
      prepareLesson(todayLessonCards.new),
    ];

    return fetchingCardsPromises.filter(
      (promise) => promise !== null
    ) as Promise<void>[];
  }

  const handleClick = async (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      c_log(`Opening deck: ${deck.id}`);

      const fetchingCardsPromises = prepareCardsForLesson();

      // If we still have no lesson's card data then we have to wait for
      // the promise fetches that started above or at least for one of them.
      // This situation can happen if there's no local data on the current
      // user's device and data is being fetched from a remote server.
      // Upon receiving the data from one of the above promises we can start
      // the lesson. The data of the unfinished promise (most likely promise
      // of fetching new cards) can be merged in later on when received.

      if (lesson.cardsEmpty()) {
        const closeDialog = showDialog(
          <DialogPrepareDeck deck={deck} onCancelClick={cancelFetching} />
        );
        try {
          await Promise.any(fetchingCardsPromises);
          closeDialog();
        } catch (err) {
          closeDialog();
          lesson.clear();
          throw err;
        }
      }

      // Holding on to the "fetch cards" promises. Based on their status
      // we'll be able to continue or pause currently ongoing lesson (if
      // the new cards are still being generated by the open ai and the
      // user already reviewed his cards, we have to pause the session and
      // wait for the arrival of all the new cards belonging to that lesson)

      // decks.lesson.inWork = fetchingCardsPromises.map(
      //   ([_, promise]) => promise
      // );

      // In this moment we should have some cards in the deck's lesson. Additionally there
      // might be work still going on in the background which should fetch the rest
      // of the cards. Upon finishing the cards should be added to the ongoing lesson

      navigate("/learn");
    } catch (error) {
      c_err(error);

      // lesson.data.inWork = [];
      lesson.clear();

      const openAiFail =
        error instanceof FirebaseError &&
        error.code === "functions/resource-exhausted";

      showAlert(
        openAiFail
          ? "Servers overloaded. Try again"
          : "Couldn't start the lesson. Try again",
        "error"
      );
    }
  };

  return deck.data !== undefined ? (
    <Box
      component={"div"}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      sx={{
        position: "relative",
        ...(!dragging
          ? {
              transition: (theme) =>
                theme.transitions.create("top", { duration: "0.2s" }),
            }
          : {}),
        ...sx,
      }}
    >
      <DragHandle
        itemRef={refCard}
        visible={hovered}
        onDragStart={() => {
          setDragging(true);
          setHovered(false);
        }}
        onDrag={onDragged}
        onDragEnd={() => {
          onDragEnd();
          setDragging(false);
        }}
      />
      <Card
        ref={refCard}
        elevation={3}
        sx={{
          ...(lesson.deckId === deck.id ? { boxShadow: 10 } : {}),
          mx: 0,
          my: { xs: "0.5rem", sm: "1rem" },
          textAlign: "center",
          display: "flex",
          position: "relative",
          flexDirection: "row-reverse",
          // filter: synch ? "blur(5px)" : "none",
          width: "100%",
          ...(dragging
            ? {
                zIndex: 1000,
                border: ({ palette }) => `2px solid ${palette.secondary.dark}`,
              }
            : {
                transition: (theme) =>
                  theme.transitions.create("top", { duration: "0.2s" }),
              }),
          ...cardProps,
        }}
      >
        {(sync || lesson.hasPendingCardAnswer(deck.id)) && (
          <CircularProgress
            sx={{
              position: "absolute",
              left: "50%",
              alignSelf: "center",
              zIndex: 10,
            }}
          />
        )}
        <ConfigPanel
          deck={deck}
          show={showSettings}
          refOwner={refCard}
          onClose={(deckInSync) => setSync(deckInSync)}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          <ConfigCog
            show={showSettings}
            onClick={() => setShowSettings((prev) => !prev)}
          />
        </Box>
        <CardActionArea
          sx={{ p: "0.5rem", borderRadius: 0 }}
          disabled={disabled || lesson.hasPendingCardAnswer(deck.id)}
          onClick={handleClick}
        >
          <FrontCover
            deck={deck}
            newCount={newCount}
            reviewCount={reviewCount}
            disabled={disabled || lesson.hasPendingCardAnswer(deck.id)}
          />
        </CardActionArea>
        <BookCover
          component="background"
          coverUrl={deck.info.coverUrl}
          disabled={disabled || lesson.hasPendingCardAnswer(deck.id)}
        />
      </Card>
    </Box>
  ) : null;
};
