import React, { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import Typography from "@mui/material/Typography";
import { usePrimaryColor } from "./CardExamples";
import { tCard } from "../../shared/types";
import { LazyComponent } from "../LazyComponent";
import { SpeakerButton } from "./SpeakerButton";
import { CardAudioState } from "./useCardAudio";

interface WordProps extends PropsWithChildren {
  card: tCard;
  audioState: CardAudioState;
  onPlayAudio: () => void;
  onClick?: (text: string) => void;
  minimal?: boolean;
}

export const Word: FC<WordProps> = ({
  card,
  audioState,
  onPlayAudio,
  onClick,
  children,
  minimal,
}) => {
  const { settings } = useAppData();
  const color = usePrimaryColor();

  return minimal ? (
    <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
      <LazyComponent>
        <SpeakerButton
          audioState={audioState}
          onClick={onPlayAudio}
          sx={{ width: "36px", height: "36px" }}
        />
      </LazyComponent>
      <Typography
        onClick={() => onClick?.(card.word)}
        sx={{
          flexBasis: "100px",
          textAlign: "left",
          overflow: "clip",
          mr: 1,
          ...(onClick ? { cursor: "pointer" } : {}),
        }}
      >
        {card.word}
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        position: "relative",
        mx: "auto",
        textTransform: "uppercase",
        fontWeight: "bold",
      }}
    >
      <Typography
        onClick={() => {
          if (settings.compactView.get) onPlayAudio();
        }}
        sx={{
          fontSize: "2rem",
          fontWeight: "bold",
          color,
          cursor: settings.compactView.get ? "pointer" : undefined,
        }}
      >
        {!settings.compactView.get && (
          <SpeakerButton
            fontSize="medium"
            audioState={audioState}
            onClick={onPlayAudio}
            sx={{
              position: "absolute",
              left: "0",
              top: "50%",
              transform: "translateX(-100%) translateY(-50%)",
            }}
          />
        )}
        {card.word}
      </Typography>
      {children}
    </Box>
  );
};
