import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styled from "@mui/material/styles/styled";
import { useLocation, useNavigate } from "react-router-dom";
import useMobileView from "../../hooks/useMobileView";
import { MenuItem } from "./MenuItem";
import { useMenu } from "../../contexts/Alert/useMenu";
import LingyLogo from "../LingyLogo";

const deckWidth = { max: 200, min: 72 };

export const styleMenuDrawerAnim = (width: string) => {
  return {
    width: width,
    transition: "width 0.2s ease-out",
    "& .MuiDrawer-paper": {
      width: width,
      transition: "width 0.2s ease-out",
      border: "none",
      overflowX: "hidden",
    },
  };
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export interface MenuDrawerState {
  expanded: boolean;
  setExpanded: (open: boolean) => void;
  drawerWidth: number;
}

export const useMenuDrawer = (): MenuDrawerState => {
  const [expanded, setExpanded] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(deckWidth.max);
  const isMobile = useMobileView();

  return {
    expanded,
    setExpanded: (expand: boolean) => {
      setDrawerWidth(expand ? deckWidth.max : deckWidth.min);
      setExpanded(expand);
    },
    drawerWidth: isMobile ? 0 : drawerWidth,
  };
};

interface MenuDrawerProps {
  use: MenuDrawerState;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({ use }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const { expanded, setExpanded, drawerWidth } = use;
  const navigate = useNavigate();
  const location = useLocation();
  const menuItems = useMenu();

  useEffect(() => {
    const idx = menuItems.findIndex((item) => item.link === location.pathname);
    setSelectedItem(idx);
  }, [location]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={expanded}
      sx={{ ...styleMenuDrawerAnim(`${drawerWidth}px`) }}
    >
      <DrawerHeader>
        <LingyLogo
          sx={{
            transition: "opacity 0.2s ease-out",
            opacity: expanded ? 1 : 0,
          }}
        />
        <IconButton onClick={() => setExpanded(!expanded)} sx={{ mx: 1 }}>
          <ChevronLeftIcon
            sx={{ transform: !expanded ? "scaleX(-1)" : undefined }}
          />
        </IconButton>
      </DrawerHeader>
      <List disablePadding sx={{ flexGrow: 1 }}>
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              key={item.text}
              item={item}
              onClick={() => navigate(item.link)}
              focused={selectedItem === index}
              expanded={expanded}
            />
          );
        })}
      </List>
    </Drawer>
  );
};

export default MenuDrawer;
