import * as React from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { SubscribePage } from "../pages/SubscribePage/SubscribePage";

export const AuthGuard: React.FC<{
  home: JSX.Element;
  children?: React.ReactNode;
}> = ({ home, children }) => {
  const { user, subscribed } = useAuth();
  const outerContext = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [path, setPath] = React.useState(location.pathname);

  React.useEffect(() => {
    if (user && path !== "") {
      navigate(path !== "/" && path !== "/subscription" ? path : "/decks");
    }
  }, [user]);

  if (!user)
    return location.pathname !== "/" ? <Navigate to="/" replace /> : home;

  if (!subscribed) {
    return location.pathname !== "/subscription" ? (
      <Navigate to="/subscription" replace />
    ) : (
      <SubscribePage />
    );
  }

  return !children ? (
    <Outlet context={outerContext} />
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};
