import { tTouchRes } from "../../shared/types";
import { useEffect } from "react";
import { DialogSynchronizing } from "../../dialogs/DialogSynchronizing/DialogSynchronizing";
import useCloudFn from "../../hooks/useCloudFn";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { useVisibilityChange } from "../../hooks/useVisibilityChange";

type TouchInfo = { at: number; in: number };

export function useDecksSync() {
  const callTouch = useCloudFn<void, tTouchRes>("touch");
  const { decks } = useAppData();

  // Sending a request to the back-end for the deck's data to be refreshed,
  // that is cards that should be set for review as well as new cards that
  // should be taken into the account based on the current server-side day/date.
  // Returned value from the remote call indicates the time in ms in which
  // the next refresh action will be available
  const touchDecks = async () => {
    const res = await callTouch({
      dialog: <DialogSynchronizing />,
    });
    const now = new Date().valueOf();
    const touchInfo: TouchInfo = { at: now, in: res.data };
    localStorage.setItem("touch", JSON.stringify(touchInfo));
  };

  const decksSync = () => {
    const touch = localStorage.getItem("touch");

    if (!touch) touchDecks();
    else {
      const touchInfo: TouchInfo = JSON.parse(touch);
      const now = new Date().valueOf();
      // Send a refresh action if the time that has passed from the last
      // data refresh is greater then the time of the subsequent refresh that
      // was indicated by the server during the last refresh call in touchDecks
      if (now - touchInfo.at > touchInfo.in) touchDecks();
    }
  };

  useEffect(decksSync, []);
  useVisibilityChange(decksSync, []);

  return decks;
}
