import { Theme } from "@mui/material/styles";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { WithTranslation, withTranslation } from "react-i18next";

interface RepetitionsHeatmapProps extends WithTranslation {
  theme: Theme;
  height: number;
  stats: [number, number][]; // [date, repetition counts][]
}

interface RepetitionsHeatmapState {
  options?: ApexCharts.ApexOptions;
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
}

class RepetitionsHeatmap extends React.Component<
  RepetitionsHeatmapProps,
  RepetitionsHeatmapState
> {
  private chartRef: React.RefObject<HTMLDivElement> = React.createRef();
  private clientWidth: number = 0;

  constructor(props: RepetitionsHeatmapProps) {
    super(props);
    this.state = { series: this.generateSeries() };
  }

  handleResize = () => {
    this.clientWidth = this.chartRef.current!.getBoundingClientRect().width;
    this.setState((prev) => ({
      ...prev,
      options: this.generateOptions(),
    }));
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: RepetitionsHeatmapProps) {
    const themeChanged = prevProps.theme !== this.props.theme;
    const statsChanged = prevProps.stats !== this.props.stats;

    if (!themeChanged && !statsChanged) return;

    this.setState((prev) => ({
      ...prev,
      ...(themeChanged || statsChanged
        ? { options: this.generateOptions() }
        : {}),
      ...(statsChanged ? { series: this.generateSeries() } : {}),
    }));
  }

  generateOptions(): ApexCharts.ApexOptions {
    const weeksCount = this.clientWidth / 20; // 20 - aprox width of heat rectangle
    return {
      chart: {
        height: this.props.height,
        type: "heatmap",
        toolbar: { show: false },
        fontFamily: this.props.theme.typography.fontFamily,
        foreColor: this.props.theme.palette.text.primary,
      },
      colors: [this.props.theme.palette.secondary.main],
      xaxis: {
        categories: this.generateWeeksLabels(weeksCount),
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: [this.props.theme.palette.grey[900]],
          fontSize: "10px",
          fontWeight: 500,
        },
        formatter: (value, { seriesIndex, dataPointIndex, w }) =>
          value === 0 ? "" : value.toString(),
      },
      stroke: {
        width: 2,
        colors: [this.props.theme.palette.background.default],
      },
      tooltip: {
        enabled: true,
        // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        //   const color = this.props.theme.palette.grey[900];
        //   const bgColor = this.props.theme.palette.secondary.light;
        //   const count = series[seriesIndex][dataPointIndex];
        //   return `
        //   <div class="heatmap-tooltip"
        //   style="padding: 4px 4px; color: ${color}; background-color: ${bgColor}; font-weight: 500">
        //   ${count}
        //   </div>`;
        // },
      },
    };
  }

  generateSeries = () => {
    const { t } = this.props;

    const weeks: Array<number[]> = [];
    for (let i = 0; i < 7; i++) weeks.push([]);

    // Filling out first week's column with zero repetitions
    // prior to the first stats data of a given date
    if (this.props.stats.length > 0) {
      const dayIdx = new Date(this.props.stats[0][0]).getDay();
      for (let i = 0; i < dayIdx; i++) weeks[i].push(0);
    }

    this.props.stats.forEach(([date, repCount]) => {
      const dayOfWeek = new Date(date).getDay();
      weeks[dayOfWeek].push(repCount);
    });

    return [
      t("stats_page.sunday"),
      t("stats_page.monday"),
      t("stats_page.tuesday"),
      t("stats_page.wenesday"),
      t("stats_page.thursday"),
      t("stats_page.friday"),
      t("stats_page.saturday"),
    ]
      .map((v, idx) => ({ name: v, data: weeks[idx] }))
      .reverse();
  };

  generateWeeksLabels = (num: number) => {
    const { t } = this.props;

    const data: string[] = [];
    for (let i = 1; i <= num; ++i)
      data.push(i % 2 ? `${t("stats_page.week")} ${i}` : "");
    return data;
  };

  render() {
    return (
      <div ref={this.chartRef} id="repetitions-chart" style={{ width: "100%" }}>
        {this.state.options && (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="heatmap"
            height={this.props.height}
          />
        )}
      </div>
    );
  }
}

// function generateData(numRows: number) {
//   const data = [];

//   for (let row = 0; row < numRows; row++) {
//     const value = Math.floor(Math.random() * 100);
//     data.push(value);
//   }

//   return data;
// }

export default withTranslation()(RepetitionsHeatmap);
