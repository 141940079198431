import { useEffect } from "react";

export function useKeyDown(cb: (event: KeyboardEvent) => void) {
  useEffect(() => {
    window.addEventListener("keydown", cb, true);
    return () => {
      window.removeEventListener("keydown", cb, true);
    };
  }, [cb]);
}
