import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlashCard } from "../../components/FlashCard/FlashCard";
import useMobileView from "../../hooks/useMobileView";
import useCardAnswer from "./useCardAnswer";
import useDeck from "./useDeck";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { useTranslation } from "react-i18next";
import { strComparePercent } from "../../common/string";
import { useKeyDown } from "../../hooks/useKeyDown";

export const LearnPage: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "lesson" });
  const { lesson, settings } = useAppData();
  const [deckId] = useState<string | undefined>(lesson.deckId);
  const [showAnswer, setShowAnswer] = useState(false);
  const isMobile = useMobileView();
  const deck = useDeck(deckId); // TODO: useCurrentDeck ?
  const cardAnswer = useCardAnswer(deck!);
  const navigate = useNavigate();
  const [typedAnswer, setTypedAnswer] = useState("");
  const [recording, setRecording] = useState(false);

  // Preventing opening a lesson upon navigating back and forth using
  // browser's navigation actions. For example if the user has already
  // finished the lesson, it shouldn't be loaded as there is lesson data.
  // Also, if the user hasn't finished the learning session and navigated back
  // to decks list and removed the deck with the current learning session,
  // upon navigating back in history the lesson for a deck that just has been
  // removed shouldn't be loaded.
  useEffect(() => {
    // If deck has been removed or learning session was finished
    if (
      deck === undefined ||
      deckId === undefined ||
      (lesson.cardsEmpty() && !lesson.isFetchingCards())
    )
      navigate("/decks", { replace: true });
  }, [deckId, deck]);

  const inputBasedCardAnswer = () => {
    const answerDiff = strComparePercent(
      typedAnswer.toLowerCase(),
      lesson.getCurrentCard().word.toLowerCase()
    );
    const answer = answerDiff === 1 ? 2 : answerDiff > 0.7 ? 1 : 0;
    cardAnswer(answer, answer === 0);
    setShowAnswer(false);
  };

  useKeyDown(
    useCallback(
      (event: KeyboardEvent) => {
        const keys = settings.inputMode.get === "keys";
        if (showAnswer && keys && event.key === "Enter") {
          inputBasedCardAnswer();
        }
      },
      [showAnswer, settings.inputMode.get]
    )
  );

  const clickAnswer = async (answer: number, doNotRemove: boolean) => {
    await cardAnswer(answer, doNotRemove);
    setShowAnswer(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          alignContent: "center",
          overflow: "auto",
          flexGrow: isMobile ? 1 : 0,
        }}
      >
        {deck && !lesson.cardsEmpty() && (
          <FlashCard
            card={lesson.getCurrentCard()}
            deck={deck}
            answerShown={showAnswer}
            onBury={async () => {
              await cardAnswer(-1);
              setShowAnswer(false);
            }}
            onInputEnter={(typedAnswer) => {
              setTypedAnswer(typedAnswer);
              setShowAnswer(true);
            }}
            onRecording={(processing) => setRecording(processing)}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {!showAnswer && (
          <Button
            variant="contained"
            size="large"
            disabled={recording}
            sx={{ m: 1 }}
            onClick={(_) => setShowAnswer(true)}
          >
            {t("show")}
          </Button>
        )}
        {settings.inputMode.get === "buttons"
          ? showAnswer && (
              <React.Fragment>
                <AnswerButton
                  title={t("again")}
                  color="error"
                  onClick={() => clickAnswer(0, true)}
                />
                <AnswerButton
                  title={t("good")}
                  color="warning"
                  onClick={() => clickAnswer(1, false)}
                />
                <AnswerButton
                  title={t("great")}
                  color="success"
                  onClick={() => clickAnswer(2, false)}
                />
              </React.Fragment>
            )
          : showAnswer && (
              <Button
                variant="contained"
                size="large"
                sx={{ m: 1 }}
                onClick={(_) => inputBasedCardAnswer()}
              >
                Next
              </Button>
            )}
      </Box>
    </Box>
  );
};

const AnswerButton: FC<
  { title: string; onClick: () => void } & ButtonProps
> = ({ title, onClick, ...rest }) => (
  <Button
    variant="contained"
    size="large"
    onClick={onClick}
    sx={{ mx: 0.25, my: 0.5, flexGrow: { xs: 1, sm: 0 } }}
    {...rest}
  >
    {title}
  </Button>
);
