import { collection, onSnapshot, query } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { tDeckConfig, tDeckData, tDeckInfo } from "../shared/types";

function useOnSnapshotDeck<T extends tDeckData | tDeckConfig | tDeckInfo>(
  colDeckPart: string
) {
  const { user } = useAuth();

  // TODO: onRemoved? for snap.type === "removed"
  const execute = (
    onSnap: (deckId: string, data: T) => void,
    onModified?: (deckId: string, data: T) => void
  ) => {
    if (user === null) return;
    const q = query(collection(db, `users/${user.uid}/${colDeckPart}`));
    return onSnapshot(q, (querySnapshot) => {
      if (onModified) {
        querySnapshot
          .docChanges()
          .filter((snap) => snap.type === "modified")
          .forEach((snap) => onModified(snap.doc.id, snap.doc.data() as T));
      }

      querySnapshot.docs.forEach((docSnap) => {
        onSnap(docSnap.id, docSnap.data() as T);
      });
    });
  };

  return execute;
}

export default useOnSnapshotDeck;
