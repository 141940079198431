import React from "react";
import { Theme } from "@mui/material";
import ReactApexChart from "react-apexcharts";

interface CardsDueBarProps {
  theme: Theme;
  height: number;
  stats: [number, number][];
}

interface CardsDueBarState {
  options?: ApexCharts.ApexOptions;
  series?: ApexAxisChartSeries; // | ApexNonAxisChartSeries;
}

export class CardsDueBar extends React.Component<
  CardsDueBarProps,
  CardsDueBarState
> {
  constructor(props: CardsDueBarProps) {
    super(props);

    this.state = {
      series: this.generateSeries(),
      options: this.generateOptions(),
    };
  }

  componentDidUpdate(prevProps: CardsDueBarProps) {
    const themeChanged = prevProps.theme !== this.props.theme;
    const statsChanged = prevProps.stats !== this.props.stats;

    if (!themeChanged && !statsChanged) return;

    this.setState((prev) => ({
      ...prev,
      ...(themeChanged || statsChanged
        ? { options: this.generateOptions() }
        : {}),
      ...(statsChanged ? { series: this.generateSeries() } : {}),
    }));
  }

  generateSeries() {
    const dayInMs = 1000 * 60 * 60 * 24;
    return [
      {
        name: "Future",
        data: Array.from(this.props.stats).map(([, count]) => count),
      },
    ];
  }

  generateOptions(): ApexCharts.ApexOptions {
    return {
      chart: {
        height: this.props.height,
        stacked: true,
        toolbar: { show: false },
        zoom: { enabled: false },
        fontFamily: this.props.theme.typography.fontFamily,
        foreColor: this.props.theme.palette.text.primary,
      },
      colors: [
        this.props.theme.palette.secondary.light,
        this.props.theme.palette.secondary.main,
        this.props.theme.palette.secondary.dark,
      ],
      dataLabels: { enabled: true },
      grid: {
        borderColor: this.props.theme.palette.divider,
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: { size: 1 },
      xaxis: {
        type: "datetime",
        categories: this.props.stats.map(([date]) => {
          return new Date(date).toLocaleDateString("en-US") + " GMT";
        }),
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        //   "01/07/2011 GMT",
        // ],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 5,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: this.props.theme.shape.borderRadius,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                color: this.props.theme.palette.text.primary,
              },
            },
          },
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: { opacity: 1 },
    };
  }

  render() {
    return (
      <div id="maturity-chart" style={{ width: "100%" }}>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={this.props.height}
        />
      </div>
    );
  }
}
