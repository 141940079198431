import React from "react";
import { ChangeEvent, ReactNode } from "react";

type Props = {
  onFileSelected?: (selectedFiles: File | File[]) => void;
  multiple?: boolean;
  children: ReactNode;
  component: JSX.Element;
};

export const SelectFileButton: React.FC<Props> = ({
  children,
  component,
  onFileSelected = null,
  multiple = false,
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files)
      onFileSelected?.(
        multiple ? Array.from(e.target.files) : e.target.files[0]
      );
  };

  const handleClick = () => {
    hiddenFileInput.current!.click();
  };

  return (
    <React.Fragment>
      {React.cloneElement(component, {
        children,
        onClick: handleClick,
      })}
      <input
        type="file"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple={multiple}
      />
    </React.Fragment>
  );
};
