import Autocomplete from "@mui/material/Autocomplete";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { FC, HTMLAttributes, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ReactCountryFlag from "react-country-flag";
import { SxProps, Typography } from "@mui/material";
import { DeckStats } from "./useStatsParser";
import { useTranslation } from "react-i18next";

interface DecksAutocompleteProps {
  stats: DeckStats[];
  deckId: string;
  onChange: (deckId: string) => void;
  sx?: SxProps;
}

export const DecksAutocomplete: FC<DecksAutocompleteProps> = ({
  stats,
  deckId,
  onChange,
  sx,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "stats_page" });
  const { decks } = useAppData();

  // Based on deck's id displaying its respective name. In case when the
  // deck id is not found in current set of decks the deck id is displayed
  // instead - this should only happen for a special "deck id" option - "All"
  const getDeckName = (deckId: string) => {
    const found = decks.find(deckId);
    return found ? found.info.name : t("all");
  };

  // Creating an array of deck's ids from decks stats data. The decks
  // that has been removed are being left out as they should not be
  // taken into account while displaying the statistics
  const createOptions = useCallback(() => {
    const ids = stats.map((deckStats) => deckStats.deckId);
    return ["All"].concat(
      ids.filter((id) => {
        const found = decks.find(id);
        return found && !found.info.removed;
      })
    );
  }, [stats]);

  const getLangCode = (deckId: string) => {
    const found = decks.find(deckId);
    if (found === undefined) return undefined;
    const lang = found.info.targetLang;
    return lang === "en" ? "gb" : lang;
  };

  return (
    <Autocomplete
      size="small"
      options={createOptions()}
      getOptionLabel={(deckId) => getDeckName(deckId)}
      value={deckId}
      onChange={(_, value) => {
        if (value) onChange(value);
      }}
      sx={sx}
      renderInput={(params) => (
        <TextField {...params} label={t("selected_deck")} />
      )}
      renderOption={(props, deckId) => (
        <RenderOption
          label={getDeckName(deckId)}
          lang={getLangCode(deckId)}
          props={props}
        />
      )}
    />
  );
};

interface RenderOptionProps {
  props: HTMLAttributes<HTMLLIElement>;
  label: string;
  lang: string | undefined;
}

const RenderOption: FC<RenderOptionProps> = ({ label, lang, props }) => {
  return (
    <Box
      component="li"
      //   sx={{ "& > img": { m: 0, flexShrink: 0 } }}
      {...props}
    >
      <Typography>
        {label}
        {lang && (
          <ReactCountryFlag
            countryCode={lang}
            style={{
              width: "1rem",
              height: "1rem",
              marginLeft: "0.25rem",
            }}
            svg
          />
        )}
      </Typography>
    </Box>
  );
};
