import { useCallback } from "react";
import { tDeckRemoveReq } from "../../shared/types";
import { useAuth } from "../../contexts/AuthContext";
import { useAlert } from "../../contexts/Alert/AlertContext";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { IndexDbCard } from "./useFetchCards";
import { DialogDeleteDeck } from "../../dialogs/DialogDeleteDeck/DialogDeleteDeck";
import { ConfigPanelProps } from "./ConfigPanel";

export function useConfigPanelDeckDelete(use: ConfigPanelProps) {
  const { deck, onClose } = use;
  const { showDialog } = useAlert();
  const { callCloudFn } = useAuth();
  const { lesson, indexedDb } = useAppData();

  const handleDeckDelete = useCallback(async () => {
    const closeDialog = showDialog(
      <DialogDeleteDeck
        deck={deck}
        onClickOk={async () => {
          closeDialog();
          onClose(true);

          // If there is an existing learning session opened for a deck
          // that is being removed, then it has to be cleared/closed
          if (lesson.deckId === deck.id) lesson.clear();

          // IndexedDb store named "cards" consist all the cards data
          // from all decks. So in order to delete cards and audio data
          // belonging to a deck that is being removed, first this data
          // has to be filtered out
          const cards = indexedDb.getData<IndexDbCard>("cards");
          if (cards && cards.length > 0) {
            const cardsIds = cards
              .filter((card) => card.deckId === deck.id)
              .map((card) => card.id);
            await Promise.all([
              indexedDb.deleteBulkData("cards", cardsIds),
              indexedDb.deleteBulkData("audio", cardsIds),
              indexedDb.deleteBulkData("answers", cardsIds),
            ]);
          }

          // Marking the deck at the back-end as removed
          callCloudFn<tDeckRemoveReq>("deckremove", { deckid: deck.id });
        }}
        onClickCancel={() => closeDialog()}
      />
    );
  }, []);

  return handleDeckDelete;
}
