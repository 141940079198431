import { useAppData } from "../../contexts/AppData/AppDataContext";
import { useAlert } from "../../contexts/Alert/AlertContext";
import useCloudFn from "../../hooks/useCloudFn";
import { UserAnswer, tCard, tCardAnswerReq } from "../../shared/types";
import { useNavigate } from "react-router-dom";
import { DialogPrepareDeck } from "../../dialogs/DialogPrepareDeck/DialogPrepareDeck";
import { Deck } from "../../contexts/AppData/DecksData";
import { delay } from "../../shared/promises";

function useCardAnswer(deck: Deck) {
  const { lesson, indexedDb } = useAppData();
  const { showAlert, showDialog } = useAlert();
  const navigate = useNavigate();
  const callAnswer = useCloudFn<tCardAnswerReq, { due: string }>("cardanswer");

  async function postAnswer(card: tCard, answer: number) {
    lesson.addAnswerInProgress(card.id, deck.id);

    try {
      // await delay(5000); // for testing purposes
      const res = await callAnswer({
        data: {
          deckid: deck.id,
          cardid: card.id,
          answer: answer as UserAnswer,
          idxExample: card.idxExample, // TODO: Will it be used?
        },
      });

      if (res.data?.due) showAlert(`Next repetition ${res.data.due}`);
    } catch {
      indexedDb.addData("answers", {
        id: new Date().getTime(),
        key: { cardId: card.id, deckId: deck.id, answer: answer },
      });
    }

    lesson.removeAnswerInProgress(card.id);
  }

  async function cardAnswer(answer: number, doNotRemove: boolean = false) {
    const currCard = lesson.popCard()!;
    postAnswer(currCard, answer);
    if (doNotRemove) lesson.pushCards([currCard]);

    if (lesson.cardsEmpty()) {
      if (!lesson.isFetchingCards()) {
        lesson.clear();
        navigate("/decks");
      } else {
        // TODO!: Showing modal Move to AppData.Lesson?
        const closeModal = showDialog(
          <DialogPrepareDeck
            deck={deck}
            onCancelClick={lesson.data.cancelFetching!}
          />
        );
        try {
          await Promise.all(lesson.data.fetchPromises);
          closeModal();
        } catch (err) {
          closeModal();
          lesson.clear();
          navigate("/decks");
          // throw err;
        }
      }
    }
  }

  return cardAnswer;
}

export default useCardAnswer;
