import { useEffect, useState } from "react";
import {
  areDecksDefined,
  useAppData,
} from "../contexts/AppData/AppDataContext";
import { getDeckReviewCount } from "../shared/deck";

function useReviewsCount() {
  const [count, setCount] = useState(0);
  const { decks } = useAppData();

  useEffect(() => {
    if (!areDecksDefined(decks.get)) return;

    const reviewsCount = decks.get
      .map((deck) => getDeckReviewCount(deck))
      .reduce((acc, val) => acc + val);

    setCount(reviewsCount);
  }, [decks]);

  return count;
}

export default useReviewsCount;
