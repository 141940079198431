import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { FsDeckStatData } from "../../shared/types";

type FsDeckStats = { [date: string]: FsDeckStatData[] }; // Record<string, FsDeckStatData[]>;

export type RawStats = {
  deckId: string;
  data: Map<number, FsDeckStatData[]>; // <date, deck's stats>
};

function useStatsListener() {
  const { user } = useAuth();
  const { decks } = useAppData();
  const [stats, setStats] = useState<RawStats[]>([]);

  useEffect(() => {
    if (decks.get.length === 0) return;

    // Removing date portion out of the document's id resulting in deck id
    const getDeckId = (docId: string) => docId.substring(0, docId.indexOf("_"));

    const q = query<FsDeckStats>(collection(db, "users", user!.uid, "stats"));
    return onSnapshot(q, (decksStatsSnap) => {
      const newData = decksStatsSnap.docs
        // Filtering out statistics data of the decks that has been removed
        .filter((deckStatsDoc) => {
          const deckId = getDeckId(deckStatsDoc.id);
          const found = decks.find(deckId);
          return found && !found.info.removed;
        })
        // Converting received json data into a data structure we can work on
        .map((deckStatsDoc) => {
          const deckId = getDeckId(deckStatsDoc.id);
          // console.log(deckId, calculateJsonSize(deckStatsDoc.data()));
          const data = convertStatsFromJsonToMap(deckStatsDoc.data());
          return { deckId, data };
        })
        // Merging in all month-based documents data of a given deckId
        .reduce((acc, { deckId, data }) => {
          const stats = acc.get(deckId) ?? new Map<number, FsDeckStatData[]>();
          const mergedStats = [...Array.from(stats), ...Array.from(data)];
          mergedStats.sort((a, b) => a[0] - b[0]); // sort by date
          acc.set(deckId, new Map(mergedStats));
          return acc;
        }, new Map<string, Map<number, FsDeckStatData[]>>());

      setStats(Array.from(newData).map(([deckId, data]) => ({ deckId, data })));
    });
  }, [decks.get]);

  return stats;
}

function convertStatsFromJsonToMap(jsonDeckStatsData: FsDeckStats) {
  const stats = Object.entries(jsonDeckStatsData).map(
    (v) => [new Date(v[0]).getTime(), v[1]] as [number, FsDeckStatData[]]
  );
  // Map by default doesn't sort it's keys therefore we have to
  // do it manually. This is important because in the next step
  // parsing of the data relies on dates being sorted in ascending order
  stats.sort((a, b) => a[0] - b[0]);
  return new Map(stats);
}

export default useStatsListener;

function calculateJsonSize(obj: Object) {
  const jsonString = JSON.stringify(obj);
  const bytes = new TextEncoder().encode(jsonString).length;
  const kilobytes = bytes / 1024;
  return kilobytes;
}
