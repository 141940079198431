import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./pages/App";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ImportPage } from "./pages/ImportPage/ImportPage";
import { DecksPage } from "./pages/DecksPage/DecksPage";
import { LearnPage } from "./pages/LearnPage/LearnPage";
import { ImportImagePage } from "./pages/ImportImagePage/ImportImagePage";
import { AuthProvider } from "./contexts/AuthContext";
import { ProgressPage } from "./pages/ProgressPage/ProgressPage";
import { AuthGuard } from "./components/AuthGuard";
import { AlertProvider } from "./contexts/Alert/AlertContext";
import { AppDataProvider } from "./contexts/AppData/AppDataContext";
import { CustomThemeProvider } from "./contexts/CustomTheme/CustomThemeContext";
import CssBaseline from "@mui/material/CssBaseline";
import { ImportEbookReaderPage } from "./pages/ImportEbookReaderPage/ImportEbookReaderPage";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { SubscribePage } from "./pages/SubscribePage/SubscribePage";
import { PrivacyPage } from "./pages/PrivacyPage/PrivacyPage";
import { c_log } from "./shared/console";
import { HelpPage } from "./pages/HelpPage/HelpPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/" element={<AuthGuard home={<LandingPage />} />}>
        <Route path="subscription" element={<SubscribePage />} />
        <Route path="xxx" element={<SubscribePage />} />
        <Route path="/" element={<App />}>
          <Route path="stats" element={<ProgressPage />} />
          <Route path="decks" element={<DecksPage />} />
          {/* <Route path="home" element={<ImportEbookReaderPage />} /> */}
          <Route path="import" element={<ImportPage />} />
          <Route path="importimage" element={<ImportImagePage />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="learn" element={<LearnPage />} />
        </Route>
      </Route>
    </React.Fragment>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CustomThemeProvider>
      <CssBaseline />
      <AuthProvider
        onAuthChanged={async (user) => {
          if (user) c_log("Signing in as", user.displayName);
          else c_log("User not signed in");
        }}
      >
        <AppDataProvider>
          <AlertProvider>
            <RouterProvider router={router} />
          </AlertProvider>
        </AppDataProvider>
      </AuthProvider>
    </CustomThemeProvider>
  </React.StrictMode>
);

// async function registerServiceWorker() {
//   const serviceWorkerSupported = "serviceWorker" in navigator;
//   if (!serviceWorkerSupported) {
//     c_err("Service workers are not supported.");
//     return;
//   }

//   // Register a service worker hosted at the root of the
//   // site using the default scope.
//   try {
//     const registration = await navigator.serviceWorker.register(
//       `${process.env.PUBLIC_URL}/serviceWorker.js`
//     );
//     c_log("Service worker registration succeeded:", registration);
//   } catch (error) {
//     c_err(`Service worker registration failed: ${error}`);
//   }
// }

// registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
