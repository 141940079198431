import * as React from "react";
import Box from "@mui/material/Box";
import { Autocomplete, SxProps, TextField } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { countries, useCountryName } from "../common/lang";
import { useAppData } from "../contexts/AppData/AppDataContext";
import { LanguageCodes } from "../shared/languages";

const AutocompleteEntry: React.FC<{ option: string }> = ({
  option,
  ...rest
}) => {
  const langCode = option === "en" ? "gb" : option;
  const getCountryName = useCountryName();

  return (
    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...rest}>
      <ReactCountryFlag
        countryCode={langCode}
        style={{ width: "1.5em", height: "1.5em" }}
        svg
      />
      {/* <img
      loading="lazy"
      width="20px"
      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
      alt=""
    /> */}
      {getCountryName(option)}
    </Box>
  );
};

type AutocompleteLanguageProps = {
  value?: string;
  onChange?: (code: LanguageCodes) => void;
  sx?: SxProps;
};
// } & UseAutocompleteProps<string, false, true, false>;
// } & AutocompleteProps<string, false, false, false>;

export const AutocompleteLanguage = React.forwardRef<
  HTMLDivElement,
  AutocompleteLanguageProps
>(({ value, onChange, sx, ...rest }, ref) => {
  const { settings } = useAppData();
  const getCountryName = useCountryName();
  const [code, setCode] = React.useState(value ?? settings.language.get);

  return (
    <Autocomplete
      ref={ref}
      size="small"
      value={code}
      onChange={(_, val) => {
        setCode(val);
        onChange?.(val as LanguageCodes);
      }}
      disableClearable
      options={countries}
      getOptionLabel={(option) => getCountryName(option)}
      renderInput={({ ...rest }) => <TextField {...rest} fullWidth />}
      renderOption={(props, option) => (
        <AutocompleteEntry option={option} {...props} />
      )}
      sx={{
        ...sx,
        background: (theme) => theme.palette.background.paper,
        borderRadius: (theme) => theme.shape.borderRadius * 0.1,
        "& .MuiAutocomplete-inputRoot:hover": { cursor: "pointer" },
      }}
      {...rest}
    />
  );
});
