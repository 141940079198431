import Box, { BoxProps } from "@mui/material/Box";
import { alpha } from "@mui/material/";
import { forwardRef } from "react";

interface EntryBoxProps extends BoxProps {
  focused: boolean;
  hoverable: boolean;
}
export const EntryBox = forwardRef<HTMLElement, EntryBoxProps>(
  ({ hoverable, focused, children, ...rest }, ref) => (
    <Box
      ref={ref}
      sx={{
        ...(hoverable
          ? {
              "&:hover": {
                backgroundColor: (theme) =>
                  alpha(
                    theme.palette.secondary.main,
                    theme.palette.action.focusOpacity * 0.5
                  ),
                borderRadius: (theme) => theme.shape.borderRadius / 2,
              },
            }
          : {}),
        ...(focused
          ? {
              backgroundColor: (theme) =>
                alpha(
                  theme.palette.secondary.main,
                  theme.palette.action.focusOpacity
                ),
              borderRadius: (theme) => theme.shape.borderRadius / 8,
            }
          : {}),
      }}
      {...rest}
    >
      {children}
    </Box>
  )
);
