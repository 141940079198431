import { forwardRef, useEffect, useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import { InputBaseComponentProps } from "@mui/material/InputBase";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { InputValidator } from "./useFormValidator";

type StripeInputComponents =
  | typeof CardNumberElement
  | typeof CardExpiryElement
  | typeof CardCvcElement;

type StripeInputFieldProps = {
  stripeComponent: StripeInputComponents;
  validator: InputValidator;
} & TextFieldProps;

const StripeInputField = forwardRef<HTMLInputElement, StripeInputFieldProps>(
  (props, ref) => {
    const { stripeComponent, validator, sx, disabled, ...other } = props;
    const [stripeError, setStripeError] = useState<string | null>(null);

    useEffect(() => {
      validator.set(other.id!, false);
    }, []);

    const handleInputChange = (event: any) => {
      setStripeError(event.error ? event.error.message : null);
      validator.set(other.id!, !event.error);
    };

    return (
      <TextField
        {...other}
        sx={{
          ...sx,
          "& .MuiOutlinedInput-input": { height: "1rem" },
        }}
        disabled={disabled}
        ref={ref}
        variant="outlined"
        fullWidth
        error={!!stripeError}
        helperText={stripeError}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeWrapperComponent,
          inputProps: {
            component: stripeComponent,
            options: { showIcon: true, disabled },
          },
          onChange: handleInputChange,
        }}
      />
    );
  }
);

// component coming from the above inputProps : InputBaseComponentProps
const StripeWrapperComponent = React.forwardRef<any, InputBaseComponentProps>(
  (props, ref) => {
    const { component: Component, ...other } = props;

    // implement `InputElement` interface
    React.useImperativeHandle(ref, () => ({
      focus: () => {
        // logic to focus the rendered component from 3rd party belongs here
      },
      // hiding the value e.g. react-stripe-elements
    }));

    // `Component` will be your `SomeThirdPartyComponent` from below
    return <Component {...other} />;
  }
);

export default StripeInputField;
