import { useRef, useState } from "react";

export interface InputValidator {
  valid: boolean;
  set: (id: string, value: boolean) => void;
}

function useFormValidator(): InputValidator {
  const fields = useRef<Record<string, boolean> | undefined>();
  const [valid, setValid] = useState(false);

  const set = (id: string, value: boolean) => {
    fields.current = { ...fields.current, [id]: value };
    setValid(
      Object.entries(fields.current).every(([_, value]) => value === true)
    );
  };

  return { valid, set };
}

export default useFormValidator;
