import { useNavigate } from "react-router-dom";
import { tImportEbookReq, tImportEbookRes } from "../shared/types";
import { useAuth } from "../contexts/AuthContext";
import { useAlert } from "../contexts/Alert/AlertContext";
import { DialogParseKindle } from "../dialogs/DialogParseKindle/DialogParseKindle";
import { fileToBase64, FileType } from "../common/file";
import useCloudFn from "./useCloudFn";

// TODO: Instead of sending file as a string, maybe it's a better idea to
//       upload it to storage first and then server side validate it
// https://stackoverflow.com/questions/51960789/how-can-firebase-functions-httpscallable-submit-files-to-functions-https-on
const useParseKindle = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const callParseKindle = useCloudFn<tImportEbookReq, tImportEbookRes>(
    "importebook"
  );

  const parse = async (selectedFiles: File | File[]) => {
    // TODO!: encodeURIComponent?
    const file = selectedFiles as File;
    const blob64 = await fileToBase64(file, FileType.Database);

    const res = await callParseKindle({
      data: {
        name: file.name,
        date: file.lastModified,
        blob: blob64,
      },
      dialog: <DialogParseKindle fileName={file.name} />,
    });

    const booksCount = res.data as tImportEbookRes;

    showAlert(`Found ${booksCount} books in ${file.name}`);
    navigate("/import");
  };

  return parse;
};

export default useParseKindle;
