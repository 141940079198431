import { useEffect } from "react";

export function useVisibilityChange(handler: () => void, dependencies: any[]) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") handler();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, dependencies);
}
