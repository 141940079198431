import { useState } from "react";
import { BookItem } from "../../components/BookItem/BookItem";
import Box from "@mui/material/Box";
import { tDeckCreateReq } from "../../shared/types";
import Typography from "@mui/material/Typography";
import { Trans, useTranslation } from "react-i18next";
import { Video } from "../../components/Video";
import { useBooksSnapshot } from "./useBooksSnapshot";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import { AutocompleteLanguage } from "../../components/AutocompleteLanguage";
import { Tooltip } from "@mui/material";
import { ConvertButton } from "./ConvertButton";

const dialogText = `Select the books you wish to convert into decks of a flash cards. The
    flash cards will be created based on words you've looked up while
    reading a particular book.`;

export function ImportEbookReaderPage() {
  const { t } = useTranslation("translation", {
    keyPrefix: "import_ebook_reader_page",
  });
  const { searchQuery } = useAppData();
  const [booksSelected, setBooksSelected] = useState<tDeckCreateReq>([]);
  const [loading, setLoading] = useState(false);
  const books = useBooksSnapshot();
  const { theme } = useCustomTheme();
  const { settings } = useAppData();
  const [lang, setLang] = useState<string>(settings.language.get);

  const deleteByValue = (value: string) => {
    setBooksSelected((oldValues) =>
      oldValues.filter((curr) => curr.id !== value)
    );
  };

  return books && books.length > 0 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          zIndex: 11,
          bottom: 0,
          background: `linear-gradient(to right, transparent, ${theme.palette.background.paper})`,
          backdropFilter: "blur(10px)",
          width: "-webkit-fill-available",
          p: "1rem 2rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          animation: "slideIn 0.75s ease",
          "@keyframes slideIn": {
            from: { transform: "translateY(100%)" },
            to: { transform: "translateY(0)" },
          },
        }}
      >
        <Typography>
          Select the books you wish to convert into set of flashcard decks
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mx: 2,
          }}
        >
          <Tooltip
            title="Target language in which flashcard's word descriptions will be written"
            placement="top"
          >
            <AutocompleteLanguage
              value={lang}
              onChange={(v) => setLang(v)}
              sx={{ width: "150px" }}
            />
          </Tooltip>
          <ConvertButton
            selection={booksSelected}
            lang={lang}
            onStateChange={(processing) => setLoading(processing)}
          />
        </Box>
      </Box>
      {books &&
        books.map((b) => {
          // c_log("Book added! " + b.title);

          const show =
            searchQuery.get() === "" ||
            b.title.toLowerCase().includes(searchQuery.get()) ||
            b.authors.toLowerCase().includes(searchQuery.get());
          return (
            show && (
              <BookItem
                key={b.id}
                book={b}
                disabled={loading}
                onClick={(book, targetLang) => {
                  if (targetLang)
                    setBooksSelected((curr) => [
                      ...curr,
                      { id: book.id!, lang: targetLang },
                    ]);
                  else deleteByValue(book.id!);
                }}
              />
            )
          );
        })}
    </Box>
  ) : (
    <Box sx={{ m: "auto", width: "100%", textAlign: "center" }}>
      <Typography gutterBottom>{t("list_empty")}</Typography>
      <Typography>
        <Trans i18nKey="import_ebook_reader_page.drag_and_drop" />
      </Typography>
      <Video fileName="example.mov" title="Kindle import process" />
    </Box>
  );
}
