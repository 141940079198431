import { Buffer } from "buffer";

let mediaRecorder: MediaRecorder;
let audioContext: AudioContext;
let analyser: AnalyserNode;
const minAudioLevel = 0.005; // Adjust this threshold as needed
let isRecording = false;
let silenceTimer: NodeJS.Timeout | null = null;

export const startRecording = async () => {
  return new Promise<number[]>(async (resolve, reject) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      audioContext = new AudioContext();
      analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      mediaRecorder = new MediaRecorder(stream);

      const chunksPromises: Promise<ArrayBuffer>[] = [];

      mediaRecorder.addEventListener("dataavailable", (event) => {
        chunksPromises.push(
          new Promise<ArrayBuffer>(async (resolve, reject) => {
            console.log("event!dataavailable", event);
            const buffer = await event.data.arrayBuffer();
            resolve(buffer);
          })
        );
      });

      mediaRecorder.addEventListener("stop", async () => {
        console.log("stop!");
        const chunks = await Promise.all(chunksPromises);

        const totalLength = chunks.reduce(
          (acc, chunk) => acc + chunk.byteLength,
          0
        );
        const mergedBuffer = new Uint8Array(totalLength);
        let offset = 0;

        chunks.forEach((chunk) => {
          mergedBuffer.set(new Uint8Array(chunk), offset);
          offset += chunk.byteLength;
        });
        // resolve(Array.from(mergedBuffer));
        const a = Array.from(mergedBuffer);
        resolve(Buffer.from(a).toJSON().data);
      });

      isRecording = true;
      mediaRecorder.start();
      analyzeAudio();
    } catch (error) {
      // Handle errors, such as microphone permission denied or device not found
      console.error("Error accessing microphone:", error);
      reject(error);
    }
  });
};

// A recording analyzer responsible for stoppage the audio
// recording when the user is not speaking for at least 2 seconds
const analyzeAudio = () => {
  const bufferLength = analyser.fftSize;
  const dataArray = new Float32Array(bufferLength);

  const checkAudioLevel = () => {
    analyser.getFloatTimeDomainData(dataArray);
    const sum = dataArray.reduce((acc, val) => acc + Math.abs(val), 0);
    const avg = sum / bufferLength;

    // console.log(avg, avg < minAudioLevel ? undefined : "!!!!!!!!!!!!!");
    if (avg < minAudioLevel) {
      if (!silenceTimer && isRecording) {
        silenceTimer = setTimeout(() => {
          stopRecording();
        }, 2000); // Stop recording after 2 seconds of silence
      }
    } else if (silenceTimer) {
      clearTimeout(silenceTimer);
      silenceTimer = null;
    }

    if (isRecording) requestAnimationFrame(checkAudioLevel);
  };

  requestAnimationFrame(checkAudioLevel);
};

export const stopRecording = async () => {
  if (isRecording) {
    mediaRecorder.stop();
    isRecording = false;
    await cleanup();
  }
};

const cleanup = async () => {
  try {
    if (silenceTimer) {
      clearTimeout(silenceTimer);
      silenceTimer = null;
    }
    if (analyser) analyser.disconnect();
    if (audioContext) await audioContext.close();
  } catch (error) {
    console.error(error);
  }
};
