import { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import { darken } from "@mui/material/styles";

interface EscButtonProps extends ButtonProps {
  onClick: () => void;
}

export const EscButton: FC<EscButtonProps> = ({ onClick, ...rest }) => {
  return (
    <Button
      variant="contained"
      size="small"
      onClick={onClick}
      sx={{
        "&, &:hover": {
          boxShadow: "none",
          transition: "none",
          textTransform: "none",
          p: 0.5,
          backgroundColor: (theme) =>
            darken(theme.palette.background.paper, 0.05),
          color: (theme) => theme.palette.primary.dark,
          border: (theme) =>
            `1px solid ${darken(theme.palette.background.paper, 0.1)}`,
        },
      }}
      {...rest}
    >
      esc
    </Button>
  );
};
