import { useState } from "react";

export interface SearchQueryData {
  get: () => string;
  set: (value: string) => void;
}

export default function useSearchQueryData(): SearchQueryData {
  const [searchQuery, setSearchQuery] = useState("");

  return {
    get: () => searchQuery,
    set: (value: string) => {
      setSearchQuery(value);
    },
  };
}
