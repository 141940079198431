import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { VocabSnapshotUi, tBook } from "../../shared/types";

export function useBooksSnapshot() {
  const { user } = useAuth();
  const [books, setBooks] = useState<tBook[] | undefined>();

  useEffect(() => {
    const refDoc = doc(db, `snaps/${user!.uid}`);
    return onSnapshot(refDoc, (docSnap) => {
      const data = docSnap.data() as VocabSnapshotUi | undefined;
      setBooks(data?.books);
    });
  }, []);

  return books;
}
