import * as React from "react";
import { tBook } from "../../shared/types";
import Box from "@mui/material/Box";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { CardActionArea, CardContent } from "@mui/material";
import { useAlert } from "../../contexts/Alert/AlertContext";
import styled from "@mui/material/styles/styled";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import { BookCover } from "./BookCover";
import { BookTargetLangDialog } from "./BookTargetLangDialog";
import { TranslationFlags } from "./TranslationFlags";

export const BookTitle = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: 500,
  maxHeight: 128,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  lineHeight: "1.3",
  textAlign: "center",
}));

interface BookItemProps {
  book: tBook;
  onClick: (book: tBook, targetLang?: string) => void;
  disabled?: boolean;
}

export const BookItem: React.FC<BookItemProps> = (props) => {
  const { book, disabled = false } = props;
  const [selected, setSelected] = React.useState(false);
  const [convToLang, setConvToLang] = React.useState<string | undefined>();
  // const { showDialog } = useAlert();
  const { isDecolorized, theme } = useCustomTheme();

  const setTargetLang = (lang?: string) => {
    setConvToLang(lang);
    props.onClick(book, lang);
    setSelected(lang !== undefined);
  };

  return (
    <Card
      elevation={3}
      sx={{
        m: "1rem",
        width: 200,
        boxShadow: selected && !disabled ? 10 : 3, // for 10 see CustomThemeContext.tsx
        filter: disabled ? "blur(5px)" : "none",
        transition: "transform 0.2s ease-out",
        transform: "scale(1.0)",
        "&:hover": { transform: "scale(1.05)" },
      }}
    >
      <CardActionArea
        onClick={() => {
          setSelected(!selected);
          props.onClick(book, !selected ? "x" : "");
          // if (!selected) {
          //   const closeDialog = showDialog(
          //     <BookTargetLangDialog
          //       book={book}
          //       onClickOk={(targetLang) => {
          //         closeDialog();
          //         setTargetLang(targetLang);
          //       }}
          //       onClickCancel={() => closeDialog()}
          //     />
          //   );
          // } else setTargetLang();
        }}
        disabled={disabled}
        sx={{ height: "100%" }}
      >
        <Box
          p={2}
          height="100%"
          display="flex"
          flexDirection="column"
          textAlign={"center"}
        >
          {selected && (
            <BookmarkAddedIcon
              color={disabled ? "disabled" : "secondary"}
              sx={{
                position: "absolute",
                right: 7,
                top: 7,
                color: theme.palette.secondary.main,
              }}
            />
          )}
          <BookCover coverUrl={book.coverUrl} component="cardmedia" shadow />
          <CardContent
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box my="1rem">
              <BookTitle>{book.title}</BookTitle>
              <Typography>{book.authors}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                flexGrow: 1,
              }}
            >
              <TranslationFlags
                langBook={book.lang}
                langTarget={convToLang}
                disabled={disabled || isDecolorized}
              />
            </Box>
            <Typography component="div" variant="caption">
              WORDS: {book.lookups!.toString()}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};
