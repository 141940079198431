import React, { FC, useRef } from "react";
import Typography from "@mui/material/Typography";
import { SelectFileButton } from "../../components/SelectFileButton";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

interface SelectFileItemProps {
  text: string;
  onFileSelected: (selectedFiles: File | File[]) => void;
  multiple?: boolean;
}

export const SelectFileItem: FC<SelectFileItemProps> = ({
  text,
  onFileSelected,
  multiple = false,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "import_page" });

  return (
    <React.Fragment>
      <Typography variant="body2" gutterBottom>
        {text}
      </Typography>
      <SelectFileButton
        component={<Button variant="text" color="info" size="small" />}
        multiple={multiple}
        onFileSelected={onFileSelected}
      >
        {t("select_file")}&nbsp;&nbsp;&gt;
      </SelectFileButton>
    </React.Fragment>
  );
};
