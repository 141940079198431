import React, { FC } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

export interface SettingsMenuItem {
  text: string;
  hint: string;
  icon: JSX.Element;
  action: () => void;
}

interface CardSettingsMenuProps {
  items: SettingsMenuItem[];
  onActionClicked: () => void;
}

export const CardSettingsMenu: FC<CardSettingsMenuProps> = ({
  items,
  onActionClicked,
}) => {
  return (
    <List>
      {items.map(({ text, hint, icon, action }) => (
        <Tooltip title={hint} placement="left">
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                onActionClicked();
                action();
              }}
            >
              {React.cloneElement(icon, {
                fontSize: "small",
                sx: { mr: 1 },
              })}
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      ))}
    </List>
  );
};
