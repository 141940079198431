import * as React from "react";
import Box from "@mui/material/Box";
import ReactCountryFlag from "react-country-flag";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import { SxProps } from "@mui/material/styles";

const StyledFlag: React.FC<{ countryCode: string; disabled: boolean }> = ({
  countryCode,
  disabled,
}) => {
  const { isDecolorized } = useCustomTheme();

  return (
    <ReactCountryFlag
      draggable={false}
      countryCode={countryCode}
      style={{
        width: "2em",
        height: "2em",
        filter:
          isDecolorized || disabled
            ? disabled
              ? `grayscale(1) brightness(10%)`
              : `grayscale(1)`
            : undefined,
        mixBlendMode: disabled ? "difference" : undefined,
      }}
      svg
    />
  );
};

interface TranslationFlagsProps {
  langBook: string;
  langTarget?: string;
  disabled?: boolean;
  sx?: SxProps;
}

export const TranslationFlags: React.FC<TranslationFlagsProps> = ({
  langBook,
  langTarget,
  disabled = false,
  sx,
}) => {
  const { theme } = useCustomTheme();

  langBook = langBook === "en" ? "gb" : langBook;
  langTarget = langTarget === "en" ? "gb" : langTarget;

  return langTarget ? (
    <Box sx={{ display: "flex", justifyContent: "center", ...sx }}>
      <StyledFlag countryCode={langTarget} disabled={disabled} />
    </Box>
  ) : null;

  // return (
  //   <Box sx={{ display: "flex", justifyContent: "center", ...sx }}>
  //     <StyledFlag countryCode={langBook} disabled={disabled} />
  //     {langTarget && (
  //       <React.Fragment>
  //         <DoubleArrowIcon
  //           sx={{
  //             fontSize: "1em",
  //             height: "2em",
  //             mx: 1,
  //             color: disabled
  //               ? theme.palette.text.disabled
  //               : theme.palette.text.primary,
  //           }}
  //         />
  //         <StyledFlag countryCode={langTarget} disabled={disabled} />
  //       </React.Fragment>
  //     )}
  //   </Box>
  // );
};
