import { useMemo } from "react";
import { DeckStats, DeckStatsOp } from "./useStatsParser";

// Returns the data used in ApexCharts Ui stats components - Maturity,
// Repetitions, Future Reviews. Each Ui component requires different
// set of data, which is embedded in DeckStats[] object. Those different
// parts are accessed via op.data as well as if necessary (selection
// "All" from Autocomplete) merged via op.sum operation
function useDeckStats<T>(
  stats: DeckStats[],
  selectedDeckId: string,
  op: DeckStatsOp<T>
) {
  const out = useMemo(() => {
    // Returns stats for a given selectedDeckId deck
    const found = stats.find((v) => v.deckId === selectedDeckId);
    if (found) return op.data(found);

    // User choose "All" so we need to combine all the data from all the decks
    const out = stats
      // combine all stats data from all decks into one array [date, stat type]
      .flatMap((v) => op.data(v))
      // sort resulting array in ascending order by date
      .sort((a, b) => a[0] - b[0])
      // merge stats data pertaining to the same date
      .reduce((acc, [date, stat]) => {
        acc.set(date, acc.has(date) ? op.sum(acc.get(date)!, stat) : stat);
        return acc;
      }, new Map<number, T>());
    return Array.from(out);
  }, [stats, selectedDeckId, op]);

  return out;
}

export default useDeckStats;
