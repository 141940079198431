import React, { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Pagination from "@mui/material/Pagination";
import styled from "@mui/material/styles/styled";
import useHorizontalPageSwiper from "../../hooks/useHorizontalPageSwiper";
import { FlashCardProps, SectionHeader } from "./FlashCard";
import { useTranslation } from "react-i18next";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import { alpha, darken } from "@mui/material/styles";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { ExampleSentence } from "./ExampleSentence";
import useCurrentDeck from "../../hooks/useCurrentDeck";

const ShortDivider = styled(Divider)({
  width: "0rem",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
});

export const CardExamples: FC<{ use: FlashCardProps }> = ({
  use: { card, answerShown },
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "lesson" });
  const { settings } = useAppData();
  const pagesCount = Math.ceil(card.examples.length / 2); // We're showing 2 example sentences per page
  const { page, setPage, swipeHandlers } = useHorizontalPageSwiper(pagesCount);
  const isBottomSentenceVisible = page * 2 + 1 < card.examples.length;
  const deck = useCurrentDeck();

  useEffect(() => {
    if (!answerShown) setPage(card.idxExample);
  }, [answerShown]);

  return (
    <React.Fragment>
      {!settings.compactView.get && (
        <SectionHeader variant="caption">{t("examples")}</SectionHeader>
      )}
      <Box
        display="flex"
        flexDirection="column"
        // alignItems="center"
        {...swipeHandlers}
      >
        {
          card.examples.map((sentence, idx) => {
            return (
              <ExampleSentence
                key={`${card.id}${idx}`}
                sentence={sentence}
                answerShown={answerShown}
                url={card.url}
                card={{ ...card, deckId: deck.id }}
                idx={idx}
              />
            );
          })
          /* <Example
          dangerouslySetInnerHTML={{
            __html: displayExample(card.examples.at(page * 2)!),
          }}
        />
        {isBottomSentenceVisible && (
          <React.Fragment>
            <ShortDivider />
            <Example
              dangerouslySetInnerHTML={{
                __html: displayExample(card.examples.at(page * 2 + 1)!),
              }}
            />
          </React.Fragment>
        )}
        <Pagination
          size="small"
          shape="rounded"
          count={pagesCount}
          page={page + 1}
          sx={{ mt: "1rem" }}
          onChange={(_, page) => {
            setPage(page - 1);
            card.idxExample = page - 1;
          }}
        /> */
        }
      </Box>
    </React.Fragment>
  );
};

export function usePrimaryColor(disabled: boolean = false) {
  const {
    mode: [mode],
    theme,
  } = useCustomTheme();

  if (disabled) return theme.palette.action.disabledBackground;

  return mode === "light"
    ? darken(theme.palette.primary.main, 0.3)
    : darken(theme.palette.secondary.main, 0.3);
}
