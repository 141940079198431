import { FC, useState } from "react";
import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import useMobileView from "../../hooks/useMobileView";

export type ShowAlert = (message: string, severity?: AlertColor) => CloseAlert;
export type CloseAlert = () => void;

export interface AlertPopupProps {
  message: string;
  severity: AlertColor | undefined;
  show: ShowAlert;
  close: CloseAlert;
}

export function useAlertPopup(): AlertPopupProps {
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<AlertColor>();

  const show: ShowAlert = (message: string, severity?: AlertColor) => {
    setMessage(message);
    setSeverity(severity ?? "info");
    return close;
  };

  const close: CloseAlert = () => setMessage("");

  return { message, severity, show, close };
}

export const AlertPopup: FC<{ use: AlertPopupProps }> = ({ use }) => {
  const { message, severity, close } = use;
  const isMobile = useMobileView();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: isMobile ? "top" : "bottom",
        horizontal: "right",
      }}
      open={message.length !== 0}
      autoHideDuration={5000}
      onClose={close}
    >
      <Alert onClose={close} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
