import { useAuth } from "../contexts/AuthContext";
import { useAlert } from "../contexts/Alert/AlertContext";

const useUserSignOut = () => {
  const { user, signout: signOut } = useAuth();
  const { showAlert } = useAlert();

  const signout = async () => {
    await signOut();
    showAlert(`${user!.displayName} signed out`, "success");
  };

  return signout;
};

export default useUserSignOut;
