import { Box, SxProps, Theme, alpha } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { FC } from "react";

interface VideoProps {
  title?: string | null;
  fileName: string;
  sx?: SxProps<Theme>;
}

export const Video: FC<VideoProps> = ({ title, fileName, sx }) => {
  return (
    <Box>
      <CardMedia
        component="video"
        src={`videos/${fileName}`}
        title={title ?? undefined}
        controls
        sx={{
          // margin: "2rem auto 0.5rem auto",
          mx: "auto",
          minHeight: "200px",
          ...sx,
        }}
      />
      {title && (
        <Typography
          variant="caption"
          sx={{
            display: "block",
            width: "100%",
            textAlign: "center",
            mt: "0.5rem",
            mb: "1rem",
          }}
        >
          {title}
        </Typography>
      )}
    </Box>
  );
};
