import { useAlert } from "../contexts/Alert/AlertContext";
import { useAuth } from "../contexts/AuthContext";

// Function returned via useCloudFn hook will be executed synchronously
// if dialog provided. Otherwise its behavior is exact as it's simpler
// counterpart "callCloudFn" which unless await'ed will behave asynchronously
export function useCloudFn<Req = unknown, Res = unknown>(functionName: string) {
  const { showDialog } = useAlert();
  const { callCloudFn } = useAuth();

  async function cloudFunction(options?: { data?: Req; dialog?: JSX.Element }) {
    if (options?.dialog === undefined)
      return callCloudFn<Req, Res>(functionName, options?.data);

    const closeDialog = showDialog(options?.dialog);
    try {
      const res = await callCloudFn<Req, Res>(functionName, options?.data);
      closeDialog();
      return res;
    } catch (error) {
      closeDialog();
      throw error;
    }
  }

  return cloudFunction;
}

export default useCloudFn;
