import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { tCard, tSttReq, tSttRes } from "../../shared/types";
import styled from "@mui/material/styles/styled";
import { CardExamples } from "./CardExamples";
import { CardDefinition } from "./CardDefinition";
import { CardAnswer } from "./CardAnswer";
import { CardSettings } from "./CardSettings";
import { playAudio } from "../../common/audio";
import { Deck } from "../../contexts/AppData/DecksData";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import TextField from "@mui/material/TextField";
import { useAuth } from "../../contexts/AuthContext";
import { MicrophoneButton } from "./MicrophoneButton";
import { useKeyDown } from "../../hooks/useKeyDown";
import { useCardAudio } from "./useCardAudio";
import CircularProgress from "@mui/material/CircularProgress";

export const SectionHeader = styled(Typography)({
  display: "block",
  gutterBottom: true,
  textTransform: "uppercase",
  textAlign: "center",
});

// TODO: using sx styling possible? sx: {m:2}
const SectionDivider = styled(Divider)({
  marginTop: "1rem",
  marginBottom: "1rem",
});

const CardContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  overflow: "auto",
});

export type CardAudioCache = {
  word?: { idx: number; data: ArrayBufferLike }[];
  examples?: { idx: number; data: ArrayBufferLike }[];
  synonyms?: { idx: number; data: ArrayBufferLike }[]; // TODO: Caching synonyms
};

export interface FlashCardProps {
  card: tCard;
  deck: Deck;
  answerShown: boolean;
  onBury: () => void;
  onInputEnter: (typedAnswer: string) => void;
  onRecording: (processing: boolean) => void;
}

export const FlashCard: FC<FlashCardProps> = (props) => {
  const { card, deck, answerShown, onInputEnter, onRecording } = props;
  const recordingBuffer = useRef<ArrayBufferLike | null>(null);
  const { settings } = useAppData();
  const [typedAnswer, setTypedAnswer] = useState("");
  const { callCloudFn } = useAuth();
  const [micDisabled, setMicDisabled] = useState(false);
  const wordAudio = useCardAudio();

  useKeyDown(
    useCallback(
      (event: KeyboardEvent) => {
        if (answerShown && event.key === " ") {
          event.preventDefault();
          playWordAudio();
        }
      },
      [answerShown]
    )
  );

  useEffect(() => {
    if (!answerShown) setTypedAnswer("");
    if (answerShown && settings.autoPlay.get) playWordAudio();
  }, [answerShown]);

  useEffect(() => {
    // setTypedAnswer("");
    recordingBuffer.current = null;

    // TODO: What about racing conditions? Upon showing a new card fetching
    // of audio data should be discarded? Can it overwrite the newly opened
    // card's audio data?
    wordAudio.fetch({ text: card.word, lang: deck.info.lang }, card.id, {
      idx: 0,
      name: "word",
    });
  }, [card]);

  const playWordAudio = async () => {
    await wordAudio.play({ text: card.word, lang: deck.info.lang }, card.id, {
      idx: 0,
      name: "word",
    });

    if (settings.autoPlayRecording.get && recordingBuffer.current)
      playAudio(recordingBuffer.current, true);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        m: { xs: 0, sm: "0.5rem", md: "1rem" },
        p: { xs: "1rem", sm: "1rem", md: "2rem" },
        // m: { xs: "0.5rem", md: "1rem" },
        // p: { xs: "1rem", sm: "2rem" },
        minWidth: 300,
        maxWidth: 800,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        position: "relative",
      }}
    >
      <CardSettings use={props} />
      <CardContent
        sx={{
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <CardDefinition use={props} />
        <SectionDivider />
        <CardExamples use={props} />
      </CardContent>
      {answerShown ? (
        <React.Fragment>
          <SectionDivider />
          <CardAnswer
            use={props}
            audioState={wordAudio.state}
            typedAnswer={typedAnswer}
            onPlayAudio={playWordAudio}
            onPlayRecording={() => {
              if (recordingBuffer.current !== null)
                playAudio(recordingBuffer.current, true);
            }}
          />
        </React.Fragment>
      ) : settings.inputMode.get === "keys" ? (
        <React.Fragment>
          <SectionDivider />
          <TextField
            id="input-answer"
            label="Answer"
            variant="standard"
            autoFocus
            autoComplete="off"
            value={typedAnswer}
            onChange={(e) => setTypedAnswer(e.currentTarget.value)}
            sx={{ width: "30%", margin: "auto" }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") onInputEnter(typedAnswer);
            }}
          />
        </React.Fragment>
      ) : (
        settings.inputMode.get === "mic" && (
          <React.Fragment>
            <SectionDivider />
            {micDisabled ? (
              <CircularProgress size="2rem" sx={{ m: "auto" }} />
            ) : (
              <MicrophoneButton
                sx={{ m: "auto" }}
                disabled={micDisabled}
                onClick={() => onRecording(true)}
                onRecordFinished={async (data) => {
                  recordingBuffer.current = data
                    ? new Uint8Array(data).buffer
                    : null;
                  if (data === null) {
                    onRecording(false);
                    return;
                  }

                  setMicDisabled(true);
                  const stt = await callCloudFn<tSttReq, tSttRes>("stt", {
                    blob: data,
                    phrase: card.word,
                    // phrase: card.examples[0].substring(0, 100),
                    lang: deck.info.lang,
                  });
                  setMicDisabled(false);
                  console.log("STT result:", stt.data.text);
                  setTypedAnswer(stt.data.text);
                  onInputEnter(stt.data.text);
                  onRecording(false);
                }}
              />
            )}
          </React.Fragment>
        )
      )}
    </Paper>
  );
};
