import { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { usePrimaryColor } from "./FlashCard/CardExamples";

interface SentenceWithBlanksProps extends TypographyProps, PropsWithChildren {
  sentence: string;
  expanded: boolean;
  sx?: TypographyProps["sx"];
}

export const SentenceWithBlanks: FC<SentenceWithBlanksProps> = ({
  sentence,
  expanded,
  sx,
  children,
  ...rest
}) => {
  const color = usePrimaryColor();

  const regex = /(\[\[.*?\]\])/;
  const parts = sentence.split(regex);

  return (
    <Typography
      sx={{
        textAlign: "left",
        my: 1.5,
        width: "100%",
        ...sx,
      }}
      {...rest}
    >
      {parts.map((part, idx) => {
        const match = regex.test(part);
        return match ? (
          <Box key={idx} component="span" sx={{ color, fontWeight: "bold" }}>
            {!expanded ? "[...]" : part.slice(2, -2)}
          </Box>
        ) : (
          part
        );
      })}
      {children}
    </Typography>
  );
};
