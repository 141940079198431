import { FC, useCallback, useEffect, useRef, useState } from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface DailyCardsSliderProps {
  caption: string;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

export const DailyCardsSlider: FC<DailyCardsSliderProps> = ({
  caption,
  max,
  value: propsValue,
  onChange,
}) => {
  const [value, setValue] = useState<number>(propsValue);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => onChange(value), [value]);

  const handleWheel = useCallback((event: WheelEvent) => {
    event.preventDefault();
    const delta = Math.max(-1, Math.min(1, event.deltaY));
    setValue((prevValue) => Math.max(0, Math.min(prevValue - delta, max)));
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container)
      container.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      if (container) container.removeEventListener("wheel", handleWheel);
    };
  }, [handleWheel]);

  return (
    <Box
      sx={{
        display: "flex",
        flexBasis: "200px",
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <Typography variant="caption" display="block">
        {caption}
      </Typography>
      <Slider
        ref={containerRef}
        max={max}
        value={value}
        valueLabelDisplay="on"
        onChange={(_, val) => setValue(val as number)}
        sx={{ mx: 2 }}
      />
    </Box>
  );
};
