import { FC, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { FlashCardProps, SectionHeader } from "./FlashCard";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { useTranslation } from "react-i18next";
import { useKeyDown } from "../../hooks/useKeyDown";
import { SentenceWithBlanks } from "../SentenceWithBlanks";

export const CardDefinition: FC<{ use: FlashCardProps }> = ({
  use: { card, deck, answerShown },
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "lesson" });
  const { settings } = useAppData();
  const [expanded, setExpanded] = useState(false);

  useKeyDown(
    useCallback(
      (event: KeyboardEvent) => {
        if (!answerShown) return;
        if (event.key === "ArrowUp") setExpanded(false);
        if (event.key === "ArrowDown") setExpanded(true);
      },
      [answerShown]
    )
  );

  useEffect(() => {
    if (!answerShown) setExpanded(false);
  }, [answerShown]);

  return (
    <Accordion elevation={0} disableGutters expanded={expanded}>
      <AccordionSummary
        expandIcon={
          answerShown && (
            <ExpandMoreIcon
              onClick={() => {
                if (answerShown) setExpanded(!expanded);
              }}
              sx={{ cursor: "pointer" }}
            />
          )
        }
        sx={{
          flexDirection: "column",
          p: 0,
          "& .MuiAccordionSummary-content": { width: "100%" },
          cursor: "unset !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            userSelect: "text",
          }}
        >
          {!settings.compactView.get && (
            <SectionHeader variant="caption">{t("definition")}</SectionHeader>
          )}
          <Typography fontSize={20} fontWeight={500} sx={{ mx: "1rem" }}>
            {card.short}
          </Typography>
        </Box>
      </AccordionSummary>
      {answerShown && (
        <AccordionDetails>
          <SentenceWithBlanks
            sentence={card.long}
            expanded
            sx={{ textAlign: "justify" }}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
};
