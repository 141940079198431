import { useState } from "react";
import { useSwipeable } from "react-swipeable";

const useHorizontalPageSwiper = (pagesCount: number) => {
  const [page, setPage] = useState(0);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const nextPage = page + 1;
      if (nextPage < pagesCount) setPage(nextPage);
    },
    onSwipedRight: () => {
      const prevPage = page - 1;
      if (prevPage >= 0) setPage(prevPage);
    },
  });

  return { page, setPage, swipeHandlers };
};

export default useHorizontalPageSwiper;
