import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import TransitionGroup from "react-transition-group/TransitionGroup";
import { DeckItem } from "../../components/DeckItem/DeckItem";
import Typography from "@mui/material/Typography";
import {
  areDecksDefined,
  useAppData,
} from "../../contexts/AppData/AppDataContext";
import { sortDecks } from "../../shared/deck";
import { useDecksSync } from "./useDecksSync";
import Button from "@mui/material/Button";

export const DecksPage = () => {
  const { settings } = useAppData();
  const decks = useDecksSync();
  const navigate = useNavigate();

  return !areDecksDefined(decks.get) ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box textAlign="center" sx={{ mx: "20%", my: "auto" }}>
        <Typography sx={{ my: "1rem" }}>The deck's list is empty.</Typography>
        <Typography sx={{ my: "1rem" }}>
          In order to start your learning journey first you need to import the
          data from your favourite ebook that you've read or to install our
          Chrome extension and start using it.
        </Typography>
        <Button
          onClick={() => {
            navigate("/import");
          }}
        >
          Go to import
        </Button>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        height: "100%",
        maxWidth: "800px",
        m: "auto",
        position: "relative",
      }}
    >
      {/* {settings.decksOrder.get.map((deckId, idx) => {
        const deck = decks.get.find((deck) => deck.id === deckId)!; */}
      {decks.get.map((deck) => {
        const idx = settings.decksOrder.get.findIndex((v) => deck.id === v)!;
        return (
          <DeckItem
            key={deck.id}
            deck={deck}
            sx={{
              position: "absolute",
              width: "100%",
              top: {
                xs: `${230 * idx}px`,
                sm: `${220 * idx}px`,
                md: `${170 * idx}px`,
              },
              // transition: (theme) =>
              //   theme.transitions.create("top", { duration: "0.2s" }),
            }}
            cardProps={{
              height: { xs: "220px", sm: "200px", md: "150px" },
            }}
            onDragEnd={() => {
              settings.synchronize();
            }}
            onDragged={(yOffset) => {
              const n = Math.trunc(yOffset / 170);
              if (n !== 0) {
                if (idx + n >= decks.get.length || idx + n < 0) return false;

                settings.decksOrder.set((prev) => {
                  // return prev.map((val, i) => {
                  //   if (i === idx) return prev[idx + n];
                  //   if (i === idx + n) return prev[idx];
                  //   return val;
                  // });
                  const newOrder = [...prev];
                  newOrder[idx] = prev[idx + n];
                  newOrder[idx + n] = prev[idx];
                  return newOrder;
                });
                return true;
              }
              return false;
            }}
          />
        );
      })}
    </Box>
  );
};
