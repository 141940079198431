import { useEffect } from "react";

export function useBackdropClick(
  args: { onClicked: () => void; clsName?: string; invert?: boolean },
  dependencies: any[]
) {
  const { onClicked, clsName, invert = false } = args;

  useEffect(() => {
    const handleBackdropClick = (event: MouseEvent) => {
      const clsList = (event.target as HTMLElement).classList;
      // console.log(clsList);
      const contains = clsList.contains(clsName ?? "MuiDialog-container");
      if (invert ? !contains : contains) onClicked();
    };

    document.addEventListener("click", handleBackdropClick);
    return () => {
      document.removeEventListener("click", handleBackdropClick);
    };
  }, dependencies);
}
