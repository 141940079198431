import { useAuth } from "../contexts/AuthContext";
import { tTtsReq, tTtsRes } from "../shared/types";

export type AudioPlay = {
  source: AudioBufferSourceNode;
  playing: Promise<void>;
};

export function playAudio(
  audioBuffer: ArrayBufferLike,
  copyBuffer: boolean = false
): AudioPlay {
  const context = new AudioContext();
  const source = context.createBufferSource();

  const playing = new Promise<void>((resolve, reject) => {
    let buffer = copyBuffer ? audioBuffer.slice(0) : audioBuffer;

    context.decodeAudioData(buffer, (decodedBuffer) => {
      source.buffer = decodedBuffer;
      source.onended = () => {
        resolve();
      };
      source.connect(context.destination);
      source.start();
    });
  });

  return { source, playing };
}

export function useFetchAudioTTS() {
  const { callCloudFn } = useAuth();

  const fetchAudio = async (text: string, lang: string = "en") => {
    const res = await callCloudFn<tTtsReq, tTtsRes>("tts", { text, lang });
    return new Uint8Array(res.data).buffer;
  };

  return fetchAudio;
}

export function usePlayAudioTTS() {
  const fetchAudio = useFetchAudioTTS();
  let isPlaying = false;

  const play = async (text: string, lang: string = "en") => {
    try {
      if (isPlaying) return null;

      isPlaying = true;

      const arrayBuffer = await fetchAudio(text, lang);
      await playAudio(arrayBuffer, true);

      isPlaying = false;

      return arrayBuffer;
    } catch (error) {
      isPlaying = false;
      return null;
    }
  };

  return play;
}
