import { FC } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Theme, alpha, darken, useTheme } from "@mui/material/styles";

export const styleMenuItemAnim = (
  expanded: boolean,
  focused: boolean,
  theme: Theme
) => {
  return {
    "& .MuiBadge-badge, .MuiTypography-root": {
      transition: "opacity 0.2s ease-out",
      opacity: expanded ? 1 : 0,
      fontWeight: focused ? 600 : undefined,
      // color: focused ? theme.palette.primary.dark : undefined,
    },
  };
};

export interface MenuItemDescr {
  text: string;
  icon: { normal: JSX.Element; focused: JSX.Element };
  link: string;
  badge?: JSX.Element;
}

interface MenuItemProps {
  item: MenuItemDescr;
  onClick: () => void;
  expanded?: boolean;
  focused?: boolean;
}

export const MenuItem: FC<MenuItemProps> = ({
  item,
  onClick,
  expanded = false,
  focused = false,
}) => {
  const theme = useTheme();

  return (
    <ListItemButton
      key={item.text}
      selected={focused}
      onClick={onClick}
      sx={{
        mx: 1,
        borderRadius: `${theme.shape.borderRadius}px`,
        // "&.Mui-selected:hover": {
        //   backgroundColor: darken(theme.palette.primary.light, 0.1),
        // },
        // "&.Mui-selected, &:hover": {
        //   backgroundColor: theme.palette.primary.light,
        // },
        // "&:hover": {
        //   backgroundColor: alpha(
        //     theme.palette.action.hover,
        //     theme.palette.action.hoverOpacity
        //   ),
        // },
      }}
    >
      <Tooltip
        title={!expanded ? item.text : ""}
        placement="right"
        PopperProps={{
          modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
        }}
      >
        <ListItemIcon sx={{ minWidth: "38px" }}>
          {item.badge !== undefined
            ? React.cloneElement(item.badge, {
                children: focused ? item.icon.normal : item.icon.focused,
                sx: { ...styleMenuItemAnim(!expanded, false, theme) },
              })
            : focused
            ? item.icon.normal
            : item.icon.focused}
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        primary={item.text}
        sx={{
          ...styleMenuItemAnim(expanded, focused, theme),
          whiteSpace: "nowrap",
        }}
      />
      {/* <ListItemText primary={expanded ? item.text : ""} /> */}
      {item.badge !== undefined &&
        React.cloneElement(item.badge, {
          sx: { ...styleMenuItemAnim(expanded, false, theme), left: "-10px" },
        })}
    </ListItemButton>
  );
};
