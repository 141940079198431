import { FC } from "react";
import { Link, SxProps } from "@mui/material";
import { openNewTab } from "../../common/browser";

type UrlLinkProps = {
  title: string;
  url: string;
  sx?: SxProps;
};

const UrlLink: FC<UrlLinkProps> = ({ title, url, sx }) => (
  <Link
    component="button"
    variant="body2"
    underline="none"
    onClick={() => openNewTab(url)}
    sx={sx}
  >
    {title}
  </Link>
);

export default UrlLink;
