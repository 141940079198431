export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const getMaxRandomInt = () =>
  Math.floor(Number.MAX_SAFE_INTEGER * Math.random());

export const clamp = (min: number, max: number, value: number) => {
  return Math.max(min, Math.min(value, max));
};

export function times<T>(count: number) {
  return (fn: () => T, ret: (lhs: T, rhs: T) => T = (x) => x): T => {
    const fnRes = fn();
    if (count > 0) return ret(fnRes, times<T>(count - 1)(fn, ret));
    return fnRes;
  };
}

export const getEpochInMillisUTC = () => Date.now();

export const generateRandomString = (length: number) => {
  const chars =
    "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";
  const randomArray = Array.from(
    { length: length },
    (v, k) => chars[getRandomInt(chars.length)]
  );
  return randomArray.join("");
};

export const HourInMillis = 1000 * 60 * 60;

export const DayInMillis = HourInMillis * 24;

export function floorDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function ceilDate(date: Date) {
  const flooerd = floorDate(date);
  flooerd.setDate(flooerd.getDate() + 1);
  return flooerd;
}

//  in arr: [[1, 2], [2, 3], [1, 5], [1, 1]]
// out map: <1, [2, 5, 1]>, <2, [3]>
export function mergeKeys<K, V>(arr: [K, V][]) {
  const res = new Map<K, V[]>();
  arr.forEach(([key, value]) => {
    if (res.has(key)) res.get(key)!.push(value);
    else res.set(key, [value]);
  });
  return res;
}

export function sameDay(days: Date[]) {
  for (let i = 1; i < days.length; ++i) {
    const daysNotEqual =
      days[i].getDate() === days[0].getDate() &&
      days[i].getMonth() === days[0].getMonth() &&
      days[i].getFullYear() === days[0].getFullYear();

    if (daysNotEqual) return false;
  }

  return true;
}

export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export function calculateJsonSizeInKb(obj: Object) {
  const jsonString = JSON.stringify(obj);
  const bytes = new TextEncoder().encode(jsonString).length;
  return bytes / 1024;
}

export function mergeObjects<T extends object | null>(obj1: T, obj2: T): T {
  if (!obj1 || typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj2; // Return obj2 if obj1 is null or either obj1 or obj2 is not an object
  }

  const merged: any = { ...obj1 }; // Shallow copy of obj1

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      const value1 = obj1![key];
      const value2 = obj2![key];
      // const value1 = obj1[key as keyof T];
      // const value2 = obj2[key as keyof T];

      if (Array.isArray(value1) && Array.isArray(value2)) {
        // Merge arrays by concatenating them
        merged[key] = [...value1, ...value2];
      } else if (typeof value1 === "object" && typeof value2 === "object") {
        // Recursively merge nested objects
        merged[key] = mergeObjects(value1, value2);
      } else {
        // Overwrite values in obj1 with values from obj2
        merged[key] = value2;
      }
    }
  }

  return merged;
}
