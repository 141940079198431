import { useEffect, useState } from "react";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { Deck } from "../../contexts/AppData/DecksData";

function useDeck(deckId: string | undefined) {
  const { decks } = useAppData();
  const [deck, setDeck] = useState<Deck | undefined>(
    deckId ? decks.find(deckId) : undefined
  );

  useEffect(() => {
    setDeck(deckId ? decks.find(deckId) : undefined);
  }, [decks, deckId]);

  return deck;
}

export default useDeck;
