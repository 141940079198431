import { useState } from "react";
import { useSwipeable } from "react-swipeable";

interface HorizontalContainerSwiperProps {
  itemsCount: number;
  itemWidth: number;
  xOffset: number; // used for left item margin
}

export const useHorizontalContainerSwiper = (
  props: HorizontalContainerSwiperProps
) => {
  const { itemsCount, itemWidth, xOffset } = props;

  const [swipePos, setSwipePos] = useState(-xOffset);
  const [swipeDelta, setSwipeDelta] = useState(0);
  const [idxSelectedItem, setIdxSelectedItem] = useState(0);

  const computeItemAnchoredSwipePos = (
    currentSwipePos: number,
    currentDelta: number
  ) => {
    let actualPosition = -(currentSwipePos + currentDelta);
    const mod = actualPosition % itemWidth; // position within an item
    const threshold =
      currentDelta < 0 ? itemWidth / 4 : itemWidth - itemWidth / 4;
    // c_log("pos:", actualPosition, "mod:", mod);
    actualPosition += mod < threshold ? -mod : itemWidth - mod;
    return -Math.max(0, Math.min(itemWidth * (itemsCount - 1), actualPosition));
  };

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      setSwipeDelta((_) => {
        const newDelta = eventData.deltaX;
        const anchorSwipePos = computeItemAnchoredSwipePos(swipePos, newDelta);
        setIdxSelectedItem(-anchorSwipePos / itemWidth);
        return newDelta;
      });
    },
    onSwiped: (_) => {
      setSwipePos((pos) => {
        let newPos = computeItemAnchoredSwipePos(pos, swipeDelta) - xOffset;
        setIdxSelectedItem(-(newPos + xOffset) / itemWidth);
        return newPos;
      });
      setSwipeDelta(0);
    },
    trackMouse: true,
  });

  return {
    handlers,
    idxSelectedItem,
    setIdxSelectedItem,
    swipePosition: swipePos + swipeDelta,
  };
};
