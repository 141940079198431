import * as React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { ExampleSentence } from "../../components/FlashCard/ExampleSentence";
import { Synonym } from "../../components/FlashCard/Synonym";
import { IndexDbCard } from "../../components/DeckItem/useFetchCards";
import { useCardAudio } from "../../components/FlashCard/useCardAudio";
import { DeckEntry } from "./DeckEntry";
import { Word } from "../../components/FlashCard/Word";
import { Deck } from "../../contexts/AppData/DecksData";
import { EntryBox } from "./EntryBox";

type SearchEntryProps = {
  card: IndexDbCard;
  expanded?: boolean;
  focused: boolean;
  onClick: (value: IndexDbCard | Deck) => void;
};

export const CardEntry = React.forwardRef<HTMLDivElement, SearchEntryProps>(
  ({ card, expanded, focused, onClick }, ref) => {
    const { decks, settings } = useAppData();
    const wordAudio = useCardAudio();

    const handleSpeakerClick = () => {
      const deck = decks.find(card.deckId)!;
      wordAudio.play({ text: card.word, lang: deck.info.lang }, card.id, {
        idx: 0,
        name: "word",
      });
    };

    return (
      <EntryBox
        ref={ref}
        onClick={!expanded ? () => onClick(card) : undefined}
        focused={!expanded && focused}
        hoverable={!expanded}
        sx={{
          display: "flex",
          mb: 1,
          alignItems: "baseline",
          flexDirection: { xs: "column", sm: "row" },
          cursor: !expanded ? "pointer" : undefined,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "150px",
            alignItems: "baseline",
            flexShrink: 0,
          }}
        >
          <Word
            card={card}
            audioState={wordAudio.state}
            onPlayAudio={handleSpeakerClick}
            minimal
          />
          {expanded && (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {card.synonyms.map((synonym, idx) => {
                const deck = decks.find(card.deckId)!;
                return (
                  <Synonym
                    key={`${synonym}${idx}`}
                    synonym={synonym}
                    deck={deck}
                    card={card}
                    idx={idx}
                    minimal
                  />
                );
              })}
            </Box>
          )}
        </Box>
        <Box>
          <Typography
            gutterBottom
            sx={{
              textAlign: "left",
              ...(expanded ? { fontWeight: 500, mt: { xs: 2, sm: 0 } } : {}),
            }}
          >
            {card.short}
          </Typography>
          {expanded && (
            <React.Fragment>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ textAlign: "left" }}
              >
                {card.long}
              </Typography>
              {settings.compactView.get && (
                <Divider sx={{ width: "25%", mx: "auto", mt: 2, mb: 1 }} />
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {card.examples.map((example, idx) => (
                  <ExampleSentence
                    key={`${card.id}${idx}`}
                    sentence={example}
                    answerShown={true}
                    card={card}
                    idx={idx}
                  />
                  // <Typography variant="body2" sx={{ textAlign: "left" }}>
                  //   {example}
                  // </Typography>
                ))}
              </Box>
              <DeckEntry
                deck={decks.find(card.deckId)!}
                onClick={(deck) => onClick(deck)}
                minimal
              />
              <Divider sx={{ mt: 2 }} />
            </React.Fragment>
          )}
        </Box>
      </EntryBox>
    );
  }
);
