import { FC, Fragment, useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { SxProps, alpha, styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import useMobileView from "../../hooks/useMobileView";

const BlurredBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: alpha(theme.palette.background.default, 0.01),
  backdropFilter: "blur(5px)",
}));

export type ShowDialog = (
  body: JSX.Element,
  options?: {
    dialogProps?: Partial<DialogProps>;
    sx?: SxProps;
  }
) => CloseDialog;

export type CloseDialog = () => void;

export interface DialogPopupProps {
  body: JSX.Element | null;
  dialogProps?: Partial<DialogProps>;
  sx?: SxProps;
  show: ShowDialog;
  close: CloseDialog;
}

export function useDialogPopup(): DialogPopupProps {
  const [body, setBody] = useState<JSX.Element | null>(null);
  const [sx, setSx] = useState<SxProps>();
  const [dialogProps, setDialogProps] = useState<Partial<DialogProps>>();

  const show: ShowDialog = (
    body: JSX.Element,
    options?: {
      dialogProps?: Partial<DialogProps>;
      sx?: SxProps;
    }
  ) => {
    setBody(body);
    setSx(options?.sx);
    setDialogProps(options?.dialogProps);

    return close;
  };

  const close: CloseDialog = () => setBody(null);

  return { body, dialogProps, sx, show, close };
}

export const DialogPopup: FC<{ use: DialogPopupProps }> = ({ use }) => {
  const { body, dialogProps, sx, close } = use;
  const isMobile = useMobileView();

  return (
    <Fragment>
      <Dialog
        {...dialogProps}
        open={body !== null}
        sx={{
          ...(isMobile && dialogProps?.fullWidth
            ? {
                "& .MuiDialog-container .MuiDialog-paper": {
                  m: 0,
                  p: 0,
                  width: "100%",
                },
              }
            : {}),
        }}
        // hideBackdrop
      >
        <DialogContent sx={{ ...sx }}>{body}</DialogContent>
      </Dialog>
      <BlurredBackdrop open={body !== null} />
    </Fragment>
  );
};
