import { useEffect, useRef, useState } from "react";
import { tCard } from "../../shared/types";
import { useAuth } from "./../AuthContext";
import { CancelPromises } from "../../components/DeckItem/useFetchCards";
import { useAlert } from "../Alert/AlertContext";
import { FirebaseError } from "firebase/app";
import { c_log } from "../../shared/console";

type CardAnswerInProgress = { cardId?: string; deckId?: string };

interface Lesson {
  cards: tCard[];
  // On-going promises that are still collecting (or waiting for
  // the AI card's generation) card's data from firestore db
  fetchPromises: Promise<void>[];
  cancelFetching: CancelPromises | null;
}

export interface LessonData {
  data: Lesson;
  pushCards: (cards: tCard[]) => void;
  popCard: () => tCard | undefined;
  deckId: string | undefined;
  cardsCount: number;
  cardsEmpty: () => boolean;
  getCurrentCard: () => tCard;
  isFetchingCards: () => boolean;
  reset: (deckId: string, cancelFetching: CancelPromises) => void;
  clear: () => void;

  // Used to prevent entering the deck/lesson after finishing the respective
  // deck/lesson session while the card answer remote call is still ongoing (
  // usually the last card answer call). When the last call is in progress it
  // results in the deck data not being up to date, so even though the lesson
  // was finished, it was still possible to start the lesson as
  // the deck's new/review cards were still not updated
  addAnswerInProgress: (cardId: string, deckId: string) => void;
  removeAnswerInProgress: (cardId: string) => void;
  hasPendingCardAnswer: (deckId: string) => boolean;
}

export default function useLessonData(): LessonData {
  const { user } = useAuth();
  // const { showAlert } = useAlert();
  const data = useRef<Lesson>({
    cards: [],
    fetchPromises: [],
    cancelFetching: null,
  });
  const [state, setState] = useState<{
    deckId?: string;
    cardsCount: number;
  }>({ deckId: undefined, cardsCount: 0 });
  const [answers, setAnswers] = useState<CardAnswerInProgress[]>([]);

  useEffect(() => {
    if (user === null) clear();
  }, [user]);

  // If the new learning session is started and in progress and
  // if the process of creating the new cards by the AI in
  // the background and fails then the exception that originates
  // from the back-end fetchcards cloud function is being handled here
  useEffect(() => {
    const fetchCardsExceptionsHandler = async () => {
      try {
        // TODO!: Showing modal (waiting for cards fetch during learning
        // session) here instead of useFetchCards?
        await Promise.all(data.current.fetchPromises);
      } catch (error) {
        // if (error instanceof FirebaseError) showAlert(error.message, "error");
      }
    };
    fetchCardsExceptionsHandler();
  }, [data.current.fetchPromises]);

  const reset = (deckId: string, cancelFetching: CancelPromises) => {
    setState({ deckId, cardsCount: 0 });
    data.current.cards = [];
    data.current.fetchPromises = [];
    data.current.cancelFetching = cancelFetching;
  };

  const clear = () => {
    c_log("Clearing lesson's data, deck:", state.deckId);
    data.current = {
      cards: [],
      fetchPromises: [],
      cancelFetching: null,
    };
    setState({ cardsCount: 0 });
  };

  return {
    data: data.current,
    pushCards: (cards: tCard[]) => {
      data.current.cards.push(...cards);
      setState((prev) => ({ ...prev, cardsCount: data.current.cards.length }));
    },
    popCard: () => {
      const first = data.current.cards.shift();
      setState((prev) => ({ ...prev, cardsCount: data.current.cards.length }));
      return first;
    },
    deckId: state.deckId,
    cardsCount: state.cardsCount,
    cardsEmpty: () => data.current.cards.length === 0,
    getCurrentCard: () => data.current.cards[0],
    isFetchingCards: () => data.current.fetchPromises.length > 0,
    reset,
    clear,

    addAnswerInProgress: (cardId: string, deckId: string) => {
      setAnswers((prev) => [...prev, { cardId, deckId }]);
    },
    removeAnswerInProgress: (cardId: string) => {
      setAnswers((prev) => [...prev].filter((v) => v.cardId !== cardId));
    },
    hasPendingCardAnswer: (deckId: string) =>
      answers.some((v) => v.deckId === deckId),
  };
}
