import * as React from "react";
import { LazyComponent } from "../../components/LazyComponent";
import { CardEntry } from "./CardEntry";
import { DeckEntry } from "./DeckEntry";
import { IndexDbCard } from "../../components/DeckItem/useFetchCards";
import { Deck } from "../../contexts/AppData/DecksData";

interface EntryItemProps {
  data: IndexDbCard | Deck;
  expanded?: boolean;
  focused: boolean;
  onClick: (value: IndexDbCard | Deck) => void;
}

export const EntryItem = React.forwardRef<HTMLDivElement, EntryItemProps>(
  ({ data, onClick, expanded, focused }, ref) => {
    const isCard = (data as IndexDbCard).deckId;

    return (
      <LazyComponent key={data.id}>
        {isCard ? (
          <CardEntry
            ref={ref}
            card={data as IndexDbCard}
            expanded={expanded}
            focused={focused}
            onClick={onClick}
          />
        ) : (
          <DeckEntry
            ref={ref}
            deck={data as Deck}
            focused={focused}
            onClick={onClick}
            sx={{ mb: 1 }}
          />
        )}
      </LazyComponent>
    );
  }
);
