import { FC } from "react";
import { usePrimaryColor } from "./CardExamples";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import StopIcon from "@mui/icons-material/Stop";
import { SxProps } from "@mui/material/styles";
import { CardAudioState } from "./useCardAudio";
import { SvgIconProps } from "@mui/material";

interface SpeakerButtonProps extends SvgIconProps {
  disabled?: boolean;
  audioState: CardAudioState;
  onClick: () => void;
  sx?: SxProps;
}

export const SpeakerButton: FC<SpeakerButtonProps> = ({
  disabled,
  audioState,
  onClick,
  sx,
  ...rest
}) => {
  const color = usePrimaryColor(disabled);

  return (
    <IconButton
      disabled={disabled || audioState === CardAudioState.Fetching}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      sx={{ ...sx }}
    >
      {audioState === CardAudioState.Fetching ? (
        <CircularProgress size="1rem" />
      ) : audioState === CardAudioState.Playing ? (
        <StopIcon fontSize="small" sx={{ color }} {...rest} />
      ) : (
        <VolumeUpIcon fontSize="small" sx={{ color }} {...rest} />
      )}
    </IconButton>
  );
};
