import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
  timeout?: number;
  onTimeout?: () => void;
  onClick?: () => void;
  iconNormal: JSX.Element;
  iconBusy?: JSX.Element;
  sx?: SxProps<Theme>;
}

// Ref implementation based on StackOverflow:
// https://stackoverflow.com/questions/60554808/react-useref-with-typescript-and-functional-component
export interface RefCircularIntegration {
  SetLoading: (loading: boolean) => void;
}

export const CircularIntegration = forwardRef(
  (props: Props, ref: Ref<RefCircularIntegration>) => {
    const { timeout, onTimeout, onClick, iconNormal, iconBusy } = props;
    const [loading, setLoading] = useState(false);
    const timer = useRef<number>();

    useImperativeHandle(ref, () => ({ SetLoading }));

    function SetLoading(loading: boolean) {
      setLoading(loading);
    }

    useEffect(() => {
      return () => clearTimeout(timer.current);
    }, []);

    const handleButtonClick = () => {
      if (!loading) {
        setLoading(true);
        timer.current = window.setTimeout(() => {
          setLoading(false);
          onTimeout?.();
        }, timeout ?? 5000);
        onClick?.();
      }
    };

    return (
      <Fab
        aria-label="save"
        color="secondary"
        disabled={loading}
        onClick={handleButtonClick}
        sx={{ position: "fixed", zIndex: 11, bottom: "50px", ...props?.sx }}
      >
        <React.Fragment>
          {!loading ? iconNormal : iconBusy ?? iconNormal}
          {loading && (
            <CircularProgress
              size={68}
              thickness={2}
              sx={{
                color: "secondary.main",
                position: "absolute",
                top: -6,
                left: -6,
                zIndex: 10,
              }}
            />
          )}
        </React.Fragment>
      </Fab>
    );
  }
);
