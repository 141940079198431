import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps, alpha, useTheme } from "@mui/material/styles";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";

type SelectableButtonProps = {
  icon: JSX.Element;
  header: string;
  children?: React.ReactNode;
  selected: boolean;
} & (BoxProps & SxProps);

export const SelectableButton: React.FC<SelectableButtonProps> = ({
  icon,
  header,
  children,
  selected,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: selected
          ? `1px solid ${alpha(theme.palette.action.selected, 0.2)}`
          : `1px solid transparent`,
        borderRadius: `${theme.shape.borderRadius}px`,
        backgroundColor: selected
          ? `${theme.palette.background.paper}`
          : "none",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: {
            xs: "none",
            md: theme.palette.action.hover,
          },
        },
        ...sx,
      }}
      {...rest}
    >
      <Box
        component="span"
        sx={{ display: "flex", p: 3, alignItems: "center", width: "100%" }}
      >
        {React.cloneElement(icon, { sx: { marginRight: 2 } })}
        <Box>
          <Typography
            variant="body2"
            sx={{ textAlign: "left", fontWeight: 700 }}
            gutterBottom
          >
            {header}
          </Typography>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
