import { Buffer } from "buffer";
import { c_log } from "../shared/console";

// Asynchronously encoding file's content to base64 string
export function fileToBase64String(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onerror = (error) => reject(new Error("FileReader failure"));

    fileReader.onload = () => {
      // Getting rid of metadata based on https://stackoverflow.com/a/52311051/925394
      let encoded = fileReader.result!.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };

    fileReader.readAsDataURL(file);
  });
}

export function fileToBuffer(file: Blob) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onerror = (error) => reject(new Error("FileReader failure"));

    fileReader.onload = () => resolve(fileReader.result as ArrayBuffer);

    fileReader.readAsArrayBuffer(file);
  });
}

const mimeTypes: { [key: string]: number[] } = {
  gif: [0x47, 0x49, 0x46, 0x38, 0x39],
  jpeg: [0xff, 0xd8, 0xff, 0xe0],
  png: [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a],
  sqlite: [
    0x53, 0x51, 0x4c, 0x69, 0x74, 0x65, 0x20, 0x66, 0x6f, 0x72, 0x6d, 0x61,
    0x74, 0x20, 0x33, 0x00,
  ],
};

export enum FileType {
  Database,
  Image,
}

export function isFileType(buffer: ArrayBuffer, header: FileType) {
  const uint8arr = new Uint8Array(buffer);
  const check = (mime: number[]) => mime.every((v, idx) => uint8arr[idx] === v);

  switch (header) {
    case FileType.Database:
      return check(mimeTypes["sqlite"]);
    case FileType.Image:
      return (
        check(mimeTypes["jpeg"]) ||
        check(mimeTypes["png"]) ||
        check(mimeTypes["gif"])
      );
  }
}

export async function fileToBase64(file: File, fileType: FileType) {
  c_log(`Converting ${file.name} to base64 buffer`);

  const buffer = await fileToBuffer(file);
  if (!isFileType(buffer, fileType))
    throw Error(`Failed recognition of ${file.name}: unsupported file format`);

  return Buffer.from(buffer).toString("base64");
}
