import { FC, useEffect, useState } from "react";
import { useAppData } from "../AppData/AppDataContext";
import Badge from "@mui/material/Badge";
import useReviewsCount from "../../hooks/useReviewsCount";
import { MenuItemDescr } from "../../components/Menus/MenuItem";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import SchoolIcon from "@mui/icons-material/School";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

export const CountBadge: FC<{ count: number }> = ({ count, ...rest }) => {
  return <Badge {...rest} color="secondary" badgeContent={count} max={99} />;
};

export type MenuItems = MenuItemDescr[];

export function useMenu(): MenuItems {
  const { lesson } = useAppData();
  const reviewsCount = useReviewsCount();
  const { t } = useTranslation("translation", { keyPrefix: "menu" });

  const items: MenuItemDescr[] = [
    // {
    //   text: t("home"),
    //   icon: { normal: <HomeRoundedIcon />, focused: <HomeOutlinedIcon /> },
    //   link: "/xxx",
    // },
    {
      text: t("decks"),
      icon: {
        normal: <DashboardRoundedIcon />,
        focused: <DashboardOutlinedIcon />,
      },
      link: "/decks",
      badge: <CountBadge count={reviewsCount} />,
    },
    {
      text: t("import"),
      icon: {
        normal: <AutoStoriesRoundedIcon />,
        focused: <AutoStoriesOutlinedIcon />,
      },
      link: "/import",
    },
    {
      text: t("stats"),
      icon: {
        normal: <AssessmentRoundedIcon />,
        focused: <AssessmentOutlinedIcon />,
      },
      link: "/stats",
    },
    {
      text: t("help"),
      icon: {
        normal: <InfoIcon />,
        focused: <InfoOutlinedIcon />,
      },
      link: "/help",
    },
    {
      text: t("lesson"),
      icon: {
        normal: <SchoolIcon />,
        focused: <SchoolOutlinedIcon />,
      },
      link: "/learn",
      badge: <CountBadge count={lesson.cardsCount} />,
    },
  ];

  return lesson.cardsCount !== 0 ? items : items.slice(0, -1);
}
