import { useTranslation } from "react-i18next";
import {
  getBaseLanguageCode,
  countries as countriesCodeLang,
  LanguageCodes,
} from "./../shared/languages";

export const countries: string[] = countriesCodeLang.map((v) => v.code);

export function useCountryName() {
  const { t } = useTranslation("translation", { keyPrefix: "countries" });
  return (code: string) => {
    const baseCode = getBaseLanguageCode(code);
    return t(countries.find((country) => country === baseCode) ?? "en");
  };
}

export function getDefaultLanguage(): LanguageCodes {
  const lang = getBaseLanguageCode(navigator.language);
  return countries.some((v) => v === lang) ? (lang as LanguageCodes) : "en";
}
