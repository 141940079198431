import * as React from "react";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useKeyDown } from "../../hooks/useKeyDown";
import ClearIcon from "@mui/icons-material/Clear";
import { EscButton } from "../../components/EscButton";
import { useAuth } from "../../contexts/AuthContext";
import { tWordContextReq, tWordContextRes } from "../../shared/types";
import { AutocompleteLanguage } from "../../components/AutocompleteLanguage";
import { SectionHeader } from "../../components/Menus/SettingsDrawer";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { useBackdropClick } from "../../hooks/useBackdropClick";
import { alpha } from "@mui/material/";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { TermMatchEntry } from "./TermMatchEntry";

interface DialogAddCardProps {
  onClose: () => void;
}

export const DialogAddCard: React.FC<DialogAddCardProps> = ({ onClose }) => {
  const { t } = useTranslation("translation", { keyPrefix: "dialogs" });
  const [query, setQuery] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>();
  const langRef = React.useRef<HTMLSpanElement>(null);
  const { callCloudFn } = useAuth();
  const { settings } = useAppData();
  const [entries, setEntries] = React.useState<tWordContextRes>([]);
  const [processing, setProcessing] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);

  const dialogClose = () => {
    settings.synchronize();
    onClose();
  };

  useBackdropClick({ onClicked: dialogClose }, [settings]);

  useKeyDown((event: KeyboardEvent) => {
    if (event.key === "Escape") dialogClose();
  });

  React.useEffect(() => {
    if (inputRef.current && !showOptions) inputRef.current.focus();
  }, [showOptions, entries]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ display: "flex", mx: 1 }}>
            <SearchIcon
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            />
          </Box>
          <Typography
            ref={langRef}
            variant="caption"
            onClick={() => setShowOptions(!showOptions)}
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%) translateY(100%)",
              width: "100%",
              fontWeight: 600,
              mt: 1,
              color: ({ palette }) =>
                palette.mode === "light"
                  ? palette.primary.dark
                  : palette.primary.light,
              cursor: "pointer",
              ...(showOptions
                ? {
                    backgroundColor: ({ palette }) => palette.action.focus,
                    borderRadius: ({ shape }) => shape.borderRadius / 8,
                  }
                : {
                    "&:hover": {
                      backgroundColor: ({ palette }) =>
                        alpha(
                          palette.action.focus,
                          palette.action.focusOpacity
                        ),
                      borderRadius: ({ shape }) => shape.borderRadius / 8,
                    },
                  }),
            }}
          >
            {settings.newCard.get.lang}|{settings.newCard.get.targetLang}
          </Typography>
        </Box>
        <InputBase
          disabled={processing}
          inputRef={inputRef}
          // placeholder={`Type word or phrase to define`}
          placeholder={`Type word or phrase to create a flashcard`}
          inputProps={{ "aria-label": "search" }}
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              setProcessing(true);
              const res = await callCloudFn<tWordContextReq, tWordContextRes>(
                "wordcontext",
                {
                  text: query,
                  lang: settings.newCard.get.lang,
                  targetLang: settings.newCard.get.targetLang,
                }
              );

              // const isError = res.data as any as { error: string };
              // if (isError.error) showAlert(isError.error);
              // else setEntries(res.data ?? []);

              setEntries(res.data ?? []);
              setProcessing(false);
            }
          }}
          sx={{ flex: 1, fontSize: "1.25rem", ml: 1 }}
          endAdornment={
            <InputAdornment position="start">
              {query && (
                <IconButton
                  disabled={processing}
                  onClick={() => {
                    setQuery("");
                    if (inputRef.current) inputRef.current.focus();
                  }}
                >
                  {processing ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    <ClearIcon />
                  )}
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <EscButton onClick={dialogClose} />
      </Box>
      <Divider />
      <Popper
        open={showOptions}
        anchorEl={langRef.current}
        placement="bottom-start"
        sx={{ zIndex: 1301, width: "160px" }}
        disablePortal
        //transition
      >
        <ClickAwayListener onClickAway={() => setShowOptions(false)}>
          <Paper
            sx={{
              boxShadow: 3,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "200px",
              textAlign: "left",
              px: 1.5,
              py: 1,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SectionHeader sx={{ m: 0.5 }}>{t("lang1")}</SectionHeader>
                <Tooltip title={t("lang1_hint")} placement="right">
                  <HelpOutlineIcon
                    sx={{ cursor: "pointer", fontSize: "1rem" }}
                  />
                </Tooltip>
              </Box>
              <AutocompleteLanguage
                value={settings.newCard.get.lang}
                onChange={(langCode) => {
                  settings.newCard.set((prev) => ({ ...prev, lang: langCode }));
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <SectionHeader sx={{ m: 0.5 }}>{t("lang2")}</SectionHeader>
                <Tooltip title={t("lang2_hint")} placement="right">
                  <HelpOutlineIcon
                    sx={{ cursor: "pointer", fontSize: "1rem" }}
                  />
                </Tooltip>
              </Box>
              <AutocompleteLanguage
                value={settings.newCard.get.targetLang}
                onChange={(langCode) => {
                  settings.newCard.set((prev) => ({
                    ...prev,
                    targetLang: langCode,
                  }));
                }}
              />
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {/* <Tooltip title={!showOptions ? "Search query options" : ""}>
        <IconButton
          disableRipple
          sx={{ alignSelf: "center", width: "1rem", height: "1rem" }}
          onClick={() => setShowOptions(!showOptions)}
        >
          <KeyboardArrowDownIcon
            fontSize="small"
            sx={{
              transform: !showOptions ? "rotate(0deg)" : "rotate(180deg)",
              transition: (theme) =>
                theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shortest,
                }),
            }}
          />
        </IconButton>
      </Tooltip> */}
      <Box sx={{ p: 2, overflow: "auto" }}>
        {entries.map((entry, idx) => (
          <React.Fragment key={entry.context}>
            <TermMatchEntry
              entry={entry}
              onClick={() => {
                setEntries((prev) => prev.filter((v) => v !== entry));
              }}
            />
            {idx < entries.length - 1 && (
              <Divider sx={{ width: "95%", mx: "auto", my: 3 }} />
              // <Divider sx={{ ml: "150px", my: 3 }} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
