// Compares two strings and returns a value [0, 1] where 0
// signifies that the two strings are completely different whilst
// 1 signifies complete equality
export function strComparePercent(a: string, b: string) {
  if (a.length === 0 && b.length === 0) return 1;

  a = a.replace("/-/g", "");
  b = b.replace("/-/g", "");

  const maxLength = Math.max(a.length, b.length);
  const diffs = Array.from(a).map((c, idx) => b[idx] && c === b[idx]);
  return diffs.filter((v) => v).length / maxLength;
}
