import React, { useState } from "react";
import { Snackbar, Button } from "@mui/material";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import { useTranslation } from "react-i18next";

export const CookieBanner: React.FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "landing_page" });
  const { theme } = useCustomTheme();
  const [open, setOpen] = useState(!localStorage.getItem("cookieConsent"));

  const handleAccept = () => {
    setOpen(false);
    localStorage.setItem("cookieConsent", "true");
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message={t("cookie_info")}
      action={
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={handleAccept}
          sx={{ ml: theme.spacing(2) }}
        >
          {t("accept")}
        </Button>
      }
    />
  );
};
