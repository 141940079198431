import React, { useState } from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  Divider,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ThemeMode } from "../../contexts/CustomTheme/CustomThemeContext";
import useMobileView from "../../hooks/useMobileView";
import useUserSignOut from "../../hooks/useUserSignOut";
import { NavBarButton } from "./NavBar";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { AutocompleteLanguage } from "../AutocompleteLanguage";
import { useTranslation } from "react-i18next";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import GridViewIcon from "@mui/icons-material/GridView";
import { InputMode } from "../../contexts/AppData/SettingsData";

export const SettingsButton: React.FC = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { settings } = useAppData();

  return (
    <React.Fragment>
      <NavBarButton
        aria-label="settings"
        size="small"
        onClick={() => setShowDrawer(!showDrawer)}
      >
        <SettingsOutlinedIcon fontSize="small" />
      </NavBarButton>
      <SettingsDrawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          settings.synchronize();
        }}
      />
    </React.Fragment>
  );
};

export const SectionHeader = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "0.6875rem",
  letterSpacing: "0.08rem",
  fontWeight: "700",
  color: theme.palette.text.secondary,
  margin: "20px 0px 10px",
  cursor: "default",
}));

const OneLineItem = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "default",
}));

interface SettingsDrawerProps {
  open: boolean;
  onClose: () => void;
}

const SettingsDrawer: React.FC<SettingsDrawerProps> = ({ open, onClose }) => {
  const isMobile = useMobileView();
  const signout = useUserSignOut();
  const { settings } = useAppData();
  const theme = useTheme();
  const { t } = useTranslation("translation", { keyPrefix: "settings" });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={(_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") onClose();
      }}
      PaperProps={{
        sx: {
          width: "360px",
          maxWidth: isMobile ? "90%" : "360px",
          borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px`,
        },
      }}
    >
      <Box
        p={"16px"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "500", cursor: "default" }}>
          {t("settings")}
        </Typography>
        <IconButton
          color="primary"
          size="small"
          sx={{ border: "none" }}
          onClick={onClose}
        >
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Box px={"16px"} sx={{ flexGrow: 1 }}>
        <SectionHeader gutterBottom>{t("mode")}</SectionHeader>
        <ToggleButtonGroup
          value={settings.themeMode.get}
          exclusive
          fullWidth
          aria-label="theme group"
          onChange={(_, newTheme: ThemeMode) => {
            if (newTheme) settings.themeMode.set(newTheme);
          }}
        >
          <ToggleButton value="light" size="small" sx={{ overflow: "hidden" }}>
            <LightModeIcon fontSize="small" sx={{ marginRight: "8px" }} />
            {t("light")}
          </ToggleButton>
          <ToggleButton value="system" size="small" sx={{ overflow: "hidden" }}>
            <SettingsBrightnessIcon
              fontSize="small"
              sx={{ marginRight: "8px" }}
            />
            {t("system")}
          </ToggleButton>
          <ToggleButton value="dark" size="small" sx={{ overflow: "hidden" }}>
            <DarkModeIcon fontSize="small" sx={{ marginRight: "8px" }} />
            {t("dark")}
          </ToggleButton>
        </ToggleButtonGroup>
        <OneLineItem sx={{ my: 1 }}>
          <Tooltip title={t("monochromatic_hint")} placement="top">
            <Typography>{t("monochromatic")}</Typography>
          </Tooltip>
          <Switch
            checked={settings.monochromatic.get}
            onChange={(event) =>
              settings.monochromatic.set(event.target.checked)
            }
          />
        </OneLineItem>
        <SectionHeader gutterBottom>{t("language")}</SectionHeader>
        <AutocompleteLanguage
          value={settings.language.get}
          onChange={(value) => settings.language.set(value)}
          sx={{ pb: 1 }}
        />
        <SectionHeader gutterBottom>{t("lesson")}</SectionHeader>

        <Tooltip title={t("input_hint")} placement="top">
          <ToggleButtonGroup
            value={settings.inputMode.get}
            exclusive
            fullWidth
            aria-label="input group"
            onChange={(_, newInput: InputMode) => {
              if (newInput) settings.inputMode.set(newInput);
            }}
          >
            <ToggleButton
              value="buttons"
              size="small"
              sx={{ overflow: "hidden" }}
            >
              <GridViewIcon fontSize="small" sx={{ marginRight: "8px" }} />
              {t("buttons")}
            </ToggleButton>
            <ToggleButton value="keys" size="small" sx={{ overflow: "hidden" }}>
              <KeyboardIcon fontSize="small" sx={{ marginRight: "8px" }} />
              {t("keyboard")}
            </ToggleButton>
            <ToggleButton value="mic" size="small" sx={{ overflow: "hidden" }}>
              <KeyboardVoiceIcon fontSize="small" sx={{ marginRight: "8px" }} />
              {t("microphone")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Tooltip>

        <OneLineItem sx={{ mt: 1 }}>
          <Tooltip title={t("auto_play_audio_hint")} placement="top">
            <Typography>{t("auto_play_audio")}</Typography>
          </Tooltip>
          <Switch
            checked={settings.autoPlay.get}
            onChange={(event) => settings.autoPlay.set(event.target.checked)}
          />
        </OneLineItem>
        {settings.inputMode.get === "mic" && (
          <OneLineItem>
            <Tooltip title={t("auto_play_recording_hint")} placement="top">
              <Typography>{t("auto_play_recording")}</Typography>
            </Tooltip>
            <Switch
              checked={settings.autoPlayRecording.get}
              onChange={(event) =>
                settings.autoPlayRecording.set(event.target.checked)
              }
            />
          </OneLineItem>
        )}
        <OneLineItem>
          <Tooltip title={t("compact_view_hint")} placement="top">
            <Typography>{t("compact_view")}</Typography>
          </Tooltip>
          <Switch
            checked={settings.compactView.get}
            onChange={(event) => settings.compactView.set(event.target.checked)}
          />
        </OneLineItem>
      </Box>
      <Button
        variant="contained"
        sx={{ m: 2 }}
        onClick={(event) => {
          event.preventDefault();
          signout();
        }}
      >
        {t("sign_out")}
      </Button>
    </Drawer>
  );
};
