import { useState } from "react";
import { startRecording, stopRecording } from "../common/microphone";
import { useAlert } from "../contexts/Alert/AlertContext";

export function useMicrophoneRecording(
  stopAfterMs: number = 5000
): [boolean, () => Promise<number[] | null>] {
  const { showAlert } = useAlert();
  const [recording, setRecording] = useState(false);

  const execute = async () => {
    if (recording) return await stop();

    const autoStopTimer = setTimeout(stop, stopAfterMs);
    try {
      setRecording(true);
      const recordingData = await startRecording();
      setRecording(false);
      clearTimeout(autoStopTimer);
      return recordingData;
    } catch (error) {
      if (error instanceof DOMException) showAlert(error.message);
      clearTimeout(autoStopTimer);
      setRecording(false);
      return null;
    }
  };

  const stop = async () => {
    await stopRecording();
    setRecording(false);
    return null;
  };

  return [recording, execute];
}
