import { Box, Button, Card, Typography } from "@mui/material";
import ccvImage from "../../assets/ccv.png";
import stripeImage from "../../assets/stripe.svg";
import StripeInputField from "./StripeInputField";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { FC, FormEvent, ReactNode, useRef } from "react";
import TextFieldRegEx from "./TextFieldRegEx";
import useFormValidator from "./useFormValidator";
import { useTranslation } from "react-i18next";
import useSubmit from "./useSubmit";
import { tSubscribeRes } from "../../shared/types";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { useAuth } from "../../contexts/AuthContext";

interface CheckoutFormProps {
  onSubmit: (result: tSubscribeRes | null) => void;
}
// TODO!: Should there be also Country or Region Combobox in the form (STRIPE AddressElement)?
//        Consider using PaymentElement which includes different payments options
const CheckoutForm: React.FC<CheckoutFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "subscription_page",
  });
  const { user } = useAuth();
  const email = useRef<string>(user?.email ?? "");
  const name = useRef<string>("");
  const { processing, submit } = useSubmit(email, name);
  const inputValidator = useFormValidator();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const result = await submit(event);
    onSubmit(result);
  };

  return (
    <form onSubmit={handleSubmit} style={{ flexGrow: 1, minWidth: "400px" }}>
      <Card sx={{ px: 4, py: 2, height: "100%", my: 4, ml: { md: 4 } }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("pay_with_card")}
        </Typography>
        <Header>{t("email")}</Header>
        <TextFieldRegEx
          id="email"
          regex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
          defaultValue={email.current}
          onChange={(value) => (email.current = value as string)}
          disabled={processing}
          validator={inputValidator}
          sx={{ mb: 1 }}
        />
        <Header>{t("card_number")}</Header>
        <StripeInputField
          id="cardNumber"
          stripeComponent={CardNumberElement}
          disabled={processing}
          validator={inputValidator}
          sx={{ mb: 1 }}
        />
        <Box sx={{ display: "flex", mb: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Header>{t("expiration_date")}</Header>
            <StripeInputField
              id="expirationDate"
              stripeComponent={CardExpiryElement}
              disabled={processing}
              validator={inputValidator}
            />
          </Box>
          <Box sx={{ m: 1 }} />
          <Box sx={{ flexGrow: 1 }}>
            <Header>{t("ccv")}</Header>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StripeInputField
                id="ccvSecurity"
                stripeComponent={CardCvcElement}
                disabled={processing}
                validator={inputValidator}
              />
              <img
                src={ccvImage}
                style={{ height: "2.5rem", marginLeft: "8px" }}
              />
            </Box>
          </Box>
        </Box>
        <Header>{t("card_holder")}</Header>
        <TextFieldRegEx
          id="cardHolder"
          regex={/^[\p{L}\p{M}\s.'-]+$/u}
          onChange={(value) => (name.current = value as string)}
          disabled={processing}
          validator={inputValidator}
        />
        <Box>
          <Header>{t("expiration_date")}</Header>
          <AddressElement options={{ mode: "billing" }} />
        </Box>

        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          disabled={processing || !inputValidator.valid}
          sx={{ mt: 3 }}
        >
          {processing ? t("processing") : t("subscribe")}
        </Button>
        <Typography variant="body2" textAlign={"center"} mt={2}>
          {t("subscription_confirmation")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <Typography>{t("secured_by")}</Typography>
          <Box sx={{ position: "relative" }}>
            <img
              src={stripeImage}
              style={{ display: "block", height: "2.5rem" }}
            />
            <GppGoodOutlinedIcon
              sx={{
                color: "#635bff",
                position: "absolute",
                height: "100%",
                top: 0,
                right: "-1rem",
              }}
            />
          </Box>
        </Box>
      </Card>
    </form>
  );
};

const Header: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography variant="body2" gutterBottom>
    {children}
  </Typography>
);

export default CheckoutForm;
