import { FC, MouseEventHandler } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { useAlert } from "../../contexts/Alert/AlertContext";
import { DialogSearch } from "../../dialogs/DialogSearch/DialogSearch";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useMobileView from "../../hooks/useMobileView";
import { NavBarButton } from "./NavBar";

export const SearchField: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "dialogs" });
  const { showDialog } = useAlert();
  const isMobile = useMobileView(450);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    const activeFocus = document.activeElement as HTMLElement;
    if (activeFocus) activeFocus.blur();

    const closeDialog = showDialog(
      <DialogSearch onClose={() => closeDialog()} />,
      {
        dialogProps: {
          fullWidth: true,
          maxWidth: "md",
          onClose: (_, reason) => {
            if (reason === "backdropClick") closeDialog();
          },
        },
        sx: { p: 0, width: "100%", height: "600px" },
      }
    );
  };

  return isMobile ? (
    <NavBarButton size="small" onClick={handleClick}>
      <SearchIcon fontSize="small" />
    </NavBarButton>
  ) : (
    <Box
      component="button"
      sx={{
        display: "flex",
        alignItems: "center",
        py: "5px",
        mx: 0.5,
        minWidth: "150px",
        cursor: "pointer",
        color: (theme) => theme.palette.text.primary,
        border: (theme) => `1px solid ${alpha(theme.palette.divider, 0.8)}`,
        borderRadius: (theme) => theme.shape.borderRadius / 8,
        background: (theme) => alpha(theme.palette.primary.main, 0.05),
        transition: (theme) =>
          theme.transitions.create("background", { duration: "0.2s" }),
        "&:hover": {
          border: (theme) => `1px solid ${alpha(theme.palette.divider, 1)}`,
          background: (theme) => alpha(theme.palette.primary.main, 0.1),
        },
      }}
      onClick={handleClick}
    >
      <SearchIcon fontSize="small" />
      <Typography
        sx={{ fontFamily: "Plus Jakarta Sans", fontSize: "0.8rem", mx: 1 }}
      >
        {`${t("search")}...`}
      </Typography>
    </Box>
  );
};
