import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { tDeck } from "../../shared/types";
import { BookCover } from "../../components/BookItem/BookCover";
import { TranslationFlags } from "../../components/BookItem/TranslationFlags";
import { BookTitle } from "../../components/BookItem/BookItem";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { c_log } from "../../shared/console";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

export interface DialogSelectDeckProps {
  deck: tDeck;
  onCancelClick?: () => void;
}

export const DialogPrepareDeck: FC<DialogSelectDeckProps> = ({
  deck,
  onCancelClick,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "dialogs" });

  return (
    <Box
      onKeyDown={(event) => {
        event.preventDefault();
        c_log("Event", event.key);
        if (event.key === "Escape") onCancelClick?.();
      }}
      sx={{
        m: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="caption" gutterBottom>
        {t("preparing_deck")}
      </Typography>
      <BookCover
        coverUrl={deck.info.coverUrl}
        sx={{ mt: 1, height: "256px" }}
      />
      <Box my="1rem">
        <BookTitle>{deck.info.name}</BookTitle>
      </Box>
      <TranslationFlags
        langBook={deck.info.lang}
        langTarget={deck.info.targetLang}
        sx={{ mb: 2 }}
      />
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{t("generating_new_cards")}</Typography>
          <Tooltip title={t("generating_new_cards_hint")} placement="top">
            <HelpOutlineIcon sx={{ ml: 1, cursor: "pointer" }} />
          </Tooltip>
        </Box>
        <LinearProgress sx={{ width: "100%" }} />
      </Box>
      <Button
        variant="contained"
        onClick={onCancelClick}
        sx={{ mt: 2, width: "100%" }}
      >
        {t("cancel")}
      </Button>
    </Box>
  );
};
