import { SxProps, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Video } from "../../components/Video";

const Header = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "1.5rem",
  fontWeight: 700,
  margin: "20px 0px 10px",
  textAlign: "left",
}));

const Body = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  paddingBottom: 1,
  textAlign: "left",
}));

export const ChromeExtensionPage: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "import_chrome_extension_page",
  });

  return (
    <Box sx={{ width: "95%", m: "3rem auto 3rem auto" }}>
      <Video fileName="chromex.mov" title={t("installation_caption")} />
      <Header variant="h1">{t("about")}</Header>
      <Body>{t("about_description")}</Body>
      <Header variant="h1">{t("installation")}</Header>
      <Body>{t("installation_description")}</Body>
      <Header variant="h1">{t("usage")}</Header>
      <Body>{t("usage_description")}</Body>
    </Box>
  );
};

interface ChromeExtensionPageShortProps {
  sx?: SxProps;
}

export const ChromeExtensionPageShort: FC<ChromeExtensionPageShortProps> = ({
  sx,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "import_chrome_extension_page",
  });

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Video
        fileName="chromex.mov"
        title={t("installation_caption")}
        sx={{ width: { xs: "100%", md: "80%" } }}
      />
      <Body>{t("about_description")}</Body>
    </Box>
  );
};
