import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { CardAudioState, useCardAudio } from "./useCardAudio";
import { SpeakerButton } from "./SpeakerButton";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import { openNewTab } from "../../common/browser";
import { IndexDbCard } from "../DeckItem/useFetchCards";
import { SentenceWithBlanks } from "../SentenceWithBlanks";
import CircularProgress from "@mui/material/CircularProgress";

interface ExampleSentenceProps {
  sentence: string;
  answerShown: boolean;
  url?: string;
  card: IndexDbCard;
  idx: number;
}

export const ExampleSentence: FC<ExampleSentenceProps> = ({
  sentence,
  answerShown,
  url,
  card,
  idx,
}) => {
  const { decks, settings } = useAppData();
  const audio = useCardAudio();
  const compact = settings.compactView.get;

  useEffect(() => {
    if (!answerShown) audio.stop();
  }, [answerShown]);

  const play = async () => {
    if (!answerShown) return;

    const filledInSentence = sentence.replace(/\[\[(.*?)\]\]/g, "$1");
    const deck = decks.find(card.deckId)!;
    audio.play({ text: filledInSentence, lang: deck.info.lang }, card.id, {
      name: "examples",
      idx,
    });
  };

  return (
    <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
      {!settings.compactView.get && (
        <Box sx={{ minWidth: "36px", minHeight: "36px" }}>
          <SpeakerButton
            disabled={!answerShown}
            onClick={play}
            audioState={audio.state}
          />
        </Box>
      )}
      <SentenceWithBlanks
        sentence={sentence}
        expanded={answerShown}
        sx={{ cursor: answerShown && compact ? "pointer" : undefined }}
        onClick={() => {
          if (compact && audio.state !== CardAudioState.Fetching) play();
        }}
      >
        {compact && audio.state === CardAudioState.Fetching && (
          <CircularProgress
            size="1rem"
            sx={{ display: "inline-flex", p: "2px" }}
          />
        )}
      </SentenceWithBlanks>
      {/* <UrlLink url={url} show={answerShown} /> */}
    </Box>
  );
};

// const UrlLink: FC<{ url?: string; show: boolean }> = ({ url, show }) => {
//   const visible = show && url !== undefined;
//   return visible ? (
//     <IconButton onClick={() => openNewTab(url)}>
//       <LinkIcon />
//     </IconButton>
//   ) : null;
// };
