import { useEffect, useState } from "react";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import { FlashCardProps } from "./FlashCard";
import { SettingsMenuItem } from "./CardSettingsMenu";
import { useTranslation } from "react-i18next";
import ArchiveIcon from "@mui/icons-material/Archive";
import LinkIcon from "@mui/icons-material/Link";
import { openNewTab } from "../../common/browser";
import { tCard } from "../../shared/types";

const actionRegenerate = () => {
  // Send info to the server to regenerate the card
};

const actionUrlLink = (card: tCard) => {
  if (card.url) openNewTab(card.url);
};

export function useCardSettingsMenuItems(use: FlashCardProps) {
  const { card, onBury } = use;
  const { t } = useTranslation("translation", { keyPrefix: "lesson" });
  const [menuItems, setMenuItems] = useState<SettingsMenuItem[]>([
    {
      text: "Regenerate",
      hint: "Create the card anew",
      icon: <RestorePageIcon />,
      action: actionRegenerate,
    },
    {
      text: t("bury"),
      hint: t("bury_hint"),
      icon: <ArchiveIcon />,
      action: onBury,
    },
  ]);

  useEffect(() => {
    setMenuItems((prev) => {
      const items = prev.filter((item) => item.text !== t("url_link"));
      return !card.url
        ? items
        : [
            {
              text: t("url_link"),
              hint: `${t("url_link_hint")}: ${card.url}`,
              icon: <LinkIcon />,
              action: () => actionUrlLink(card),
            },
            ...items,
          ];
    });
  }, [card]);

  return menuItems;
}
