import * as React from "react";
import Box from "@mui/material/Box";
import { Divider, IconButton, InputAdornment, InputBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { EscButton } from "../../components/EscButton";
import { SearchQueryArgs, useSearchQuery } from "./useSearchQuery";
import { IndexDbCard } from "../../components/DeckItem/useFetchCards";
import { Deck } from "../../contexts/AppData/DecksData";
import { EntryItem } from "./EntryItem";
import { useKeyNavigation } from "./useKeyNavigation";

interface DialogSearchProps {
  onClose: () => void;
}

export const DialogSearch: React.FC<DialogSearchProps> = ({ onClose }) => {
  const { t } = useTranslation("translation", { keyPrefix: "dialogs" });
  const inputRef = React.useRef<HTMLInputElement>();
  const [input, setInput] = React.useState<SearchQueryArgs>({ query: "" });
  const { items, breakIdx } = useSearchQuery(input);
  const { focusIdx, itemRefs } = useKeyNavigation(items.length, {
    onClose: () => onClose(),
    onEnter: () => itemRefs.current.at(focusIdx)?.click(),
  });

  React.useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const entryItemClickHandler = (value: IndexDbCard | Deck) => {
    if (inputRef.current) inputRef.current.focus();

    const isCard = (value as IndexDbCard).deckId;

    const query = isCard
      ? (value as IndexDbCard).word
      : (value as Deck).info.name;

    setInput({ query, selected: value });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <SearchIcon sx={{ mx: 1 }} />
        <InputBase
          inputRef={inputRef}
          placeholder={`${t("search_by")}`}
          inputProps={{ "aria-label": "search" }}
          value={input.query}
          onChange={(e) => {
            setInput({ query: e.currentTarget.value });
          }}
          sx={{ flex: 1, fontSize: "1.25rem" }}
          endAdornment={
            <InputAdornment position="start">
              {/* {selected?.deck && <DeckEntry deck={selected.deck} minimal />} */}
              {input.query && (
                <IconButton
                  onClick={() => {
                    setInput({ query: "" });
                    if (inputRef.current) inputRef.current.focus();
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <EscButton onClick={onClose} />
      </Box>
      <Divider />
      <Box sx={{ p: 2, overflow: "auto" }}>
        {items.map((item, idx) => (
          <React.Fragment key={item.id}>
            {idx === breakIdx && (
              <Divider sx={{ my: 2, width: "25%", mx: "auto" }} />
            )}
            <EntryItem
              ref={(el) => (itemRefs.current[idx] = el!)}
              data={item}
              expanded={items.length === 1}
              focused={focusIdx === idx}
              onClick={entryItemClickHandler}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
