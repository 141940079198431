import React, { FC } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { FlashCardProps, SectionHeader } from "./FlashCard";
import { useTranslation } from "react-i18next";
import { alpha, styled } from "@mui/material/styles";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import CloseIcon from "@mui/icons-material/Close";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import { Synonym } from "./Synonym";
import { CardAudioState } from "./useCardAudio";
import { Word } from "./Word";

interface CardAnswerProps {
  use: FlashCardProps;
  audioState: CardAudioState;
  typedAnswer: string;
  onPlayAudio: () => void;
  onPlayRecording: () => void;
}

export const CardAnswer: FC<CardAnswerProps> = ({
  use: { card, deck },
  audioState,
  typedAnswer,
  onPlayAudio,
  onPlayRecording,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "lesson" });
  const { settings } = useAppData();

  return (
    <React.Fragment>
      {!settings.compactView.get && (
        <SectionHeader variant="caption">{t("answer")}</SectionHeader>
      )}
      <Word card={card} audioState={audioState} onPlayAudio={onPlayAudio}>
        {settings.inputMode.get !== "buttons" && (
          <TypedAnswer
            answer={typedAnswer.length !== 0 ? typedAnswer : undefined}
            word={card.word}
            onClick={() => onPlayRecording()}
          />
        )}
      </Word>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: 1,
        }}
      >
        {card.synonyms.map((synonym, idx) => (
          <Synonym
            key={`${synonym}${idx}`}
            synonym={synonym}
            deck={deck}
            card={card}
            idx={idx}
          />
        ))}
      </Box>
    </React.Fragment>
  );
};

const AnswerChip = styled(Chip)(({ label }) => ({
  display: "flex",
  ...(!label
    ? {
        "& .MuiChip-label": {
          paddingLeft: 3,
          paddingRight: 3,
          visibility: "hidden",
        },
      }
    : {}),
}));

const TypedAnswer: FC<{ answer?: string; word: string } & BoxProps> = ({
  answer,
  word,
  ...rest
}) => {
  const { settings } = useAppData();
  const correct = answer ? answer.toLowerCase() === word.toLowerCase() : false;

  return (
    <Box
      {...rest}
      sx={{
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateX(100%) translateY(-50%)",
        pl: 2,
      }}
    >
      <AnswerChip
        label={correct ? undefined : answer}
        color={correct ? "success" : "error"}
        variant="outlined"
        size="small"
        icon={
          correct ? (
            <DoneSharpIcon fontSize="small" />
          ) : (
            <CloseIcon fontSize="small" />
          )
        }
        sx={{
          cursor: settings.inputMode.get === "mic" ? "pointer" : undefined,
          transition: "none",
          "&:hover": {
            ...(settings.inputMode.get === "mic"
              ? {
                  backgroundColor: ({ palette }) =>
                    alpha(
                      correct ? palette.success.main : palette.error.main,
                      0.2
                    ),
                }
              : {}),
          },
        }}
      />
    </Box>
  );
};
