import { tDeck } from "../../shared/types";
import React, { ReactNode } from "react";
import useSettingsData, { SettingsData } from "./SettingsData";
import useIndexedDB, {
  DBConfig,
  UseIndexedDBResult,
} from "../../hooks/useIndexedDB";
import useDecksData, { DecksData } from "./DecksData";
import useSearchQueryData, { SearchQueryData } from "./SearchQueryData";
import useLessonData, { LessonData } from "./LessonData";
import { useAuth } from "../AuthContext";

type Stores = "cards" | "answers" | "audio";

const indexDbConfig: (uid: string) => DBConfig<Stores> = (uid) => ({
  name: `lingy-${uid}`,
  stores: [
    {
      name: "cards",
      options: { keyPath: "id" },
      // index: [{ name: "", key: "id", options: { unique: true } }],
    },
    {
      name: "answers",
      options: { keyPath: "id" },
    },
    {
      name: "audio",
      options: { keyPath: "id" },
    },
  ],
});

interface AppDataContextType {
  indexedDb: UseIndexedDBResult<Stores>;
  settings: SettingsData;
  decks: DecksData;
  searchQuery: SearchQueryData;
  lesson: LessonData;
}

const AppDataContext = React.createContext<AppDataContextType>(null!);

export function useAppData() {
  return React.useContext(AppDataContext);
}

export const AppDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  const indexedDb = useIndexedDB<Stores>(indexDbConfig(user?.uid ?? "default"));
  const settings = useSettingsData();
  const lesson = useLessonData();
  const decks = useDecksData(lesson);
  const searchQuery = useSearchQueryData();

  return (
    <AppDataContext.Provider
      value={{ decks, settings, indexedDb, searchQuery, lesson }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export function areDecksDefined(decks: tDeck[]) {
  return (
    decks.length !== 0 &&
    decks.find(
      (deck) =>
        deck.data === undefined ||
        deck.info === undefined ||
        deck.config === undefined
    ) === undefined
  );
}
