import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import useUserSignIn from "../../hooks/useUserSignIn";
import Container from "@mui/material/Container";
import Box, { BoxProps } from "@mui/material/Box";
import { Typography, alpha, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ConstructionIcon from "@mui/icons-material/Construction";
import PsychologyIcon from "@mui/icons-material/Psychology";
import WalletIcon from "@mui/icons-material/Wallet";
import { SectionHeader } from "./SectionHeader";
import { SimplePaperInfo } from "./SimplePaperInfo";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ExtensionIcon from "@mui/icons-material/Extension";
import { StackedItem, StackedSection } from "./StackedSection";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { CookieBanner } from "./CookieBanner";
import { Video } from "../../components/Video";
import { ChromeExtensionPageShort } from "../ChromeExtensionPage/ChromeExtensionPage";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import LingyLogo from "../../components/LingyLogo";

const items = [
  {
    icon: <ConstructionIcon />,
    header: "Personalized learning",
    body: `With a language learning app like Lingy, you can customize 
    your learning experience to focus on the areas where you need 
    the most help, and progress at your own pace.`,
  },
  {
    icon: <AccessibilityIcon />,
    header: "Accessibility",
    body: `Language learning app is available anytime, anywhere, and
    can be accessed on a variety of devices, making it easier to
    fit learning into your busy schedule.`,
  },
  {
    icon: <HistoryEduIcon />,
    header: "Efficiency",
    body: `Lingy language learning apps use innovative automation
    technology to streamline the learning process, helping you
    learn more efficiently and retain new vocabulary.`,
  },
  {
    icon: <WalletIcon />,
    header: "Affordable",
    body: `Compared to traditional language classes or private tutors, 
    language learning app is often more affordable and accessible 
    to a wider range of learners.`,
  },
];

type SectionProps = BoxProps & { color: string; size: number };

const Section = styled(Box)<SectionProps>(({ theme, color, size }) => ({
  position: "relative",
  width: "100%",

  color: theme.palette.text.primary,
  backgroundColor: color,
  paddingBottom: theme.spacing(2),

  "&::after": {
    content: '""',
    position: "absolute",
    zIndex: 10,
    bottom: `-${size}px`,
    left: "50%",
    transform: "translateX(-50%)",
    width: 0,
    height: 0,
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
    borderTop: `${size}px solid ${color}`,
  },
}));

export const LandingPage = () => {
  const signin = useUserSignIn();
  const theme = useTheme();
  const refOutlet = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const items2: StackedItem[] = [
    {
      icon: <MenuBookIcon />,
      header: "Kindle import",
      body: (
        <Typography variant="body2" textAlign="left">
          The easiest way revising vocabulary of a book you've read.
        </Typography>
      ),
      ref: <Box sx={{ height: "500px", backgroundColor: "red" }} />,
    },
    {
      icon: <ExtensionIcon />,
      header: "Chrome extension",
      body: (
        <React.Fragment>
          <Typography variant="body2" gutterBottom>
            Add a new vocabulary using our Chrome extension as you browse
            through the internet.
            {/* With our Chrome extension you can add new vocabulary as you browse
            through the internet. */}
          </Typography>
          <Button
            variant="text"
            color="info"
            size="small"
            onClick={() =>
              window.open(
                "https://chrome.google.com/webstore/category/extensions",
                "_blank",
                "noopener=yes"
              )
            }
          >
            Chrome Web Store&nbsp;&nbsp;&gt;
          </Button>
        </React.Fragment>
      ),
      ref: <ChromeExtensionPageShort />,
    },
    // {
    //   icon: <TextFieldsIcon />,
    //   header: "Character recognision",
    //   body: `Add the words to your vocabulary builder straight from a photo or a phone's screenshot`,
    //   ref: <Box sx={{ height: "500px", backgroundColor: "yellow" }} />,
    // },
    // {
    //   icon: <WalletIcon />,
    //   header: "Affordable",
    //   body: `Compared to traditional language classes or private tutors,
    //   language learning app is often more affordable and accessible
    //   to a wider range of learners.`,
    //   ref: <Box sx={{ height: "500px", backgroundColor: "green" }} />,
    // },
  ];

  return (
    <React.Fragment>
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          boxShadow: 1,
          maxHeight: "48px",
          background: alpha(theme.palette.background.paper, 0.7),
          // background: theme.palette.background.paper,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              minHeight: { xs: "48px" },
            }}
          >
            <LingyLogo />
          </Toolbar>
        </Container>
      </AppBar>
      <Section color={theme.palette.background.paper} size={20}>
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              py: "10rem",
            }}
          >
            <Box
              sx={{
                position: "relative",
                flexGrow: 1,
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.shape.borderRadius / 4,
                p: { xs: 2, sm: 4, md: 5 },
                mb: { xs: 4, md: 0 },
                mr: { xs: 0, md: 4 },
                // background: `linear-gradient(to right, transparent, ${theme.palette.background.default})`,
                "&::after": {
                  content: '""',
                  position: "absolute",
                  zIndex: 10,
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: 0,
                  height: 0,
                  borderTop: `20px solid transparent`,
                  borderBottom: `20px solid transparent`,
                  borderRight: `20px solid ${theme.palette.background.paper}`,
                },
                [theme.breakpoints.down("md")]: {
                  // background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
                  "&::after": {
                    right: "50%",
                    top: "calc(100% - 29px)",
                    transform: "rotate(90deg)",
                    borderRight: `20px solid ${theme.palette.background.paper}`,
                  },
                },
              }}
            >
              <Typography
                variant="h1"
                gutterBottom
                sx={{ fontFamily: "Plus Jakarta Sans", fontWeight: 800 }}
              >
                Learn faster with{" "}
                <span style={{ color: theme.palette.primary.main }}>Lingy</span>
                {/* Learn faster with Lingy
                <span style={{ color: theme.palette.primary.main }}>app</span> */}
                {/* </span>{" "}
                with <abbr title="help">ayuda</abbr> of Lingy */}
              </Typography>
              <Typography>
                Learn a foreign language with ease using Lingy's innovative
                technology that automates the creation of personalized
                flashcards and schedules their repetitions, making language
                learning efficient and effective. Sign in with your Google
                account and start a 7-days trial journey towards fluency.
                {/* Embrace the power of our Chrome Extension and embark on a
          language-learning adventure like never before. Explore the depths of
          the web with confidence, armed with the knowledge that every online
          encounter is an opportunity for growth and enrichment. */}
                {/* Elevate your language proficiency to new heights with Lingy's
              innovative automation technology that streamlines the creation of
              personalized flashcards from your input sources and scheduling the
              repetitions of your learning lessons. */}
                {/* Buba helps you improve your vocabulary in
              the most efficient way by automating creation of flash cards based
              on your input sources and scheduling their repetitions of your
              learning lessons. */}
              </Typography>
              <Button
                variant="contained"
                size="large"
                sx={{ mt: "1rem" }}
                onClick={(event) => {
                  event.preventDefault();
                  signin();
                }}
              >
                Sign in
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Video fileName="landing.mov" />
            </Box>
          </Box>
        </Container>
      </Section>

      <Section color={theme.palette.background.default} size={20}>
        <Container sx={{ py: 16 }}>
          <SectionHeader
            title="Why learn with Lingy.app?"
            sx={{ width: { xs: "70%", md: "50%" } }}
          >
            A{" "}
            <span style={{ color: theme.palette.primary.main }}>
              delightful
            </span>{" "}
            experience with a minimal workload on your part
          </SectionHeader>
          <Grid container spacing={3}>
            {items.map(({ icon, header, body }, idx) => {
              return (
                <Grid key={idx} item xs={12} sm={6} md={3}>
                  <SimplePaperInfo
                    icon={icon}
                    header={header}
                    sx={{ height: "100%" }}
                  >
                    {body}
                  </SimplePaperInfo>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Section>

      <Section color={theme.palette.background.paper} size={20}>
        <Container sx={{ py: 16 }}>
          <SectionHeader
            title="Features"
            sx={{ width: { xs: "70%", md: "50%" } }}
          >
            Make{" "}
            <span style={{ color: theme.palette.primary.main }}>the most</span>{" "}
            out of our product
          </SectionHeader>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius / 4,
              p: { xs: 2, sm: 4 },
            }}
          >
            <StackedSection
              items={items2}
              outletRef={refOutlet}
              sx={{
                width: { xs: "100%", md: "40%" },
                paddingRight: { xs: 0, md: 8 },
              }}
            />
            <Box
              ref={refOutlet}
              sx={{
                width: { xs: "100%", md: "60%" },
                p: { xs: "1rem 0rem", md: 2 },
              }}
            />
          </Box>
        </Container>
      </Section>

      <Box sx={{ backgroundColor: theme.palette.background.default }}>
        {/* <Divider /> */}
        <Container sx={{ pt: 16 }}>
          <Box>
            <SectionHeader
              title="Our approach to language learning"
              sx={{ width: { xs: "70%", md: "50%" } }}
            >
              Efficiency and{" "}
              <span style={{ color: theme.palette.primary.main }}>
                personalisation
              </span>{" "}
              lead to better retention
            </SectionHeader>{" "}
            {/* <SectionHeader title="Easy import of your Kindle's vocabulary">
              With{" "}
              <span style={{ color: theme.palette.primary.main }}>
                one click
              </span>{" "}
              of a button create flashcards from your favourite ebook
            </SectionHeader> */}
            <Typography sx={{ mb: 16 }}>
              At Lingy.app, we understand that language learning can be
              challenging and time-consuming. That's why we've developed an
              innovative approach that maximizes efficiency and personalization.
              Our automation technology allows learners to easily create custom
              flashcards that are relevant to them and our scheduling algorithm
              ensures that the repetitions are optimized for retention. By
              taking the hassle out of creating flashcards and tailoring the
              learning process to each learner's unique needs, we make language
              learning a more enjoyable and rewarding experience.
              {/* Lingy creates flashcards based on your input sources, allowing you
            to learn vocabulary that is relevant and meaningful to you. This
            personalized approach makes language learning more engaging and
            enjoyable */}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography variant="body2" sx={{ pt: 4, pb: 2 }}>
              Copyright © 2023 Lingy.app
            </Typography>
            <Box component="span">
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => {
                  navigate("/privacy");
                }}
                sx={{ mr: 1 }}
              >
                Privacy policy
              </Link>
              <Link
                variant="body2"
                underline="none"
                href="mailto:contact@lingy.app"
              >
                Contact us
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
      <CookieBanner />
    </React.Fragment>
  );
};

// import React, { useRef, useState } from "react";
// import Button from "@mui/material/Button";
// import useUserSignIn from "../../hooks/useUserSignIn";
// import Container from "@mui/material/Container";
// import Box, { BoxProps } from "@mui/material/Box";
// import { Typography, alpha, styled } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import Grid from "@mui/material/Grid";
// import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
// import AccessibilityIcon from "@mui/icons-material/Accessibility";
// import ConstructionIcon from "@mui/icons-material/Construction";
// import PsychologyIcon from "@mui/icons-material/Psychology";
// import WalletIcon from "@mui/icons-material/Wallet";
// import { SectionHeader } from "./SectionHeader";
// import { SimplePaperInfo } from "./SimplePaperInfo";
// import MenuBookIcon from "@mui/icons-material/MenuBook";
// import ExtensionIcon from "@mui/icons-material/Extension";
// import { StackedItem, StackedSection } from "./StackedSection";
// import TextFieldsIcon from "@mui/icons-material/TextFields";
// import Divider from "@mui/material/Divider";
// import Link from "@mui/material/Link";
// import { useNavigate } from "react-router-dom";
// import { CookieBanner } from "./CookieBanner";
// import { Video } from "../../components/Video";
// import { ChromeExtensionPageShort } from "../ChromeExtensionPage/ChromeExtensionPage";
// import Toolbar from "@mui/material/Toolbar";
// import AppBar from "@mui/material/AppBar";
// import LingyLogo from "../../components/LingyLogo";

// const items = [
//   {
//     icon: <ConstructionIcon />,
//     header: "Personalized learning",
//     body: `With a language learning app like Lingy, you can customize
//     your learning experience to focus on the areas where you need
//     the most help, and progress at your own pace.`,
//   },
//   {
//     icon: <AccessibilityIcon />,
//     header: "Accessibility",
//     body: `Language learning app is available anytime, anywhere, and
//     can be accessed on a variety of devices, making it easier to
//     fit learning into your busy schedule.`,
//   },
//   {
//     icon: <HistoryEduIcon />,
//     header: "Efficiency",
//     body: `Lingy language learning apps use innovative automation
//     technology to streamline the learning process, helping you
//     learn more efficiently and retain new vocabulary.`,
//   },
//   {
//     icon: <WalletIcon />,
//     header: "Affordable",
//     body: `Compared to traditional language classes or private tutors,
//     language learning app is often more affordable and accessible
//     to a wider range of learners.`,
//   },
// ];

// type SectionProps = BoxProps & { color: string; size: number };

// const Section = styled(Box)<SectionProps>(({ theme, color, size }) => ({
//   position: "relative",
//   width: "100%",

//   color: theme.palette.text.primary,
//   backgroundColor: color,
//   paddingBottom: theme.spacing(2),

//   "&::after": {
//     content: '""',
//     position: "absolute",
//     zIndex: 10,
//     bottom: `-${size}px`,
//     left: "50%",
//     transform: "translateX(-50%)",
//     width: 0,
//     height: 0,
//     borderLeft: `${size}px solid transparent`,
//     borderRight: `${size}px solid transparent`,
//     borderTop: `${size}px solid ${color}`,
//   },
// }));

// export const LandingPage = () => {
//   const signin = useUserSignIn();
//   const theme = useTheme();
//   const refOutlet = useRef<HTMLDivElement>(null);
//   const navigate = useNavigate();

//   const items2: StackedItem[] = [
//     {
//       icon: <MenuBookIcon />,
//       header: "Kindle import",
//       body: `The easiest way revising vocabulary of a book you've read.`,
//       ref: <Box sx={{ height: "500px", backgroundColor: "red" }} />,
//     },
//     {
//       icon: <ExtensionIcon />,
//       header: "Chrome extension",
//       body: (
//         <React.Fragment>
//           <Typography variant="body2" gutterBottom>
//             Add a new vocabulary using our Chrome extension as you browse
//             through the internet.
//             {/* With our Chrome extension you can add new vocabulary as you browse
//             through the internet. */}
//           </Typography>
//           <Button
//             variant="text"
//             color="info"
//             size="small"
//             onClick={() =>
//               window.open(
//                 "https://chrome.google.com/webstore/category/extensions",
//                 "_blank",
//                 "noopener=yes"
//               )
//             }
//           >
//             Chrome Web Store&nbsp;&nbsp;&gt;
//           </Button>
//         </React.Fragment>
//       ),
//       ref: <ChromeExtensionPageShort />,
//     },
//     // {
//     //   icon: <TextFieldsIcon />,
//     //   header: "Character recognision",
//     //   body: `Add the words to your vocabulary builder straight from a photo or a phone's screenshot`,
//     //   ref: <Box sx={{ height: "500px", backgroundColor: "yellow" }} />,
//     // },
//     // {
//     //   icon: <WalletIcon />,
//     //   header: "Affordable",
//     //   body: `Compared to traditional language classes or private tutors,
//     //   language learning app is often more affordable and accessible
//     //   to a wider range of learners.`,
//     //   ref: <Box sx={{ height: "500px", backgroundColor: "green" }} />,
//     // },
//   ];

//   return (
//     <React.Fragment>
//       <AppBar
//         component="nav"
//         position="fixed"
//         sx={{
//           boxShadow: 1,
//           maxHeight: "48px",
//           background: alpha(theme.palette.background.paper, 0.7),
//           // background: theme.palette.background.paper,
//         }}
//       >
//         <Container maxWidth="xl">
//           <Toolbar
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "left",
//               minHeight: { xs: "48px" },
//             }}
//           >
//             <LingyLogo />
//           </Toolbar>
//         </Container>
//       </AppBar>
//       <Section color={theme.palette.background.paper} size={20}>
//         <Container>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: { xs: "column" },
//               alignItems: "center",
//               py: "10rem",
//             }}
//           >
//             <Box
//               sx={{
//                 width: "80%",
//                 position: "relative",
//                 flexGrow: 1,
//                 backgroundColor: theme.palette.background.default,
//                 borderRadius: theme.shape.borderRadius / 4,
//                 p: 6,
//                 mb: { xs: 4, md: 8 },
//                 mr: { xs: 0, md: 4 },
//                 "&::after": {
//                   content: '""',
//                   position: "absolute",
//                   zIndex: 10,
//                   width: 0,
//                   height: 0,
//                   borderTop: `20px solid transparent`,
//                   borderBottom: `20px solid transparent`,
//                   borderLeft: `20px solid ${theme.palette.background.default}`,
//                   right: "50%",
//                   top: "calc(100% - 10px)",
//                   transform: "rotate(90deg)",
//                 },
//               }}
//             >
//               <Typography
//                 variant="h1"
//                 gutterBottom
//                 sx={{ fontFamily: "Plus Jakarta Sans", fontWeight: 800 }}
//               >
//                 Learn faster with{" "}
//                 <span style={{ color: theme.palette.primary.main }}>Lingy</span>
//                 {/* Learn faster with Lingy
//                 <span style={{ color: theme.palette.primary.main }}>app</span> */}
//                 {/* </span>{" "}
//                 with <abbr title="help">ayuda</abbr> of Lingy */}
//               </Typography>
//               <Typography>
//                 Learn a foreign language with ease using Lingy's innovative
//                 technology that automates the creation of personalized
//                 flashcards and schedules their repetitions, making language
//                 learning efficient and effective. Sign in with your Google
//                 account and start a 7-days trial journey towards fluency.
//                 {/* Embrace the power of our Chrome Extension and embark on a
//           language-learning adventure like never before. Explore the depths of
//           the web with confidence, armed with the knowledge that every online
//           encounter is an opportunity for growth and enrichment. */}
//                 {/* Elevate your language proficiency to new heights with Lingy's
//               innovative automation technology that streamlines the creation of
//               personalized flashcards from your input sources and scheduling the
//               repetitions of your learning lessons. */}
//                 {/* Buba helps you improve your vocabulary in
//               the most efficient way by automating creation of flash cards based
//               on your input sources and scheduling their repetitions of your
//               learning lessons. */}
//               </Typography>
//               <Button
//                 variant="contained"
//                 size="large"
//                 sx={{ mt: "1rem" }}
//                 onClick={(event) => {
//                   event.preventDefault();
//                   signin();
//                 }}
//               >
//                 Sign in
//               </Button>
//             </Box>
//             <Box sx={{ flexGrow: 1 }}>
//               <Video fileName="landing.mov" sx={{ width: "100%" }} />
//             </Box>
//           </Box>
//         </Container>
//       </Section>

//       <Section color={theme.palette.background.default} size={20}>
//         <Container sx={{ py: 16 }}>
//           <SectionHeader
//             title="Why learn with Lingy.app?"
//             sx={{ width: { xs: "70%", md: "50%" } }}
//           >
//             A{" "}
//             <span style={{ color: theme.palette.primary.main }}>
//               delightful
//             </span>{" "}
//             experience with a minimal workload on your part
//           </SectionHeader>
//           <Grid container spacing={3}>
//             {items.map(({ icon, header, body }, idx) => {
//               return (
//                 <Grid key={idx} item xs={12} sm={6} md={3}>
//                   <SimplePaperInfo
//                     icon={icon}
//                     header={header}
//                     sx={{ height: "100%" }}
//                   >
//                     {body}
//                   </SimplePaperInfo>
//                 </Grid>
//               );
//             })}
//           </Grid>
//         </Container>
//       </Section>

//       <Section color={theme.palette.background.paper} size={20}>
//         <Container sx={{ py: 16 }}>
//           <SectionHeader
//             title="Features"
//             sx={{ width: { xs: "70%", md: "50%" } }}
//           >
//             Make{" "}
//             <span style={{ color: theme.palette.primary.main }}>the most</span>{" "}
//             out of our product
//           </SectionHeader>
//           <Box
//             sx={{
//               display: "flex",
//               flexWrap: "wrap",
//               backgroundColor: theme.palette.background.default,
//               borderRadius: theme.shape.borderRadius / 4,
//               p: 4,
//             }}
//           >
//             <StackedSection
//               items={items2}
//               outletRef={refOutlet}
//               sx={{
//                 width: { xs: "100%", md: "40%" },
//                 paddingRight: { xs: 0, md: 8 },
//               }}
//             />
//             <Box
//               ref={refOutlet}
//               sx={{ width: { xs: "100%", md: "60%" }, p: 2 }}
//             />
//           </Box>
//         </Container>
//       </Section>

//       <Box sx={{ backgroundColor: theme.palette.background.default }}>
//         {/* <Divider /> */}
//         <Container sx={{ pt: 16 }}>
//           <Box>
//             <SectionHeader
//               title="Our approach to language learning"
//               sx={{ width: { xs: "70%", md: "50%" } }}
//             >
//               Efficiency and{" "}
//               <span style={{ color: theme.palette.primary.main }}>
//                 personalisation
//               </span>{" "}
//               lead to better retention
//             </SectionHeader>{" "}
//             {/* <SectionHeader title="Easy import of your Kindle's vocabulary">
//               With{" "}
//               <span style={{ color: theme.palette.primary.main }}>
//                 one click
//               </span>{" "}
//               of a button create flashcards from your favourite ebook
//             </SectionHeader> */}
//             <Typography sx={{ mb: 16 }}>
//               At Lingy.app, we understand that language learning can be
//               challenging and time-consuming. That's why we've developed an
//               innovative approach that maximizes efficiency and personalization.
//               Our automation technology allows learners to easily create custom
//               flashcards that are relevant to them and our scheduling algorithm
//               ensures that the repetitions are optimized for retention. By
//               taking the hassle out of creating flashcards and tailoring the
//               learning process to each learner's unique needs, we make language
//               learning a more enjoyable and rewarding experience.
//               {/* Lingy creates flashcards based on your input sources, allowing you
//             to learn vocabulary that is relevant and meaningful to you. This
//             personalized approach makes language learning more engaging and
//             enjoyable */}
//             </Typography>
//           </Box>
//           <Divider />
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "baseline",
//             }}
//           >
//             <Typography variant="body2" sx={{ pt: 4, pb: 2 }}>
//               Copyright © 2023 Lingy.app
//             </Typography>
//             <Box component="span">
//               <Link
//                 component="button"
//                 variant="body2"
//                 underline="none"
//                 onClick={() => {
//                   navigate("/privacy");
//                 }}
//                 sx={{ mr: 1 }}
//               >
//                 Privacy policy
//               </Link>
//               <Link
//                 variant="body2"
//                 underline="none"
//                 href="mailto:contact@lingy.app"
//               >
//                 Contact us
//               </Link>
//             </Box>
//           </Box>
//         </Container>
//       </Box>
//       <CookieBanner />
//     </React.Fragment>
//   );
// };
