import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";

interface ConfigCogProps {
  show: boolean;
  onClick: () => void;
}

export const ConfigCog: React.FC<ConfigCogProps> = ({ show, onClick }) => {
  return (
    <IconButton size="large" onClick={onClick} sx={{ zIndex: 15 }}>
      <SettingsIcon
        sx={{
          transform: show ? "rotate(360deg)" : "rotate(0deg)",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      />
    </IconButton>
  );
};
