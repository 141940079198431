import { tDeck } from "./types";

export function sortDecks(a: tDeck, b: tDeck) {
  const aCount = getDeckNewCount(a) + getDeckReviewCount(a);
  const bCount = getDeckNewCount(b) + getDeckReviewCount(b);
  if (aCount === bCount) return a.info.name < b.info.name ? 1 : -1;
  return aCount < bCount ? -1 : 1;
}

export function getDeckNewCount(deck: tDeck) {
  const newDaily = Math.min(
    deck.data.newCards.length + deck.data.newAnsweredToday,
    deck.config.dailyNewCards
  );
  return Math.max(0, newDaily - deck.data.newAnsweredToday);
}

export function getDeckReviewCount(deck: tDeck) {
  const reviewDaily = Math.min(
    deck.data.reviewCards.length + deck.data.reviewAnsweredToday,
    deck.config.dailyReviewCards
  );
  return Math.max(0, reviewDaily - deck.data.reviewAnsweredToday);
}
