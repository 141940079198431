import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { useAuth } from "../contexts/AuthContext";
import { useAlert } from "../contexts/Alert/AlertContext";

const useUserSignIn = () => {
  const navigate = useNavigate();
  const { signinGoogle } = useAuth();
  const { showAlert } = useAlert();

  const signin = async () => {
    try {
      const user = await signinGoogle();
      showAlert(`${user!.displayName} signed in`, "success");
      navigate("/decks");
    } catch (error) {
      if (error instanceof FirebaseError) {
        showAlert(error.message, "error");
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.customData?.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
      }
    }
  };

  return signin;
};

export default useUserSignIn;
