import { useRef, useState } from "react";
import { AudioPlay, playAudio, useFetchAudioTTS } from "../../common/audio";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { CardAudioCache } from "./FlashCard";
import { useAlert } from "../../contexts/Alert/AlertContext";

type CardAudioPlay = (
  tts: {
    text: string;
    lang: string;
  },
  cardId: string,
  field: {
    name: "word" | "examples" | "synonyms";
    idx: number;
  }
) => Promise<void>;

type CardAudioFetch = CardAudioPlay;

type CardAudioStop = () => void;

export enum CardAudioState {
  Fetching,
  Playing,
  Stopped,
}

export function useCardAudio(): {
  fetch: CardAudioFetch;
  play: CardAudioPlay;
  stop: CardAudioStop;
  state: CardAudioState;
} {
  const { indexedDb } = useAppData();
  const { showAlert } = useAlert();
  const fetchAudioTts = useFetchAudioTTS();
  const audio = useRef<AudioPlay>();
  const data = useRef<{ text: string; buf?: ArrayBufferLike }>();
  const [state, setState] = useState<CardAudioState>(CardAudioState.Stopped);

  const fetch: CardAudioPlay = async (
    tts: { text: string; lang: string },
    cardId: string,
    field: { name: "word" | "examples" | "synonyms"; idx: number }
  ) => {
    // Don't execute fetching if already in a process of doing it or the current audio data for a given .text has already been fetched (from indexedDb or remote) and is stored in the memory .buf
    if (state === CardAudioState.Fetching || data.current?.text === tts.text)
      return;

    data.current = { text: tts.text };
    setState(CardAudioState.Fetching);

    const found = indexedDb.getData<CardAudioCache>("audio", [cardId]);
    if (found && found[0][field.name]) {
      const cachedBuf = found[0][field.name]!.find((v) => v.idx === field.idx);
      if (cachedBuf) {
        // console.log(`${field.name} audio found in indexedDb`);
        data.current.buf = cachedBuf.data;
      }
    }

    // If audio variable still isn't set then what that means is there
    // was no cached version in the IndexedDb and that we need to fetch
    // it throught text-to-speech remote call
    if (!data.current.buf) {
      data.current.buf = await fetchAudioTts(tts.text, tts.lang);

      await indexedDb.addData(
        "audio",
        {
          id: cardId,
          key: {
            [field.name]: [{ idx: field.idx, data: data.current.buf }],
          },
        },
        true
      );
    }

    setState(CardAudioState.Stopped);
  };

  const play: CardAudioPlay = async (
    tts: { text: string; lang: string },
    cardId: string,
    field: { name: "word" | "examples" | "synonyms"; idx: number }
  ) => {
    try {
      if (state === CardAudioState.Fetching) return;

      if (audio.current) {
        stop();
        return;
      }

      await fetch(tts, cardId, field);

      if (!data.current?.buf) return;

      setState(CardAudioState.Playing);
      audio.current = playAudio(data.current.buf, true);
      await audio.current.playing;
      audio.current = undefined;
      setState(CardAudioState.Stopped);
    } catch (error) {
      showAlert("Loading an audio failed", "error");
      audio.current = undefined;
      data.current = undefined;
      setState(CardAudioState.Stopped);
    }
  };

  const stop: CardAudioStop = () => {
    audio.current?.source.stop();
    audio.current = undefined;
    setState(CardAudioState.Stopped);
  };

  return { fetch, play, stop, state };
}
