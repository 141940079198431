import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SelectableButton } from "./SelectableButton";
import { useHorizontalContainerSwiper } from "./useHorizontalContainerSwiper";
import { SxProps, useTheme } from "@mui/material/styles";
import ReactDOM from "react-dom";

export type StackedItem = {
  icon: JSX.Element;
  header: string;
  body: string | JSX.Element;
  ref: JSX.Element;
};

interface StackedSectionProps {
  items: StackedItem[];
  outletRef?: React.RefObject<HTMLDivElement>;
  options?: {
    itemWidth?: number;
  };
  sx?: SxProps;
}

export const StackedSection: React.FC<StackedSectionProps> = ({
  items,
  outletRef,
  options,
  sx,
}) => {
  const theme = useTheme();

  const itemWidth = options?.itemWidth ?? 350;

  const marginX = 1;
  const pxMargin = parseFloat(theme.spacing(marginX));

  const itemWidthWithMargins = itemWidth + pxMargin * 2;

  const [outletContent, setOutletContent] = useState<React.ReactPortal | null>(
    null
  );

  const { handlers, idxSelectedItem, setIdxSelectedItem, swipePosition } =
    useHorizontalContainerSwiper({
      itemsCount: items.length,
      itemWidth: itemWidthWithMargins,
      xOffset: pxMargin,
    });

  useEffect(() => {
    if (outletRef?.current) {
      const content = ReactDOM.createPortal(
        items.at(idxSelectedItem)!.ref,
        outletRef.current
      );
      setOutletContent(content);
    }

    // c_log("xxxxxx", outletRef?.current);
    // // const c = React.cloneElement(items.at(idxSelectedItem)!.ref);
    // const comp = (<div>New component</div>) as React.ReactNode;
    // outletRef!.current!.replaceChildren(comp);
  }, [idxSelectedItem]);

  return (
    <React.Fragment>
      <Stack
        {...handlers}
        direction={{ xs: "row", md: "column" }}
        sx={{
          width: "100%",
          overflowX: { xs: "hidden", md: undefined },
          ...sx,
        }}
      >
        {items.map(({ icon, header, body }, idx) => {
          return (
            <SelectableButton
              key={idx}
              icon={icon}
              header={header}
              selected={idx === idxSelectedItem}
              onClick={() => setIdxSelectedItem(idx)}
              sx={{
                flexShrink: 0,
                width: { xs: `${itemWidth}px`, md: "100%" },
                mx: { xs: marginX, md: 0 },
                my: 0.5,
                transform: {
                  xs: `translateX(${swipePosition}px)`,
                  md: "none",
                },
                transition: {
                  xs: "transform 0.3s ease, background-color 0.3s ease, border 0.3s ease",
                  md: "background-color 0.3s ease, border 0.3s ease",
                },
                // position: swipeDelta !== 0 ? "relative" : "static",
              }}
            >
              {body}
            </SelectableButton>
          );
        })}
      </Stack>
      {outletContent}
    </React.Fragment>
  );
};
