import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper, { PaperProps } from "@mui/material/Paper";
import { SxProps } from "@mui/material";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";

type SimplePaperInfoProps = {
  icon: JSX.Element;
  header: string;
  children?: React.ReactNode;
} & (PaperProps & SxProps);

export const SimplePaperInfo: React.FC<SimplePaperInfoProps> = ({
  icon,
  header,
  children,
  sx,
  ...rest
}) => {
  const { theme } = useCustomTheme();

  return (
    <Paper variant="outlined" sx={sx} {...rest}>
      <Box p={3}>
        <Box display="flex" alignItems="center" paddingBottom="0.5rem">
          {React.cloneElement(icon, {
            sx: { color: theme.palette.primary.main },
            fontSize: "small",
          })}
          <Typography variant="h3" marginLeft="10px">
            {header}
          </Typography>
        </Box>
        <Typography variant="body2">{children}</Typography>
      </Box>
    </Paper>
  );
};
