import { useRef, useState } from "react";
import { useKeyDown } from "../../hooks/useKeyDown";

export function useKeyNavigation(
  itemsLength: number,
  args: { onClose: () => void; onEnter: () => void }
) {
  const { onClose, onEnter } = args;
  const [focusIdx, setFocusIdx] = useState(-1);
  const itemRefs = useRef<HTMLDivElement[]>([]);

  const scrollToItem = (index: number) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center", // or 'start' or 'end'
      });
    }
  };

  useKeyDown((event: KeyboardEvent) => {
    if (event.key === "Escape") onClose();

    if (event.key === "ArrowUp") {
      const newIdx = focusIdx <= 0 ? itemsLength - 1 : focusIdx - 1;
      setFocusIdx(newIdx);
      scrollToItem(newIdx);
    }
    if (event.key === "ArrowDown") {
      const newIdx = focusIdx >= itemsLength - 1 ? 0 : focusIdx + 1;
      setFocusIdx(newIdx);
      scrollToItem(newIdx);
    }
    if (event.key === "Enter") onEnter();
  });

  return { focusIdx, itemRefs };
}
