import React from "react";
import { Theme } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { WithTranslation, withTranslation } from "react-i18next";
import { DayInMillis } from "../../shared/misc";

interface CardsMaturityBarProps extends WithTranslation {
  theme: Theme;
  height: number;
  stats: Map<number, [string, number][]>; // <date, [cardId, diff]>
}

interface CardsMaturityBarState {
  options?: ApexCharts.ApexOptions;
  series?: ApexAxisChartSeries; // | ApexNonAxisChartSeries;
}

class CardsMaturityBar extends React.Component<
  CardsMaturityBarProps,
  CardsMaturityBarState
> {
  constructor(props: CardsMaturityBarProps) {
    super(props);

    this.state = {
      series: this.generateSeries(),
      options: this.generateOptions(),
    };
  }

  componentDidUpdate(prevProps: CardsMaturityBarProps) {
    const themeChanged = prevProps.theme !== this.props.theme;
    const statsChanged = prevProps.stats !== this.props.stats;

    if (!themeChanged && !statsChanged) return;

    this.setState((prev) => ({
      ...prev,
      ...(themeChanged || statsChanged
        ? { options: this.generateOptions() }
        : {}),
      ...(statsChanged ? { series: this.generateSeries() } : {}),
    }));
  }

  getStatsDataFor(condition: (diff: number) => boolean) {
    return Array.from(this.props.stats.values()).map(
      (v) => v.filter(([cardId, diff]) => condition(diff)).length
    );
  }

  generateSeries() {
    const { t } = this.props;

    const threeWeeks = DayInMillis * 7 * 3;
    const threeMonths = DayInMillis * 30 * 3;

    const n = (diff: number) => diff <= threeWeeks;
    const y = (diff: number) => diff > threeWeeks && diff <= threeMonths;
    const m = (diff: number) => diff > threeMonths;

    return [
      { name: t("stats_page.new"), data: this.getStatsDataFor(n) },
      { name: t("stats_page.young"), data: this.getStatsDataFor(y) },
      { name: t("stats_page.mature"), data: this.getStatsDataFor(m) },
    ];
  }

  generateOptions(): ApexCharts.ApexOptions {
    return {
      chart: {
        height: this.props.height,
        stacked: true,
        toolbar: { show: false },
        zoom: { enabled: false },
        fontFamily: this.props.theme.typography.fontFamily,
        foreColor: this.props.theme.palette.text.primary,
      },
      colors: [
        this.props.theme.palette.secondary.light,
        this.props.theme.palette.secondary.main,
        this.props.theme.palette.secondary.dark,
      ],
      dataLabels: { enabled: true },
      grid: {
        borderColor: this.props.theme.palette.divider,
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: { size: 1 },
      xaxis: {
        type: "datetime",
        categories: Array.from(this.props.stats.keys()).map((v) => {
          return new Date(v).toLocaleDateString("en-US") + " GMT";
        }),
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        //   "01/07/2011 GMT",
        // ],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 5,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: this.props.theme.shape.borderRadius,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                color: this.props.theme.palette.text.primary,
              },
            },
          },
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: { opacity: 1 },
    };
  }

  render() {
    return (
      <div id="maturity-chart" style={{ width: "100%" }}>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={this.props.height}
        />
      </div>
    );
  }
}

export default withTranslation()(CardsMaturityBar);
