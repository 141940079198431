import { useRef } from "react";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import useCloudFn from "../../hooks/useCloudFn";
import { tCard, tFetchCardsReq, tFetchCardsRes } from "../../shared/types";
import { c_log } from "../../shared/console";

export type IndexDbCard = tCard & { deckId: string };

export type FetchPromise = (cardsIds: string[]) => Promise<void> | null;
export type CancelPromises = () => void;

function useFetchCards(deckId: string): [FetchPromise, CancelPromises] {
  const { lesson, indexedDb } = useAppData();
  const callFetchCards = useCloudFn<tFetchCardsReq, tFetchCardsRes>(
    "fetchcards"
  );
  const cancelRef = useRef(false);

  // Returns null if there was no work to be done or if call asked cards (cardsIds)
  // were in the local storage.
  // Returns a promise to the background job which purpose is to fetch asked cards
  // from the server's database
  function fetchCards(cardsIds: string[]) {
    if (cardsIds.length === 0) return null;

    const cardsInLocalDb = indexedDb.getData<tCard>("cards", cardsIds) ?? [];
    lesson.pushCards(cardsInLocalDb);

    const cardsNotInDb = indexedDb.excludes("cards", cardsIds) as string[];
    if (cardsNotInDb.length === 0) return null;

    // Asynchronously fetch the cards that are not in a local storage but
    // are listed in current lesson session. Upon receiving add them to
    // the local storage and to the current lesson
    const fetchFromRemote = () => {
      cancelRef.current = false;
      let interval: NodeJS.Timer;

      const promise = new Promise<void>(async (resolve, reject) => {
        try {
          interval = setInterval(() => {
            if (cancelRef.current) {
              clearInterval(interval);
              reject("Promise cancelled");
            }
          }, 500);

          const { data } = await callFetchCards({
            data: { ids: cardsNotInDb, deckid: deckId },
          });

          clearInterval(interval);

          if (lesson.data.fetchPromises.some((v) => v === promise)) {
            // Caching received cards for future use during a subsequent lessons
            indexedDb.addBulkData(
              "cards",
              data.map((card) => ({
                id: card.id,
                key: { ...card, deckId } as IndexDbCard,
              }))
            );

            if (!cancelRef.current) lesson.pushCards(data);
          }
          lesson.data.fetchPromises = lesson.data.fetchPromises.filter(
            (v) => v !== promise
          );

          c_log("End of promise:", promise);

          resolve();
        } catch (err) {
          clearInterval(interval);
          lesson.data.fetchPromises = lesson.data.fetchPromises.filter(
            (v) => v !== promise
          );
          reject(err);
        }
      });

      lesson.data.fetchPromises.push(promise);
      c_log("Adding promise:", promise);

      return promise;
    };

    return fetchFromRemote();
  }

  const cancel = () => {
    cancelRef.current = true;
  };

  return [fetchCards, cancel];
}

export default useFetchCards;
