import TextField, { TextFieldProps } from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { ChangeEvent, FocusEvent, FC, useState, useEffect } from "react";
import { InputValidator } from "./useFormValidator";

type TextFieldRegExProps = {
  regex: RegExp;
  onChange: (value: string) => void;
  validator: InputValidator;
} & (TextFieldProps & SxProps);

const TextFieldRegEx: FC<TextFieldRegExProps> = ({
  regex,
  onChange,
  validator,
  sx,
  ...other
}) => {
  const [value, setValue] = useState<string>(other.defaultValue as string);
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    validator.set(other.id!, false);
    valueValidation(value);
  }, []);

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(event.currentTarget.value);
    onChange(event.currentTarget.value);
    valueValidation(event.currentTarget.value);
  };

  const handleFocus = (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => {
    valueValidation(value);
  };

  const valueValidation = (value: string | undefined) => {
    if (!value || value.length === 0) {
      setError("Field required");
      validator.set(other.id!, false);
    } else {
      const isValueValid = regex.test(value);
      setError(!isValueValid ? "Invalid format" : null);
      validator.set(other.id!, isValueValid);
    }
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      error={!!error}
      helperText={error}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      sx={{ "& .MuiOutlinedInput-input": { height: "1rem" }, ...sx }}
      {...other}
    />
  );
};

export default TextFieldRegEx;
