import { FC } from "react";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { tDeckCreateReq } from "../../shared/types";
import { useAuth } from "../../contexts/AuthContext";
import { c_err } from "../../shared/console";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../contexts/Alert/AlertContext";
import { FirebaseError } from "firebase/app";
import { lighten } from "@mui/material/styles";

interface ConvertButtonProps {
  selection: tDeckCreateReq;
  lang: string;
  onStateChange: (processing: boolean) => void;
}

export const ConvertButton: FC<ConvertButtonProps> = ({
  selection,
  lang,
  onStateChange,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "import_ebook_reader_page",
  });
  const { callCloudFn } = useAuth();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  const convertIntoDecks = async () => {
    try {
      setProcessing(true);
      onStateChange(true);
      const res = await callCloudFn<tDeckCreateReq>(
        "deckcreate",
        selection.map((v) => ({ ...v, lang }))
      );
      navigate("/decks");
    } catch (err) {
      onStateChange(false);
      setProcessing(false);
      c_err(err); // TODO!: Present failure alert to the user
      if (err instanceof FirebaseError) showAlert(err.message, "error");
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      disabled={selection.length === 0 || processing}
      sx={{ ml: 2, px: 3.5 }}
      onClick={convertIntoDecks}
    >
      <Badge
        badgeContent={processing ? 0 : selection.length}
        sx={{
          position: "absolute",
          right: "1rem",
          bottom: "0.9rem",
          "& .MuiBadge-badge": {
            color: ({ palette }) => palette.primary.contrastText,
            backgroundColor: ({ palette }) =>
              palette.mode === "light" ? palette.error.main : palette.info.main,
          },
        }}
      />
      {t(processing ? "processing" : "convert")}
    </Button>
  );
};
