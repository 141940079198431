import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useAlert } from "../contexts/Alert/AlertContext";
import { tOcrReq, tOcrRes } from "../shared/types";
import { fileToBase64, FileType } from "../common/file";
import { c_err, c_log } from "../shared/console";

function prepareText(text: string) {
  const paragraphs = text.split("\n\n");
  const paragraphWords = paragraphs.map((p) => p.split(/ |\n/));
  return paragraphWords;
}

// TODO: Instead of sending file as a string, maybe it's a better idea to
//       upload it to storage first and then server side validate it
// https://stackoverflow.com/questions/51960789/how-can-firebase-functions-httpscallable-submit-files-to-functions-https-on
const useParseImage = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { callCloudFn } = useAuth();

  const parse = async (selectedFiles: File | File[]) => {
    const files = selectedFiles as File[];
    const asyncWorkload = files.map((file) => {
      // TODO: async in Promise? is it an anti-pattern?
      return new Promise<tOcrRes>(async (resolve, reject) => {
        try {
          const blob64 = await fileToBase64(file, FileType.Image);

          // TODO: Maybe size down the image before sending it to cloud function
          //       in order to lower the data transer
          const res = await callCloudFn<tOcrReq, tOcrRes>("ocr", {
            filename: file.name,
            blob: blob64,
          });
          resolve(res.data);
        } catch (error) {
          c_err(error);
          reject(error);
          // reject(`Failed processing of ${file.name} image`);
        }
      });
    });

    const ocrTextsFromFiles = await Promise.all(asyncWorkload);
    const parsedTexts = ocrTextsFromFiles.map((text) => prepareText(text)); //[file][paragraph][word]

    c_log("Parsed texts", parsedTexts);
    navigate("/importimage", {
      state: { text: parsedTexts },
    });
  };

  return parse;
};

export default useParseImage;
