import React, { ReactNode } from "react";
import { AlertPopup, ShowAlert, useAlertPopup } from "./AlertPopup";
import { DialogPopup, ShowDialog, useDialogPopup } from "./DialogPopup";

interface AlertContextType {
  showAlert: ShowAlert;
  showDialog: ShowDialog;
}

const AlertContext = React.createContext<AlertContextType>(null!);

export function useAlert() {
  return React.useContext(AlertContext);
}

export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const alert = useAlertPopup();
  const dialog = useDialogPopup();

  return (
    <AlertContext.Provider
      value={{
        showAlert: alert.show,
        showDialog: dialog.show,
      }}
    >
      {children}
      <AlertPopup use={alert} />
      <DialogPopup use={dialog} />
    </AlertContext.Provider>
  );
};
