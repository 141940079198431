import { useEffect, useRef } from "react";
import { c_log } from "../shared/console";
import { mergeObjects } from "../shared/misc";

export type DBConfig<StoreName extends string> = {
  name: string;
  version?: number;
  stores: {
    name: StoreName;
    options?: IDBObjectStoreParameters;
    index?: {
      name: string;
      key: string;
      options?: IDBIndexParameters;
    }[];
  }[];
};

type DBObjectId = number | string;

type DBObject = {
  key: Record<string, any>; // [key: string]: any;
  id: DBObjectId;
};

export type UseIndexedDBResult<StoreName extends string> = {
  addData: (
    storeName: StoreName,
    data: DBObject,
    merge?: boolean
  ) => Promise<IDBValidKey>;
  addBulkData: (storeName: StoreName, data: DBObject[]) => Promise<void>;
  deleteBulkData: (storeName: StoreName, ids: DBObjectId[]) => Promise<void>;
  getData: <T = unknown>(
    storeName: StoreName,
    ids?: DBObjectId[]
  ) => T[] | undefined;
  excludes: (storeName: StoreName, ids: DBObjectId[]) => DBObjectId[];
  clearData: () => Promise<void>;
};

type StoreData = {
  idx: number; //  store idx
  data: DBObject[];
};

const useIndexedDB = <StoreName extends string>(
  dbConfig: DBConfig<StoreName>,
  initialData: StoreData[] = []
): UseIndexedDBResult<StoreName> => {
  const data = useRef<StoreData[]>(initialData);

  const connect = async (
    storeIdx: number,
    mode: IDBTransactionMode = "readonly"
  ) => {
    const db = await openDB(dbConfig);
    const tx = db.transaction(dbConfig.stores[storeIdx].name, mode);
    const store = tx.objectStore(dbConfig.stores[storeIdx].name);
    return { store, tx };
  };

  const addData = async (
    storeName: StoreName,
    obj: DBObject,
    merge?: boolean
  ) => {
    const storeIdx = dbConfig.stores.findIndex((v) => v.name === storeName);
    const { store } = await connect(storeIdx, "readwrite");
    return new Promise<IDBValidKey>((resolve, reject) => {
      let mergeResultObj: DBObject | undefined;
      if (merge) {
        const data = getDataFromState(storeName, [obj.id]);
        c_log("Cached data before merging:", data);
        if (data) mergeResultObj = mergeObjects(data[0], obj);
        c_log("Data after merging:", mergeResultObj);
      }

      const req = mergeResultObj ? store.put(mergeResultObj) : store.add(obj);

      req.onerror = () =>
        reject(new Error(`Error adding data to ${dbConfig.name} database`));

      req.onsuccess = () => {
        const store = data.current.find((store) => store.idx === storeIdx)!;
        const n = store.data.filter((v) => v.id !== req.result.toString());
        store.data = [
          ...n,
          { ...(mergeResultObj ?? obj), id: req.result.toString() },
        ];

        // store.data.push({ ...obj, id: req.result.toString() });
        // TODO: state [data, setData] unaware of a change, useImmer?

        c_log(`New object added to ${dbConfig.name} database`, obj.id);
        resolve(req.result);
      };
    });
  };

  const addBulkData = async (storeName: StoreName, objects: DBObject[]) => {
    const storeIdx = dbConfig.stores.findIndex((v) => v.name === storeName);
    const { store, tx } = await connect(storeIdx, "readwrite");

    return new Promise<void>((resolve, reject) => {
      tx.onerror = () => {
        reject(new Error(`Error adding data to ${dbConfig.name} database`));
      };

      tx.oncomplete = async () => {
        const store = data.current.find((store) => store.idx === storeIdx)!;
        store.data = [...store.data, ...objects];
        // store.data = await fetchData(storeIdx);
        c_log(`New object added to ${dbConfig.name} database`, objects);
        resolve();
      };

      for (const obj of objects) store.put(obj);
    });
  };

  const deleteBulkData = async (storeName: StoreName, ids: DBObjectId[]) => {
    const storeIdx = dbConfig.stores.findIndex((v) => v.name === storeName);
    const { store, tx } = await connect(storeIdx, "readwrite");

    return new Promise<void>((resolve, reject) => {
      tx.onerror = () => {
        reject(new Error(`Error deleting data to ${dbConfig.name} database`));
      };

      tx.oncomplete = async () => {
        const store = data.current.find((store) => store.idx === storeIdx)!;
        store.data = store.data.filter((obj) =>
          ids.some((id) => id !== obj.id)
        );
        // store.data = await fetchData(storeIdx);
        c_log(`Objects deleted from ${dbConfig.name} [${storeName}] database`);
        resolve();
      };

      for (const id of ids) store.delete(id);
    });
  };

  const getDataFromState = (storeName: StoreName, ids?: DBObjectId[]) => {
    const storeIdx = dbConfig.stores.findIndex((v) => v.name === storeName);
    const store = data.current.find((store) => store.idx === storeIdx)!;

    if (!ids) return store.data;

    const filtered = store.data.filter((v) => ids.includes(v.id));
    return filtered.length !== 0 ? filtered : undefined;
  };

  const getData = <T = unknown>(storeName: StoreName, ids?: DBObjectId[]) => {
    return getDataFromState(storeName, ids)?.map((obj) => obj.key) as T[];
  };

  const excludes = (storeName: StoreName, ids: DBObjectId[]) => {
    const storeIdx = dbConfig.stores.findIndex((v) => v.name === storeName);
    const store = data.current.find((store) => store.idx === storeIdx)!;
    return ids.filter((id) => !store.data.some((obj) => obj.id === id));
    // return ids.filter((id) => data.find((obj) => obj.id === id) === undefined);
  };

  // TODO!: Pass storeName as currently connect(0,...) idx = 0
  const clearData = async () => {
    const { store } = await connect(0, "readwrite");
    return new Promise<void>((resolve, reject) => {
      const req = store.clear();

      req.onerror = () =>
        reject(new Error(`Error clearing data from ${dbConfig.name} database`));

      req.onsuccess = () => {
        data.current = [];
        resolve();
        // resolve(req.result);
      };
    });
  };

  const fetchData = async (storeIdx: number) => {
    const { store } = await connect(storeIdx, "readonly");
    return new Promise<DBObject[]>((resolve, reject) => {
      const req = store.getAll();

      req.onerror = () =>
        reject(new Error(`Error fetching data from ${dbConfig.name} database`));

      req.onsuccess = () => resolve(req.result);
    });
  };

  useEffect(() => {
    const fn = async () => {
      const promises = dbConfig.stores.map(async (store, idx) => {
        const data = await fetchData(idx);
        return { idx, data } as StoreData;
      });
      const res = await Promise.all(promises);
      data.current = res;
    };
    fn();
  }, [dbConfig]);

  return { addData, addBulkData, deleteBulkData, getData, excludes, clearData };
};

const openDB = <T extends string>(
  dbConfig: DBConfig<T>
): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dbConfig.name, dbConfig.version);

    request.onerror = () =>
      reject(new Error(`Failed to open database ${dbConfig.name}`));

    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = () => {
      const db = request.result;
      dbConfig.stores.forEach((storeConfig) => {
        const store = db.createObjectStore(
          storeConfig.name,
          storeConfig.options
        );
        if (storeConfig.index) {
          storeConfig.index.forEach((indexConfig) => {
            store.createIndex(
              indexConfig.name,
              indexConfig.key,
              indexConfig.options
            );
          });
        }
      });
    };
  });
};

export default useIndexedDB;
