import { useEffect, useState } from "react";
import { useAuth } from "../AuthContext";
import { ThemeMode, useCustomTheme } from "../CustomTheme/CustomThemeContext";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useTranslation } from "react-i18next";
import { getDefaultLanguage } from "../../common/lang";
import { LanguageCodes } from "../../shared/languages";

export type InputMode = "buttons" | "keys" | "mic";

type State<T> = {
  V: T;
  F: (prevState: T) => T;
  VF: State<T>["V"] | State<T>["F"];
};

type SettingType<T> = {
  get: State<T>["V"];
  set: (value: State<T>["VF"]) => void;
};

export interface SettingsData {
  themeMode: SettingType<ThemeMode>;
  monochromatic: SettingType<boolean>;
  autoPlay: SettingType<boolean>;
  autoPlayRecording: SettingType<boolean>;
  compactView: SettingType<boolean>;
  inputMode: SettingType<InputMode>;
  language: SettingType<LanguageCodes>;
  newCard: SettingType<{ lang: LanguageCodes; targetLang: LanguageCodes }>;
  decksOrder: SettingType<string[]>;

  synchronize: () => Promise<void>; // Synchronization with Firestore database
}

type Settings = {
  themeMode: ThemeMode;
  monochromatic: boolean;
  autoPlay: boolean;
  autoPlayRecording: boolean;
  compactView: boolean;
  inputMode: InputMode;
  langCode: LanguageCodes;
  newCard: { lang: LanguageCodes; targetLang: LanguageCodes };
  decksOrder: string[];
};

// TODO: CustomTheme and SettingsData share some data, so the question is
//   which entity should be the owner (for example themeMode and monochromatic)
export default function useSettingsData(): SettingsData {
  const { user } = useAuth();
  const { i18n } = useTranslation();

  const [autoPlay, setAutoPlay] = useState(false);
  const [autoPlayRecording, setAutoPlayRecording] = useState(false);
  const [compactView, setCompactView] = useState(false);
  const [inputMode, setInputMode] = useState<InputMode>("buttons");
  const [langCode, setLangCode] = useState(getDefaultLanguage());
  const [newCard, setNewCard] = useState<{
    lang: LanguageCodes;
    targetLang: LanguageCodes;
  }>({ lang: getDefaultLanguage(), targetLang: getDefaultLanguage() });
  const [decksOrder, setDecksOrder] = useState<string[]>([]);

  const {
    mode: [themeMode, setThemeMode],
    isDecolorized,
    setDecolorized,
  } = useCustomTheme();
  const [isSync, setIsSync] = useState(true);

  useEffect(() => {
    setIsSync(false);
  }, [
    themeMode,
    autoPlay,
    autoPlayRecording,
    compactView,
    langCode,
    inputMode,
    isDecolorized,
    newCard,
    decksOrder,
  ]);

  useEffect(() => {
    i18n.changeLanguage(langCode);
  }, [langCode]);

  useEffect(() => {
    if (user === null) return;

    const refDoc = doc(db, `settings/${user.uid}`);
    return onSnapshot(refDoc, (docSnap) => {
      const data = docSnap.data() as Settings | undefined;
      setAutoPlay(data?.autoPlay ?? autoPlay);
      setAutoPlayRecording(data?.autoPlayRecording ?? autoPlayRecording);
      setCompactView(data?.compactView ?? compactView);
      setInputMode(data?.inputMode ?? inputMode);
      setDecolorized(data?.monochromatic ?? isDecolorized);
      setThemeMode(data?.themeMode ?? "system");
      setLangCode(data?.langCode ?? langCode);
      setNewCard(data?.newCard ?? newCard);
      setDecksOrder(data?.decksOrder ?? []);
    });
  }, [user]);

  const getSet = <T,>(
    getState: T,
    setState: (state: State<T>["VF"]) => void
  ) => {
    return {
      get: getState,
      set: (vf: State<T>["VF"]) => {
        const isFn = typeof vf === "function";
        setState(isFn ? (prev) => (vf as State<T>["F"])(prev) : vf);
      },
    };
  };

  const synchronize = async () => {
    if (isSync) return;

    const refDoc = doc(db, "settings", user!.uid);
    const data: Settings = {
      themeMode,
      autoPlay,
      autoPlayRecording,
      compactView,
      inputMode,
      langCode: langCode as LanguageCodes, // TODO: try changing langCode from string to LanguageCodes
      newCard,
      monochromatic: isDecolorized,
      decksOrder,
    };
    await setDoc(refDoc, data);
    setIsSync(true);
  };

  return {
    themeMode: getSet(themeMode, setThemeMode),
    autoPlay: getSet(autoPlay, setAutoPlay),
    autoPlayRecording: getSet(autoPlayRecording, setAutoPlayRecording),
    compactView: getSet(compactView, setCompactView),
    inputMode: getSet(inputMode, setInputMode),
    monochromatic: getSet(isDecolorized, setDecolorized),
    language: getSet(langCode, setLangCode),
    newCard: getSet(newCard, setNewCard),
    decksOrder: getSet(decksOrder, setDecksOrder),
    synchronize,
  };
}
