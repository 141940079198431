import { FC } from "react";
import { useCustomTheme } from "../contexts/CustomTheme/CustomThemeContext";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { SxProps, Theme } from "@mui/material";

interface LingyLogoProps {
  sx?: SxProps<Theme>;
}

const LingyLogo: FC<LingyLogoProps> = ({ sx }) => {
  const { theme } = useCustomTheme();
  const navigate = useNavigate();

  return (
    <Typography
      sx={{
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 800,
        cursor: "default",
        userSelect: "none",
        color: theme.palette.secondary.main,
        ...sx,
      }}
      // onClick={() => navigate("/")}
    >
      LINGY<span style={{ color: theme.palette.secondary.dark }}>APP</span>
    </Typography>
  );
};

export default LingyLogo;
