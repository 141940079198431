import { useCallback, useEffect, useRef } from "react";
import { tDeckConfigReq, tDeckConfig } from "../../shared/types";
import { useAuth } from "../../contexts/AuthContext";
import { ConfigPanelProps } from "./ConfigPanel";

export function useConfigPanelClose(
  use: ConfigPanelProps,
  config: tDeckConfig
) {
  const { deck, onClose } = use;
  const { callCloudFn } = useAuth();

  const handleClose = useCallback(async () => {
    try {
      const configChanged =
        deck.config.dailyNewCards !== config.dailyNewCards ||
        deck.config.dailyReviewCards !== config.dailyReviewCards;

      if (configChanged) {
        onClose(true);
        await callCloudFn<tDeckConfigReq>("deckconfig", {
          deckid: deck.id,
          config: config,
        });
        // Decks items use the data from the onSnapshot to present themselves.
        // In the result the change triggered via "deckconfig" cloud function
        // should be reflected in the UI the moment the change will be made
        // in the Firestore db (therefore no need to unfreeze/sync the deck
        // in the UI)
      }
    } catch (err) {
      onClose(false);
      throw err;
    }
  }, [config, deck.config]);

  const handleCloseRef = useRef(handleClose);

  useEffect(() => {
    handleCloseRef.current = handleClose;
  }, [handleClose]);

  useEffect(() => {
    // If the user has made a change in deck's configuration and is redirecting
    // to a different page in order not to lose this change we are posting it
    // upon component unmount
    return () => {
      handleCloseRef.current();
    };
  }, []);

  return handleClose;
}
