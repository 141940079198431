import { useTranslation } from "react-i18next";
import { Video } from "../../components/Video";
import { Grid } from "@mui/material";

const items = [
  {
    videoFile: "chromex.mov",
    description:
      "description description description description description description description description description description description description description description description description description description description description description ",
  },
  {
    videoFile: "example.mov",
    description: "Connecting kindle, looking the file up and importing",
  },
  {
    videoFile: "landing.mov",
    description: "Selecting books for conversion, converting",
  },
  {
    videoFile: "chromex.mov",
    description: "Setting up a session and doing a flashcard's reviews",
  },
];

export const HelpPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "help_page" });

  return (
    <Grid container spacing={3}>
      {items.map(({ videoFile, description }, idx) => {
        return (
          <Grid key={idx} item xs={12} md={6}>
            <Video
              fileName={videoFile}
              title={description}
              sx={{ width: "100%" }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
