import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";

export interface DialogParseKindleProps {
  fileName: string;
}

export const DialogParseKindle: FC<DialogParseKindleProps> = ({ fileName }) => {
  const { t } = useTranslation("translation", { keyPrefix: "dialogs" });

  return (
    <Box
      sx={{
        m: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="caption">{t("importing")}</Typography>
      <Typography gutterBottom>{fileName}</Typography>
      <Typography>{t("please_wait")}</Typography>
      <LinearProgress sx={{ width: "100%" }} />
    </Box>
  );
};
