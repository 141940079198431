import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { TranslationFlags } from "../BookItem/TranslationFlags";
import ErrorIcon from "@mui/icons-material/Error";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import { useTranslation } from "react-i18next";
import { tDeck } from "../../shared/types";
import { useAppData } from "../../contexts/AppData/AppDataContext";

interface FrontCoverProps {
  deck: tDeck;
  newCount: number;
  reviewCount: number;
  disabled: boolean;
}

const Text = styled(Typography)<TypographyProps & { disabled: boolean }>(
  ({ theme, disabled }) => ({
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  })
);

export const FrontCover: React.FC<FrontCoverProps> = ({
  deck,
  newCount,
  reviewCount,
  disabled,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "deck_item" });
  const { lesson } = useAppData();

  return (
    <CardContent
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100%",
        flexWrap: "wrap",
      }}
    >
      {lesson.deckId === deck.id && (
        <Text
          variant="caption"
          disabled={disabled}
          sx={{
            position: "absolute",
            top: 0,
            color: ({ palette }) => palette.secondary.main,
            fontWeight: 600,
          }}
        >
          {t("active_lesson")}
        </Text>
      )}
      <Box sx={{ m: "1rem", flexBasis: "200px", flexGrow: 1 }}>
        <Text variant="caption" display="block" disabled={disabled}>
          {t("name")}
        </Text>
        <Text
          gutterBottom
          disabled={disabled}
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {deck.info.name}
        </Text>
        <TranslationFlags
          langBook={deck.info.lang}
          langTarget={deck.info.targetLang}
          disabled={disabled}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          mx: "1rem",
          flexBasis: "10px",
          flexGrow: 1,
        }}
      >
        <DeckInfo
          title={t("size")}
          count={deck.info.size}
          disabled={disabled}
        />
        <Box sx={{ display: "flex", flexShrink: 0, justifyContent: "center" }}>
          <DeckInfo
            title={t("review_count")}
            count={reviewCount}
            disabled={disabled}
            warning
          />
          <DeckInfo
            title={t("new_count")}
            count={newCount}
            disabled={disabled}
          />
        </Box>
      </Box>
    </CardContent>
  );
};

interface DeckInfoProps {
  title: string;
  count: number;
  disabled: boolean;
  warning?: boolean;
}

const DeckInfo: React.FC<DeckInfoProps> = ({
  title,
  count,
  disabled,
  warning = false,
}) => {
  const { theme } = useCustomTheme();
  const { t } = useTranslation("translation", { keyPrefix: "deck_item" });

  return (
    <Box sx={{ mx: 1, my: 0.5 }}>
      <Text variant="caption" disabled={disabled} sx={{ position: "relative" }}>
        {title}
        {warning && count > 0 && (
          <ErrorIcon
            sx={{
              position: "absolute",
              top: "-0.4rem",
              right: "-0.6rem",
              fontSize: "0.8rem",
              color: disabled
                ? theme.palette.text.disabled
                : theme.palette.secondary.main,
            }}
          />
        )}
      </Text>
      <Text variant="body2" disabled={disabled}>
        {`${count} ${t("cards")}`}
      </Text>
    </Box>
  );
};
