// Set of types shared by the UI and the server. Those types are passed
// from the UI in form of requests and in form of responses from the server.

export type tBook = {
  id: string;
  title: string;
  authors: string;
  coverUrl?: string;
  lang: string;
  lookups: number;
};

export type tImportEbookReq = {
  name: string;
  date: number;
  blob: string;
};

export type tImportEbookRes = number;

export type tOcrReq = {
  filename: string;
  blob: string;
};

export type tOcrRes = string;

export type tCardBase = {
  word: string;
  usages: string[];
  url?: string;
};

export type tCard = {
  id: string;
  stem: string;
  word: string;
  short: string;
  examples: string[];
  synonyms: string[];
  long: string;
  idxExample: number;
  url?: string;
};

export type tDeckInfo = {
  name: string;
  lang: string;
  bookId?: string;
  coverUrl: string;
  size: number; // Orig size
  targetLang: string;
  // removed field being duplicated in tDeckData in order
  // to optimalise documents' reading from Firestore
  removed: boolean;
};

export type tDeckConfig = {
  dailyNewCards: number;
  dailyReviewCards: number;
};

export type tDeckData = {
  newAnsweredToday: number;
  reviewAnsweredToday: number;
  newCards: string[];
  reviewCards: string[];
  removed: boolean;
  touchedAt: number;
};

export type tDeck = {
  id: string;
  info: tDeckInfo;
  config: tDeckConfig;
  data: tDeckData;
};

export type tDeckCreateReq = {
  id: string; // TODO!: bookId?
  lang: string;
}[];

export type tDeckCreateRes = tVoidRes;

export type tFetchCardsReq = {
  deckid: string;
  ids: string[];
};

export type tFetchCardsRes = tCard[];

export enum UserAnswer {
  Bury = -1,
  Again = 0,
  Good = 1,
  Great = 2,
}

export type tCardAnswerReq = {
  deckid: string;
  cardid: string;
  answer: UserAnswer;
  idxExample: number;
};

export type tCardAnswerRes =
  | {
      due: string;
    }
  | tVoidRes;

export type tTtsReq = {
  text: string;
  lang: string;
};

export type tTtsRes = number[]; // TODO: is this type correct?

export type tSttReq = {
  blob: number[];
  phrase: string;
  lang: string;
};

export type tSttRes = {
  text: string;
};

export type tDeckConfigReq = {
  deckid: string;
  config: tDeckConfig;
};

export type tSubscribeReq = {
  paymentMethodId: string;
};

export type tSubscribeRes = {
  status: string;
  subscriptionId: string;
  price: string;
  billingCycle: string;
  billingStartDate: string;
  error?: string;
};

export type tDeckRemoveReq = {
  deckid: string;
};

export const defineTextDef = {
  maxSelection: 32,
  maxSentence: 64,
};

export type tDefineTextReq = {
  selection: string;
  sentence: string;
  url: string;
  lang: string;
};

export type tDefineTextRes = {
  def: string;
  part: string;
} | null;

export type tInsertCardReq = {
  selection: string;
  sentence: string;
  url?: string;
  lang: string;
  targetLang: string;
};

export type tWordContextReq = {
  text: string;
  lang: string;
  targetLang: string;
};

export type tWordContext = {
  match: {
    lang: string;
    target: string;
  };
  context: string;
  definition: string;
  example: string;
};

export type tWordContextRes = tWordContext[];

export type tTouchRes = number;

export type tVoidRes = void | null;

export const maxDailyNewCards = 10;

export const maxDailyReviewCards = 20;

export type VocabSnapshotUi = {
  idSnap: string;
  books: tBook[];
};

// Firebase FireStore types

export type FsDeckStatData = {
  cardId: string;
  diff: number;
  due: number;
};
