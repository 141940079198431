// Converts a hexadecimal color code (valid notations: #ffffff or #fff)
// to an RGB color object
export function hexToRgb(hex: string): {
  r: number;
  g: number;
  b: number;
  a?: number;
} {
  const hexString = hex.startsWith("#") ? hex.slice(1) : hex;
  const hexInt = parseInt(
    hexString.length === 3 ? hexString.repeat(2) : hexString,
    16
  );
  const alpha = hex.length === 4 ? ((hexInt >> 12) & 15) / 15 : undefined;
  return {
    r: (hexInt >> 16) & 255,
    g: (hexInt >> 8) & 255,
    b: hexInt & 255,
    a: alpha,
  };
}

export function rgbToHex(r: number, g: number, b: number, a?: number): string {
  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");
  const alphaHex =
    a !== undefined
      ? Math.round(a * 255)
          .toString(16)
          .padStart(2, "0")
      : "";
  return `#${rHex}${gHex}${bHex}${alphaHex}`;
}

export function decolorize(hexColor: string) {
  const { r, g, b, a } = hexToRgb(hexColor);
  const avg = Math.round((r + g + b) / 3);
  return rgbToHex(avg, avg, avg, a);
}
