import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface SelectActionItemProps {
  text: string;
  action: { title: string; onClick: () => void };
}

export const SelectActionItem: FC<SelectActionItemProps> = ({
  text,
  action,
}) => {
  return (
    <React.Fragment>
      <Typography variant="body2" gutterBottom>
        {text}
      </Typography>
      <Button variant="text" color="info" size="small" onClick={action.onClick}>
        {action.title}&nbsp;&nbsp;&gt;
      </Button>
    </React.Fragment>
  );
};
