import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const Paragraph = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const BulletItem = styled(ListItem)({
  display: "list-item",
  listStyleType: "disc",
  listStylePosition: "inside",
});

export const PrivacyPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: theme.palette.background.paper }}>
        <Container maxWidth="md">
          <Box px={{ md: 8 }} py={4}>
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => {
                navigate("/");
              }}
            >
              Back to home page
            </Link>
            <Paragraph>
              Thank you for considering using Lingy.app for your language
              learning needs. We understand the importance of privacy and take
              great care to ensure that your personal information is protected.
            </Paragraph>
            <Paragraph>
              This Privacy Policy governs the manner in which Lingy.app
              collects, uses, maintains, and discloses information collected
              from users (hereafter referred to as "you" or "users") of our
              website and mobile application (hereafter referred to as
              "Services"). This Policy applies to all users of the Services,
              including those who access our Services for the purpose of
              language learning or any other reason.
            </Paragraph>
            <Paragraph variant="h6">Information We Collect</Paragraph>
            <Paragraph>
              At Lingy.app, we collect several types of information from our
              users, including personal information and non-personal
              information.
            </Paragraph>
            <Paragraph>
              Personal information: We collect personal information that you
              provide to us when you register for an account, use our Services,
              or communicate with us. This may include your name, email address,
              phone number, billing information, and other information that you
              voluntarily provide to us. We use this information to provide you
              with our Services, process your payments, communicate with you,
              and improve our Services.
            </Paragraph>
            <Paragraph>
              Non-personal information: We also collect non-personal information
              about your use of our Services. This may include your IP address,
              browser type, operating system, device type, location data, and
              other information that is automatically collected when you use our
              Services. We use this information to analyze how our Services are
              used, improve our Services, and provide you with customized
              content and advertisements.
            </Paragraph>
            <Paragraph>
              Cookies and other technologies: As described in our Cookies and
              Other Technologies section, we use cookies and other technologies
              to collect information about your use of our Services. This
              includes information about your browsing behavior, preferences,
              and other details that help us improve your experience on our
              Services.
            </Paragraph>
            <Paragraph>
              Third-party information: We may also collect information about you
              from third-party sources, such as social media platforms,
              advertising partners, and analytics providers. This information
              may include demographic information, interests, and other
              information that helps us better understand our users and provide
              them with personalized content and advertisements.
            </Paragraph>
            <Paragraph>
              Children's information: We do not knowingly collect personal
              information from children under the age of 13. If you are a parent
              or legal guardian and believe that your child has provided us with
              personal information, please contact us at support@lingy.app. We
              will take steps to delete the information as soon as possible.
            </Paragraph>
            <Paragraph>
              If you have any questions or concerns about the information we
              collect, please contact us at support@lingy.app. We will be happy
              to provide you with additional information and address your
              concerns.
            </Paragraph>
            <Paragraph variant="h6">How We Use Your Information</Paragraph>
            <List>
              <ListItemText>
                We use your personal information for the following purposes:
              </ListItemText>
              <BulletItem>
                To provide you with language learning services and products.
              </BulletItem>
              <BulletItem>
                To process your payments and communicate with you about your
                account.
              </BulletItem>
              <BulletItem>
                To improve our website and mobile application.
              </BulletItem>
              <BulletItem>
                To personalize your experience on our Services.
              </BulletItem>
              <BulletItem>
                To send you marketing communications about our products and
                services.
              </BulletItem>
            </List>
            <Paragraph>
              We may also use your information for research purposes or to
              comply with legal requirements.
            </Paragraph>
            <Paragraph variant="h6">How We Protect Your Information</Paragraph>
            <Paragraph>
              At Lingy.app, we take the security of your personal information
              very seriously. We have implemented a variety of technical,
              administrative, and physical measures to protect your information
              from unauthorized access, use, or disclosure.
            </Paragraph>
            <Paragraph>
              Technical measures: We use industry-standard encryption protocols,
              such as Secure Sockets Layer (SSL) and Transport Layer Security
              (TLS), to protect the transmission of your data over the internet.
              We also use firewalls and other network security measures to
              prevent unauthorized access to our systems.
            </Paragraph>
            <Paragraph>
              Administrative measures: We have strict access controls and
              authentication procedures in place to limit access to your
              information to only those employees and contractors who have a
              legitimate need to access it. We also provide regular training to
              our employees and contractors on data protection best practices.
            </Paragraph>
            <Paragraph>
              Physical measures: We store your data on servers that are located
              in secure data centers with restricted physical access. We also
              back up your data regularly to prevent data loss in the event of a
              system failure or other unexpected event.
            </Paragraph>
            <Paragraph>
              Despite our best efforts, no security measures can guarantee 100%
              protection against all threats. Therefore, we cannot guarantee the
              security of your personal information. However, we will take all
              reasonable steps to protect your information from unauthorized
              access, use, or disclosure.
            </Paragraph>
            <Paragraph>
              If you have reason to believe that your information has been
              compromised, please contact us immediately at support@lingy.app.
              We will investigate the matter and take appropriate steps to
              mitigate any harm that may have occurred.
            </Paragraph>
            <Paragraph>
              We also recommend that you take steps to protect your own
              information, such as using strong passwords, keeping your login
              credentials confidential, and using up-to-date antivirus software
              on your device.
            </Paragraph>
            <Paragraph>
              If you have any questions or concerns about how we protect your
              information, please contact us at support@lingy.app. We will be
              happy to address your concerns and provide you with additional
              information about our security practices.
            </Paragraph>
            <Paragraph variant="h6">Sharing Your Information</Paragraph>
            <Paragraph>
              We may share your information with third parties for a variety of
              reasons, including to provide you with our Services, improve our
              Services, and comply with legal requirements. Here are some of the
              ways in which we may share your information:
            </Paragraph>
            <Paragraph>
              Service Providers: We may share your information with third-party
              service providers who help us provide our Services. This may
              include payment processors, cloud storage providers, email service
              providers, and customer support platforms. We only share the
              information that is necessary for these service providers to
              perform their functions, and we require them to use your
              information only for the purposes of providing services to us.
            </Paragraph>
            <Paragraph>
              Affiliates: We may share your information with our affiliates,
              subsidiaries, and related entities for the purpose of providing
              you with our Services. We require our affiliates to comply with
              this Privacy Policy and to use your information only for the
              purposes for which it was shared.
            </Paragraph>
            <Paragraph>
              Business Partners: We may share your information with business
              partners who offer complementary products or services that we
              believe may be of interest to you. We will only share your
              information with your consent or where permitted by law.
            </Paragraph>
            <Paragraph>
              Advertising Partners: We may share your information with
              third-party advertising partners who help us deliver targeted
              advertising to you. This may include advertising networks, social
              media platforms, and other advertising partners. We may share
              non-personal information and pseudonymous identifiers with these
              partners in order to deliver relevant ads to you.
            </Paragraph>
            <Paragraph>
              Legal Requirements: We may disclose your information in response
              to a subpoena, court order, or other legal process. We may also
              disclose your information to law enforcement agencies, government
              officials, or other third parties as required by law or to protect
              our legal rights.
            </Paragraph>
            <Paragraph>
              Corporate Transactions: If we are involved in a merger,
              acquisition, or sale of all or a portion of our assets, your
              information may be transferred as part of that transaction. We
              will notify you via email or by posting a notice on our Services
              of any change in ownership or use of your personal information.
            </Paragraph>
            <Paragraph>
              We will not sell, rent, or otherwise disclose your personal
              information to third parties for their own marketing purposes
              without your consent.
            </Paragraph>
            <Paragraph>
              If you have any questions or concerns about how we share your
              information, please contact us at support@lingy.app. We will be
              happy to provide you with additional information and address your
              concerns.
            </Paragraph>
            <Paragraph variant="h6">Your Choices</Paragraph>
            <List>
              <ListItemText>
                You have the right to control how we use your personal
                information by:
              </ListItemText>
              <BulletItem>Opting out of marketing communications.</BulletItem>
              <BulletItem>Updating your account information.</BulletItem>
              <BulletItem>Deleting your account.</BulletItem>
            </List>
            <Paragraph>
              However, please note that deleting your account may not completely
              remove all of your personal information from our systems.
            </Paragraph>
            <Paragraph variant="h6">Cookies and Other Technologies</Paragraph>
            <Paragraph>
              Cookies and other technologies are used to collect information
              about how users interact with our website and mobile application.
              Cookies are small data files that are stored on your device when
              you visit a website. They contain information about your browsing
              behavior, preferences, and other details that help us improve your
              experience on our Services.
            </Paragraph>
            <Paragraph>
              We may also use web beacons, which are small, transparent images
              that are embedded in web pages or emails. They help us understand
              how users interact with our Services by tracking user behavior,
              such as which pages they visit, which links they click, and how
              long they spend on each page.
            </Paragraph>
            <List>
              <ListItemText>
                We use cookies and other technologies for various purposes, such
                as:
              </ListItemText>
              <BulletItem>
                To remember your preferences, such as your language or currency
                settings.
              </BulletItem>
              <BulletItem>
                To personalize your experience, such as by displaying customized
                content or advertisements.
              </BulletItem>
              <BulletItem>
                To analyze how users interact with our Services, such as which
                pages are most popular and which features are used most often.
              </BulletItem>
              <BulletItem>
                To improve the performance and security of our Services, such as
                by identifying and fixing errors or bugs.
              </BulletItem>
            </List>
            <Paragraph>
              We may also use third-party cookies and other technologies for
              advertising and analytics purposes. These cookies may be set by
              our advertising partners or analytics providers, and they may
              track your browsing behavior across multiple websites and
              services.
            </Paragraph>
            <Paragraph>
              You can control how cookies and other technologies are used by
              adjusting your browser settings. Most browsers allow you to block
              or delete cookies, or to be notified when a cookie is being placed
              on your device. However, please note that blocking or deleting
              cookies may affect your ability to use certain features of our
              Services.
            </Paragraph>
            <Paragraph>
              By using our Services, you consent to our use of cookies and other
              technologies as described in this Privacy Policy.
            </Paragraph>
            <Paragraph variant="h6">Changes to this Policy</Paragraph>
            <Paragraph>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or to comply with legal requirements.
              When we make material changes to this Policy, we will notify you
              by email or by posting a notice on our Services.
            </Paragraph>
            <Paragraph>
              We encourage you to review this Privacy Policy periodically to
              stay informed about how we collect, use, and disclose your
              personal information. Your continued use of our Services after we
              make changes to this Policy constitutes your acceptance of the
              updated Policy.
            </Paragraph>
            <Paragraph>
              If you do not agree with any changes to this Policy, you may stop
              using our Services and delete your account. However, please note
              that if you continue to use our Services after we make changes to
              this Policy, you will be deemed to have accepted the updated
              Policy.
            </Paragraph>
            <Paragraph>
              If you have any questions or concerns about the changes to this
              Privacy Policy, please contact us at support@lingy.app. We will do
              our best to address your concerns and assist you with any issues
              you may have.
            </Paragraph>
            <Paragraph variant="h6">Contact Us</Paragraph>
            <Paragraph>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at support@lingy.app.
            </Paragraph>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};
