import * as React from "react";
import { tDeck } from "../../shared/types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { BookCover } from "../../components/BookItem/BookCover";
import { BookTitle } from "../../components/BookItem/BookItem";
import { useTranslation } from "react-i18next";

interface DialogDeleteDeckProps {
  deck: tDeck;
  onClickOk: () => void;
  onClickCancel: () => void;
}

export const DialogDeleteDeck: React.FC<DialogDeleteDeckProps> = ({
  deck,
  onClickOk,
  onClickCancel,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "dialogs" });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        width: "300px",
      }}
    >
      <Typography variant="caption" gutterBottom>
        {t("deck_removal")}
      </Typography>
      <BookCover coverUrl={deck.info.coverUrl} sx={{ height: "256px" }} />
      <Box my="1rem">
        <BookTitle>{deck.info.name}</BookTitle>
      </Box>
      <Box mb="1rem">
        <Typography>{t("deck_removal_info")}</Typography>
      </Box>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          variant="contained"
          color="error"
          sx={{ flexGrow: 1, width: "50%", mr: 0.5 }}
          onClick={onClickOk}
        >
          {t("remove")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ flexGrow: 1, width: "50%", ml: 0.5 }}
          onClick={onClickCancel}
        >
          {t("cancel")}
        </Button>
      </Box>
    </Box>
  );
};
