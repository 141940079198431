import useParseImage from "../../hooks/useParseImage";
import useParseKindle from "../../hooks/useParseKindle";
import { StackedItem } from "../LandingPage/StackedSection";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ConstructionIcon from "@mui/icons-material/Construction";
import PsychologyIcon from "@mui/icons-material/Psychology";
import WalletIcon from "@mui/icons-material/Wallet";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ImageIcon from "@mui/icons-material/Image";
import ExtensionIcon from "@mui/icons-material/Extension";
import { ImportEbookReaderPage } from "../ImportEbookReaderPage/ImportEbookReaderPage";
import { ImportImagePage } from "../ImportImagePage/ImportImagePage";
import { useTranslation } from "react-i18next";
import { SelectFileItem } from "./SelectFileItem";
import { ChromeExtensionPage } from "../ChromeExtensionPage/ChromeExtensionPage";
import { SelectActionItem } from "./SelectActionItem";
import { openNewTab } from "../../common/browser";

export function useImportPageItems(): StackedItem[] {
  const { t } = useTranslation("translation", { keyPrefix: "import_page" });
  const parseImage = useParseImage();
  const parseKindle = useParseKindle();

  const handleOpenWebStore = () => {
    const extensionUrl =
      "https://chrome.google.com/webstore/detail/google-translate/aapbdbdomjkkjkaonfhkkikfgjllcleb";
    openNewTab(extensionUrl);
  };

  const items: StackedItem[] = [
    {
      icon: <MenuBookIcon />,
      header: t("ebook_source"),
      body: (
        <SelectFileItem
          text={t("ebook_description")}
          onFileSelected={parseKindle}
        />
      ),
      ref: <ImportEbookReaderPage />,
    },
    // {
    //   icon: <ImageIcon />,
    //   header: t("image_source"),
    //   body: (
    //     <SelectFileItem
    //       text={t("image_description")}
    //       onFileSelected={parseImage}
    //       multiple
    //     />
    //   ),
    //   ref: <ImportImagePage />,
    // },
    {
      icon: <ExtensionIcon />,
      header: t("chrome_source"),
      body: (
        <SelectActionItem
          text={t("chrome_description")!}
          action={{
            title: t("chrome_web_store"),
            onClick: handleOpenWebStore,
          }}
        />
      ),
      ref: <ChromeExtensionPage />,
    },
  ];

  return items;
}
