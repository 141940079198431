import React from "react";
import styled from "@mui/material/styles/styled";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import useMobileView from "../../hooks/useMobileView";
import { SettingsButton } from "./SettingsDrawer";
import { MenuDrawerState, styleMenuDrawerAnim } from "./MenuDrawer";
import { BurgerButton } from "./BurgerMenu";
import Container from "@mui/material/Container";
import { SearchField } from "./SearchField";
import AddIcon from "@mui/icons-material/Add";
import { useAlert } from "../../contexts/Alert/AlertContext";
import { DialogAddCard } from "../../dialogs/DialogAddCard/DialogAddCard";
import { Box, Typography, lighten } from "@mui/material/";

export const NavBarButton = styled(IconButton)(() => ({
  marginLeft: "0.25rem",
  marginRight: "0.25rem",
}));

interface NavBarProps {
  menuDrawerState: MenuDrawerState;
}

export const NavBar: React.FC<NavBarProps> = ({
  menuDrawerState: { drawerWidth },
}) => {
  const { showDialog } = useAlert();
  const isMobile = useMobileView();

  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{
        boxShadow: "none",
        ...styleMenuDrawerAnim(`calc(100% - ${drawerWidth}px)`),
      }}
    >
      <Container>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          {isMobile && (
            <Box sx={{ flexGrow: 1 }}>
              <BurgerButton />
            </Box>
          )}
          <SearchField />
          <NavBarButton
            aria-label="add-button"
            size="small"
            // sx={{
            //   borderColor: (theme) => alpha(theme.palette.secondary.dark, 0.2),
            //   backgroundColor: (theme) =>
            //     alpha(theme.palette.secondary.main, 0.2),
            // }}
            onClick={() => {
              const activeFocus = document.activeElement as HTMLElement;
              if (activeFocus) activeFocus.blur();

              const closeDialog = showDialog(
                <DialogAddCard onClose={() => closeDialog()} />,
                {
                  dialogProps: {
                    fullWidth: true,
                    maxWidth: "sm",
                  },
                  sx: { p: 0, width: "100%", height: "500px" },
                }
              );
            }}
          >
            <AddIcon fontSize="small" />
            <Typography
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: "0.75rem",
                fontWeight: 600,
                pl: 0.5,
                pr: 0.8,
              }}
            >
              New Card
            </Typography>
          </NavBarButton>
          <NavBarButton
            aria-label="notifications-button"
            size="small"
            onClick={() => {}}
          >
            <NotificationsOutlinedIcon fontSize="small" />
          </NavBarButton>
          <SettingsButton />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
