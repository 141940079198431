import { FC } from "react";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { useMicrophoneRecording } from "../../hooks/useMicrophoneRecording";
import { SxProps, Theme } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

interface MicrophoneButtonProps extends IconButtonProps {
  onClick?: () => void;
  onRecordFinished?: (data: number[] | null) => void;
  sx?: SxProps<Theme>;
}

export const MicrophoneButton: FC<MicrophoneButtonProps> = ({
  onClick,
  onRecordFinished,
  sx,
  ...rest
}) => {
  const [isRecording, record] = useMicrophoneRecording();

  const pulsateAnimation = keyframes`
  0%   { background-color: rgba(255, 0, 0, 0.05); }
  50%  { background-color: rgba(255, 0, 0, 0.1); }
  100% { background-color: rgba(255, 0, 0, 0.05); }
  `;

  return (
    <IconButton
      color={isRecording ? "error" : "primary"}
      onClick={async () => {
        onClick?.();
        const recordingData = await record();
        onRecordFinished?.(recordingData);
      }}
      sx={{
        animation: isRecording ? `${pulsateAnimation} 1s infinite` : undefined,
        ...sx,
      }}
      {...rest}
    >
      <KeyboardVoiceIcon fontSize="large" />
    </IconButton>
  );
};
