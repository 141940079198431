import { useAppData } from "../contexts/AppData/AppDataContext";
import useDeck from "../pages/LearnPage/useDeck";

function useCurrentDeck() {
  const { lesson } = useAppData();
  const deck = useDeck(lesson.deckId)!;
  return deck;
}

export default useCurrentDeck;
