import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";

type SectionHeaderProps = {
  title: string;
  children: React.ReactNode;
} & (BoxProps & SxProps);

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  title,
  sx,
  ...rest
}) => {
  const { theme } = useCustomTheme();

  return (
    <Box sx={{ paddingBottom: "1rem", ...sx }} {...rest}>
      <Typography
        variant="body2"
        gutterBottom
        sx={{ color: theme.palette.primary.main, fontWeight: 800 }}
      >
        {title}
      </Typography>
      <Typography variant="h2" gutterBottom sx={{ fontWeight: 800 }}>
        {children}
      </Typography>
    </Box>
  );
};
