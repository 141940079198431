import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Palette,
  Shadows,
  alpha,
  colors,
  darken,
  lighten,
  rgbToHex,
  useTheme,
} from "@mui/material";
import { decolorize } from "../../common/colors";
import { ThemeMode } from "./CustomThemeContext";

const createColor = (color: Record<number, string>, base: number = 500) => {
  return {
    light: rgbToHex(lighten(color[base], 0.5)),
    main: color[base],
    dark: rgbToHex(darken(color[base], 0.3)),
    contrastText: color[50],
  };
};

const createAction = (color: Record<number, string>) => {
  return {
    active: color[400],
    hover: alpha(color[400], 0.1),
    hoverOpacity: 0.1,
    selected: alpha(color[400], 0.1),
    selectedOpacity: 0.1,
    disabled: colors.grey[300],
    disabledOpacity: 0.1,
    disabledBackground: colors.grey[400],
    focus: alpha(color[400], 0.3),
    focusOpacity: 0.1,
    activatedOpacity: 0.1,
  };
};

const lightPalette: Partial<Palette> = {
  action: createAction(colors.blue),
  primary: createColor(colors.blue),
  secondary: createColor(colors.lightBlue),
  divider: colors.grey[300],
  background: {
    default: lighten(colors.blue[50], 0.5), // colors.grey[50],
    paper: colors.common.white,
  },
  text: {
    primary: colors.grey[800],
    secondary: colors.grey[600],
    disabled: colors.grey[300],
  },
};

const darkPalette: Partial<Palette> = {
  action: createAction(colors.grey),
  primary: createColor(colors.grey), // TODO: what about contrastText in dark mode?
  secondary: createColor(colors.orange),
  divider: colors.grey[700],
  background: {
    default: "#111111",
    paper: "#333333",
  },
  text: {
    primary: colors.grey[300],
    secondary: colors.grey[500],
    disabled: colors.grey[700],
  },
};

// decolorization of Theme's palette object
function decolorizePalette(palette: any) {
  const newPalette: any = {};
  for (const key in palette) {
    if (typeof palette[key] === "string") {
      newPalette[key] = palette[key].startsWith("#") // color (ex. #77FF88)
        ? decolorize(palette[key])
        : palette[key];
    } else if (typeof palette[key] === "object")
      newPalette[key] = decolorizePalette(palette[key]);
  }
  return newPalette as Palette;
}

export default function useCustomPalette() {
  const prefersDark = useMediaQuery("(prefers-color-scheme: dark)");
  const [themeMode, setThemeMode] = React.useState<ThemeMode>("system");
  const [isDecolorized, setIsDecolorized] = React.useState(false);
  const defaultTheme = useTheme();

  function getPalette(mode: "light" | "dark") {
    const customPalette: Palette = {
      ...defaultTheme.palette,
      mode,
      ...(mode === "light" ? lightPalette : darkPalette),
    };

    return isDecolorized ? decolorizePalette(customPalette) : customPalette;
  }

  function getShadows() {
    const customShadows = [...defaultTheme.shadows] as Shadows;
    // usage sx={{ boxShadow: 10 }}
    customShadows[10] = `0px 3px 3px -2px ${palette.secondary.main}, 0px 3px 4px 0px ${palette.secondary.main}, 0px 1px 8px 0px ${palette.secondary.main}`;
    return customShadows;
  }

  const mode =
    themeMode === "system" ? (prefersDark ? "dark" : "light") : themeMode;

  const palette = getPalette(mode);
  const shadows = getShadows();

  return {
    palette,
    shadows,
    themeMode: { get: themeMode, set: setThemeMode },
    decolorized: { get: isDecolorized, set: setIsDecolorized },
  };
}
