import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import {
  maxDailyNewCards,
  maxDailyReviewCards,
  tDeck,
  tDeckConfig,
} from "../../shared/types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { DailyCardsSlider } from "./DailyCardsSlider";
import { useTranslation } from "react-i18next";
import { useConfigPanelClose } from "./useConfigPanelClose";
import { useConfigPanelDeckDelete } from "./useConfigPanelDeckDelete";

export interface ConfigPanelProps {
  deck: tDeck;
  show: boolean;
  refOwner: React.RefObject<HTMLDivElement>;
  onClose: (deckInSync: boolean) => void; // blocking deck (loading animation) if deckInSync == true
}

export const ConfigPanel: React.FC<ConfigPanelProps> = (props) => {
  const { deck, show, refOwner, onClose } = props;
  const { t } = useTranslation("translation", { keyPrefix: "deck_item" });
  const [config, setConfig] = useState<tDeckConfig>({ ...deck.config });
  const handleClose = useConfigPanelClose(props, config);
  const handleDeckDelete = useConfigPanelDeckDelete(props);

  return (
    <Slide
      in={show}
      direction="left"
      container={refOwner.current}
      onExit={handleClose}
      unmountOnExit // TODO: new and review count cards improperly setuped when uncommented ?
    >
      <Paper
        sx={{
          position: "absolute",
          p: "0.5rem 10%",
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          size="large"
          onClick={handleDeckDelete}
          // sx={{ flexDirection: "column" }}
        >
          <DeleteIcon />
          {/* <Typography variant="caption" display="block">
            {t("delete")}
          </Typography> */}
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            flexWrap: "wrap",
          }}
        >
          <DailyCardsSlider
            caption={t("new_max_daily")}
            max={maxDailyNewCards}
            value={config.dailyNewCards}
            onChange={(val) => setConfig({ ...config, dailyNewCards: val })}
          />
          <DailyCardsSlider
            caption={t("review_max_daily")}
            max={maxDailyReviewCards}
            value={config.dailyReviewCards}
            onChange={(val) => setConfig({ ...config, dailyReviewCards: val })}
          />
        </Box>
      </Paper>
    </Slide>
  );
};
