import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { alpha, Theme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import createTheme from "@mui/material/styles/createTheme";
import useCustomPalette from "./Palette";

export type ThemeMode = "light" | "dark" | "system";

type ThemeContext = {
  mode: [ThemeMode, Dispatch<SetStateAction<ThemeMode>>];
  isDecolorized: boolean;
  setDecolorized: Dispatch<SetStateAction<boolean>>;
  theme: Theme;
};

const CustomThemeContext = React.createContext<ThemeContext>(null!);

export function useCustomTheme() {
  return React.useContext(CustomThemeContext);
}

export const CustomThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { palette, shadows, themeMode, decolorized } = useCustomPalette();

  let theme: Theme = createTheme({
    palette,
    shadows,
    shape: { borderRadius: 8 },
    typography: {
      fontFamily: `"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
         "Helvetica Neue", Arial,sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
         "Segoe UI Symbol"`,
      h1: {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "clamp(2.625rem, 1.2857rem + 3.5714vw, 3.7rem)",
        fontWeight: 800,
      },
      h2: {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
        fontWeight: 800,
      },
      h3: {
        fontSize: "0.875rem",
        fontWeight: 700,
      },
      caption: {
        fontFamily: "Plus Jakarta Sans",
        lineHeight: "1rem",
        fontSize: 9,
        textTransform: "uppercase",
        color: palette.text.primary,
      },
    },
  });

  theme = React.useMemo(() => {
    return createTheme(theme, {
      components: {
        MuiCardActionArea: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              padding: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            // rounded: { borderRadius: 10 },
            root: {
              backgroundImage: "none", // fixes Flash Card's Accordion background
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableTouchRipple: true,
          },
        },
        // MuiButton: {
        //   styleOverrides: {
        //     root: {
        //       borderRadius: 8,
        //     },
        //   },
        // },
        MuiIconButton: {
          styleOverrides: {
            sizeSmall: {
              border: `1px solid ${theme.palette.divider}`,
              // border: "solid 1px",
              borderRadius: theme.shape.borderRadius,
              color:
                theme.palette.mode === "light"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            fontSizeSmall: {
              fontSize: "1.25rem",
              color:
                theme.palette.mode === "light"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            },
            fontSizeMedium: {
              color: theme.palette.primary.dark,
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            valueLabel: { backgroundColor: theme.palette.primary.dark },
          },
        },
        // MuiListItemButton: {
        //   styleOverrides: {
        //     root: {
        //       "&:hover": {
        //         backgroundColor: alpha(
        //           theme.palette.action.hover,
        //           theme.palette.action.hoverOpacity
        //         ),
        //       },
        //     },
        //   },
        // },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                ".MuiSvgIcon-root": {
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                },
                ".MuiTypography-root": {
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                },
              },
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            sizeSmall: {
              color: palette.text.secondary,
              fontSize: "0.875rem",
              textTransform: "capitalize",
              padding: "11px",
              "&.Mui-selected:hover": {
                backgroundColor: alpha(
                  theme.palette.action.selected,
                  theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity
                ),
              },
              "&.Mui-selected": {
                fontWeight: 700,
                ".MuiSvgIcon-root": {
                  color: theme.palette.primary.main,
                },
              },
              "&.Mui-selected, &:hover": {
                backgroundColor: alpha(
                  theme.palette.action.hover,
                  theme.palette.action.hoverOpacity
                ),
              },
              // border: "1px solid rgb(224, 227, 231)",
              // borderRadius: "10px",
            },
          },
        },
        // MuiToolbar: {
        //   styleOverrides: {
        //     dense: {
        //       height: 64,
        //       minHeight: 64,
        //     },
        //   },
        // },
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              background: `linear-gradient(to right, transparent, ${theme.palette.background.paper})`,
              //backgroundColor: alpha(theme.palette.background.paper, 0.7),
              backdropFilter: "blur(10px)",
            },
          },
        },
        // MuiDrawer: {
        //   styleOverrides: {
        //     paper: {
        //       backgroundColor: alpha(theme.palette.background.paper, 0.95),
        //       backdropFilter: "blur(10px)",
        //     },
        //   },
        // },
        MuiCardMedia: {
          styleOverrides: {
            root: {
              borderRadius: theme.shape.borderRadius,
            },
          },
        },
        MuiBadge: {
          styleOverrides: {
            badge: {
              "&, &.MuiBadge-colorSecondary": {
                color: theme.palette.background.default,
              },
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              "& .MuiIconButton-root": { border: 0 },
            },
          },
        },
      },
    });
  }, [theme]);

  return (
    <CustomThemeContext.Provider
      value={{
        mode: [themeMode.get, themeMode.set],
        isDecolorized: decolorized.get,
        setDecolorized: decolorized.set,
        theme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};
