import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import CardsMaturityBar from "./CardsMaturityBar";
import RepetitionsHeatmap from "./RepetitionsHeatmap";
import useStatsParser, {
  StatsDueOp,
  StatsMaturityOp,
  StatsRepetitionOp,
} from "./useStatsParser";
import { Box, Tooltip, Typography } from "@mui/material";
import { DecksAutocomplete } from "./DecksAutocomplete";
import { CardsDueBar } from "./CardsDueBar";
import useDeckStats from "./useDeckStats";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";

export const ProgressPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "stats_page" });
  const { theme } = useCustomTheme();
  const [deckId, setDeckId] = useState<string>("All"); // Chosen deck by the user from Autocomplete
  const stats = useStatsParser();

  const mat = useDeckStats(stats, deckId, StatsMaturityOp);
  const rep = useDeckStats(stats, deckId, StatsRepetitionOp);
  const due = useDeckStats(stats, deckId, StatsDueOp);

  const [matStats, setMatStats] = useState(new Map(mat));

  // Filling out card maturity stats for "All" Autocomplete option.
  // When the user learns a random decks at a given day/date, there
  // might be a situation when other decks at this given day will be left out.
  // As a result the decks will have different last date of maturity stats
  // and in order for the chart to be correctly displayed those stats has to be
  // taken into consideration up until the utmost date.
  useEffect(() => {
    const out = new Map<number, [string, number][]>();
    mat.reduce((acc, [date, stat]) => {
      stat.forEach(([cardId, diff]) => acc.set(cardId, diff));
      out.set(date, Array.from(acc));
      return acc;
    }, new Map<string, number>());
    setMatStats(out);
  }, [mat]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        width: "100%",
      }}
    >
      <DecksAutocomplete
        stats={stats}
        deckId={deckId}
        onChange={(deckId) => setDeckId(deckId)}
        sx={{ maxWidth: 300, width: "100%", my: 2 }}
      />
      {stats.length !== 0 && (
        <React.Fragment>
          <ChartHeader title={t("maturity")} hint={t("maturity_hint")}>
            <CardsMaturityBar height={250} theme={theme} stats={matStats} />
          </ChartHeader>
          <ChartHeader title={t("repetitions")} hint={t("repetitions_hint")}>
            <RepetitionsHeatmap height={250} theme={theme} stats={rep} />
          </ChartHeader>
          <ChartHeader title={t("reviews")} hint={t("reviews_hint")}>
            <CardsDueBar height={250} theme={theme} stats={due} />
          </ChartHeader>
        </React.Fragment>
      )}
    </Box>
  );
};

interface ChartHeaderProps {
  title: string;
  hint: string;
}

const ChartHeader: FC<PropsWithChildren<ChartHeaderProps>> = ({
  title,
  hint,
  children,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="body2" sx={{ fontWeight: 800 }}>
          {title}
        </Typography>
        <Tooltip title={hint} placement="right">
          <HelpOutlineIcon
            fontSize="small"
            sx={{ ml: 0.5, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
      {children}
    </Box>
  );
};
