import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tSubscribeRes } from "../../shared/types";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";

interface DialogSubmitCompletionProps {
  data: tSubscribeRes;
  onClickOk: (subscriptionActive: boolean) => void;
}

export const DialogSubmitCompletion: React.FC<DialogSubmitCompletionProps> = ({
  data,
  onClickOk,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "dialogs" });
  const subscriptionActive = data.status === "active";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        maxWidth: "400px",
      }}
    >
      <Typography variant="caption">Subscription {data.status}</Typography>
      <Box mb="1rem">
        {subscriptionActive ? (
          <Success
            data={[
              { label: "Id", data: data.subscriptionId },
              { label: "Price", data: `${data.price}/${data.billingCycle}` },
              { label: "Start of billing cycle", data: data.billingStartDate },
            ]}
          />
        ) : (
          <Failure errorMsg={data.error!} />
        )}
      </Box>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          variant="contained"
          sx={{ flexGrow: 1, m: "auto" }}
          onClick={() => onClickOk(subscriptionActive)}
        >
          {t("ok")}
        </Button>
      </Box>
    </Box>
  );
};

const Success: React.FC<{ data: { label: string; data: string }[] }> = ({
  data,
}) => (
  <React.Fragment>
    <Typography variant="h2" gutterBottom>
      Payment successful
    </Typography>
    <CheckBoxOutlinedIcon sx={{ fontSize: "3rem" }} />
    <Box sx={{ m: 1 }}>
      <Typography gutterBottom>
        Your order was successfully processed.
      </Typography>
      <Typography gutterBottom>
        An automated payment receipt will be sent to your registered email.
        Thank you for your payment.
      </Typography>
    </Box>
    <Typography variant="caption">Subscription details</Typography>
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {data.map((item) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="caption"
            sx={{
              width: "175px",
              textAlign: "right",
              mr: 2,
              textTransform: "uppercase",
            }}
          >
            {item.label}
          </Typography>
          <Typography variant="body2">{item.data}</Typography>
        </Box>
      ))}
    </Box>
  </React.Fragment>
);

const Failure: React.FC<{ errorMsg?: string }> = ({ errorMsg }) => (
  <React.Fragment>
    <Typography variant="h2">Payment failed</Typography>
    <ReportGmailerrorredOutlinedIcon sx={{ fontSize: "3rem", m: 1 }} />
    <Typography gutterBottom>
      Unfortunately we were unable to process your order and charge your credit
      card for a subscription{errorMsg ? " with the following reason:" : "."}
    </Typography>
    {errorMsg && <Typography fontWeight={500}>{errorMsg}</Typography>}
  </React.Fragment>
);
