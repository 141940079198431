import { useEffect, useMemo, useState } from "react";
import { tDeck, tDeckConfig, tDeckData, tDeckInfo } from "../../shared/types";
import useOnSnapshotDeck from "../../hooks/useOnSnapshotDeck";
import { useAuth } from "./../AuthContext";
import { LessonData } from "./LessonData";

export type Deck = tDeck;

export interface DecksData {
  get: Deck[];
  swapItems: (fromIndex: number, toIndex: number) => void; // TODO: Unused
  update: (
    deckId: string,
    value: Partial<Deck> | ((prev: Deck) => Deck)
  ) => void;
  find: (deckId: string) => Deck | undefined;
}

export default function useDecksData(lesson: LessonData): DecksData {
  const { user, subscribed } = useAuth();
  const [decks, setDecks] = useState<Deck[]>([]);

  const onSnapDeckData = useOnSnapshotDeck<tDeckData>("decks-data");
  const onSnapDeckConfig = useOnSnapshotDeck<tDeckConfig>("decks-config");
  const onSnapDeckInfo = useOnSnapshotDeck<tDeckInfo>("decks-info");

  useEffect(() => {
    if (user === null) {
      setDecks([]);
      return;
    }

    const unsubscribes = [
      onSnapDeckData(
        (deckId, data) => update(deckId, { data: data }),
        (deckId, data) => {
          // Closing learning session if it belongs to a deck that
          // is being marked as removed - it might happen when a user
          // has two instances of the app opened and is removing the deck
          if (data.removed && lesson.deckId === deckId) lesson.clear();
        }
      ),
      onSnapDeckConfig((deckId, data) => update(deckId, { config: data })),
      onSnapDeckInfo((deckId, data) => update(deckId, { info: data })),
    ];

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe?.());
    };
  }, [user, subscribed, lesson.deckId]);

  const update = (
    deckId: string,
    value: Partial<Deck> | ((prev: Deck) => Deck)
  ) => {
    setDecks((prev) => {
      const deck = prev.find((deck) => deck.id === deckId)!;
      const newDecks = prev.filter((deck) => deck.id !== deckId);
      if (typeof value === "function") return [...newDecks, value(deck)];
      else return [...newDecks, { ...deck, ...value, id: deckId } as Deck];
    });
  };

  const get = useMemo(
    () => decks.filter((deck) => !deck.data?.removed),
    [decks]
  );

  const swapItems = (fromIndex: number, toIndex: number) => {
    const newDecks = [...get];
    const [movedItem] = newDecks.splice(fromIndex, 1);
    newDecks.splice(toIndex, 0, movedItem);
    setDecks(newDecks);
  };

  const find = (deckId: string) => decks.find((deck) => deck.id === deckId);

  return {
    get,
    swapItems,
    update,
    find,
  };
}
