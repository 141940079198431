import React, { FC, useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styled from "@mui/material/styles/styled";
import { FlashCardProps } from "./FlashCard";
import { CardSettingsMenu } from "./CardSettingsMenu";
import Paper from "@mui/material/Paper";
import { useCardSettingsMenuItems } from "./useCardSettingsMenuItems";

const CardSettingsButton = styled(IconButton)({
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: 10,
});

export const CardSettings: FC<{ use: FlashCardProps }> = ({ use }) => {
  const { answerShown } = use;
  const [showMenu, setShowMenu] = useState(false);
  const refSettings = useRef<HTMLButtonElement>(null);
  const menuItems = useCardSettingsMenuItems(use);

  useEffect(() => {
    setShowMenu(false);
  }, [answerShown]);

  return (
    <React.Fragment>
      <CardSettingsButton
        ref={refSettings}
        color="primary"
        size="large"
        onClick={() => setShowMenu(!showMenu)}
      >
        <MoreVertIcon />
      </CardSettingsButton>
      <Popper
        open={showMenu}
        anchorEl={refSettings.current}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: 10 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setShowMenu(false)}>
            <Fade {...TransitionProps} timeout={250}>
              <Paper sx={{ marginRight: "0.5rem" }}>
                <CardSettingsMenu
                  items={menuItems}
                  onActionClicked={() => setShowMenu(false)}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};
