import * as React from "react";
import { useAppData } from "../../contexts/AppData/AppDataContext";
import { IndexDbCard } from "../../components/DeckItem/useFetchCards";
import { Deck } from "../../contexts/AppData/DecksData";

export type SearchQueryArgs = {
  query: string;
  selected?: Deck | IndexDbCard;
};

export type SearchQueryRes = {
  items: (IndexDbCard | Deck)[];
  breakIdx?: number;
};

export function useSearchQuery(args: SearchQueryArgs): SearchQueryRes {
  const { query, selected } = args;
  const { decks, indexedDb } = useAppData();
  const [items, setItems] = React.useState<SearchQueryRes>({ items: [] });

  React.useEffect(() => {
    const allCards = indexedDb.getData<IndexDbCard>("cards") ?? [];

    // User has selected one of the items from the search list.
    // Based on selected variable we're returning selected card's
    // or deck's data to be presented
    if (selected) {
      const isCard = (selected as IndexDbCard).deckId;
      setItems({
        items: isCard
          ? allCards.filter((c) => c.id === selected.id)
          : allCards
              .filter((c) => selected.id === c.deckId)
              .sort((a, b) => a.word.localeCompare(b.word)),
      });
      return;
    }

    // If the user hasn't selected any item from the search list then
    // the query input string is being used to match the cards and the decks

    const lowCaseQuery = query.toLowerCase();

    const cardsList = allCards
      .filter((card) => card.word.toLowerCase().includes(lowCaseQuery))
      .sort((a, b) => a.word.localeCompare(b.word));

    const decksList = decks.get
      .filter((deck) => deck.info.name.toLowerCase().includes(lowCaseQuery))
      .sort((a, b) => a.info.name.localeCompare(b.info.name));

    setItems({
      items: [...cardsList, ...decksList],
      ...(cardsList.length > 0 ? { breakIdx: cardsList.length } : {}),
    });
  }, [query]);

  return items;
}
