import * as React from "react";
import Typography from "@mui/material/Typography";
import { BookCover } from "../../components/BookItem/BookCover";
import { SxProps, Theme } from "@mui/material";
import { Deck } from "../../contexts/AppData/DecksData";
import ReactCountryFlag from "react-country-flag";
import { EntryBox } from "./EntryBox";

interface BookFieldProps {
  deck: Deck;
  minimal?: boolean;
  focused?: boolean;
  onClick?: (deck: Deck) => void;
  sx?: SxProps<Theme>;
}

export const DeckEntry = React.forwardRef<HTMLElement, BookFieldProps>(
  ({ deck, minimal, onClick, focused, sx }, ref) => {
    return (
      <EntryBox
        ref={ref}
        onClick={() => onClick?.(deck)}
        focused={focused!}
        hoverable={!minimal}
        sx={{
          display: "flex",
          flexDirection: minimal ? "row" : "row-reverse",
          justifyContent: "flex-end",
          alignItems: "center",
          cursor: onClick ? "pointer" : "default",
          userSelect: "none",
          ...sx,
        }}
      >
        {!minimal && deck.info.bookId && (
          <ReactCountryFlag
            draggable={false}
            countryCode={
              deck.info.targetLang === "en" ? "gb" : deck.info.targetLang
            }
            style={{
              width: "1rem",
              height: "1rem",
              marginRight: "0.5rem",
            }}
            svg
          />
        )}
        <Typography
          variant={minimal ? "caption" : "body1"}
          sx={!minimal ? { flexGrow: 1, textAlign: "left" } : {}}
        >
          {deck.info.name}
        </Typography>
        <BookCover
          coverUrl={deck.info.coverUrl}
          component="background"
          sx={{
            mx: 1,
            width: "2rem",
            height: "2em",
            boxShadow: "none",
            borderRadius: (theme) => theme.shape.borderRadius / 12,
          }}
        />
      </EntryBox>
    );
  }
);
