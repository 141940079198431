import React, { ReactNode, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  IdTokenResult,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import { auth, functions } from "../firebase";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";
import { checkInternetConnection } from "../common/browser";
import { useAlert } from "./Alert/AlertContext";
import { c_err, c_log } from "../shared/console";

type OnAuthChangedCallback = (user: User | null) => void;

interface AuthContextType {
  user: User | null;
  subscribed: boolean;
  refreshUserToken: () => Promise<IdTokenResult | undefined>;
  signinGoogle: () => Promise<User>;
  signout: () => Promise<void>;
  setOnAuthChanged: (cb: OnAuthChangedCallback) => void; // TODO: is needed, as user state can alawys be checked via useEffect ... [user]??
  callCloudFn: <Request = unknown, Response = unknown>(
    functionName: string,
    data?: Request | undefined
  ) => Promise<HttpsCallableResult<Response>>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}

export const AuthProvider: React.FC<{
  // TODO: is onAuthChanged prop still necessary as the introduction of
  //       AuthContextType.setOnAuthChanged duplicates this functionality
  onAuthChanged?: OnAuthChangedCallback;
  children: ReactNode;
}> = ({ onAuthChanged, children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [authChanged, setAuthChanged] = useState<OnAuthChangedCallback | null>(
    null
  );
  const [subscribed, setSubscribed] = useState(true);
  // const { showAlert } = useAlert();

  const signinGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);

    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;

    setCurrentUser(result.user);
    // IdP data available using getAdditionalUserInfo(result)
    return result.user;
  };

  const signout = async () => {
    await signOut(auth);
    setCurrentUser(null);
  };

  const setOnAuthChanged = (cb: OnAuthChangedCallback) => setAuthChanged(cb);

  const callCloudFn = async <Request = unknown, Response = unknown>(
    functionName: string,
    data?: Request
  ): Promise<HttpsCallableResult<Response>> => {
    try {
      if (!checkInternetConnection()) throw new Error("No internet connection");

      c_log(`Calling cloud function: ${functionName}`);
      const httpsCall = httpsCallable<Request, Response>(
        functions,
        functionName
      );
      return await httpsCall(data);
    } catch (error) {
      c_err(error);
      if (error instanceof FirebaseError) {
        if (error.code === "functions/permission-denied") {
          // Revoking the current token so that the information about
          //  subscription  status be taken into account
          await refreshToken(currentUser);
        }
      }
      //else showAlert(`${error}`, "error"); // TODO: Merge AlertContext with AppData and Auth?

      throw error;
    }
  };

  const refreshToken = async (user: User | null) => {
    const res = await user?.getIdTokenResult(true);
    c_log("Token refreshed, customClaims", res?.claims);
    const sub = res?.claims["subscription"];
    if (sub) setSubscribed(sub === "active");
    return res;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      c_log("onAuthStateChanged", currentUser, user);

      const res = await refreshToken(user);
      const sub = res?.claims["subscription"];
      if (sub && sub !== "active") setSubscribed(false);

      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  // Watch it! Defining lambda without { } was triggering a compilation error
  useEffect(() => {
    onAuthChanged?.(currentUser);
    authChanged?.(currentUser);
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        subscribed,
        refreshUserToken: () => refreshToken(currentUser),
        signinGoogle,
        signout,
        setOnAuthChanged,
        callCloudFn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
