import React, { FC, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  CircularIntegration,
  RefCircularIntegration,
} from "../../components/CircularItegration";
import CheckIcon from "@mui/icons-material/Check";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Pagination from "@mui/material/Pagination";
import { c_err, c_log } from "../../shared/console";

interface WordProps {
  text: string;
  onSelected: (word: string) => void;
}

const Word: FC<WordProps> = (props) => {
  const [selected, setSelected] = useState(false);
  const { text, onSelected } = props;
  return (
    <Typography
      component="span"
      onClick={() => {
        const noSpecialChar = text.replace(/[^a-zA-Z-' ]/g, "");
        if (noSpecialChar.length === 0) return;
        setSelected(!selected);
        onSelected(noSpecialChar);
      }}
      sx={{
        color: selected ? "success.main" : "text.primary",
        fontWeight: selected ? "bold" : "none",
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      {`${text} `}
    </Typography>
  );
};

interface OcrPageProps {
  page: string[][];
  onSelected: (word: string) => void;
}

const OcrPage: FC<OcrPageProps> = (props) => {
  const { page, onSelected } = props;
  return (
    <React.Fragment>
      {page.map((paragraph) => (
        <Typography fontSize={18} align="left" m={3}>
          {paragraph.map((word) => (
            <Word text={word} onSelected={onSelected} />
          ))}
        </Typography>
      ))}
    </React.Fragment>
  );
};

export function ImportImagePage() {
  const searchQuery = useOutletContext<string>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { text }: { text: string[][][] } = state ?? { text: [] };
  const [loading, setLoading] = useState(false);
  const refImportBtn = useRef<RefCircularIntegration>(null);
  const [wordsSelected, setWordsSelected] = useState<string[]>([]);
  const [page, setPage] = useState(1);

  return text.length === 0 ? (
    <Typography>List is empty</Typography>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {wordsSelected.length > 0 && (
        <CircularIntegration
          ref={refImportBtn}
          iconNormal={<CheckIcon />}
          iconBusy={<HourglassEmptyIcon />}
          timeout={30000}
          onClick={async () => {
            try {
              setLoading(true);
              c_log(`Creating decks...`);
            } catch (err) {
              setLoading(false);
              refImportBtn.current?.SetLoading(false);
              c_err(err); // TODO: Present failure alert to the user
            }
          }}
        />
      )}
      {text.length > 1 && (
        <Pagination
          count={text.length}
          page={page}
          onChange={(_, idx) => setPage(idx)}
          color="primary"
        />
      )}
      <Paper
        elevation={3}
        sx={{
          m: "1rem",
          p: "2rem",
          minWidth: 300,
          maxWidth: 800,
          overflow: "auto",
        }}
      >
        <OcrPage
          page={text[page - 1]}
          onSelected={(userSelection) => {
            if (wordsSelected.includes(userSelection)) {
              setWordsSelected((prev) =>
                prev.filter((curr) => curr !== userSelection)
              );
            } else setWordsSelected([...wordsSelected, userSelection]);
          }}
        />
        {/* {text[page - 1].map((paragraph) => {
          return (
            <Typography fontSize={18} align="left" m={3}>
              {paragraph.map((word) => (
                <Word
                  text={word}
                  onSelected={(userSelection) => {
                    if (wordsSelected.includes(userSelection)) {
                      setWordsSelected((prev) =>
                        prev.filter((curr) => curr !== userSelection)
                      );
                    } else setWordsSelected([...wordsSelected, userSelection]);
                  }}
                />
              ))}
            </Typography>
          );
        })} */}
      </Paper>
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {wordsSelected.map((word) => (
          <Chip key={word} label={word} sx={{ m: "0.5rem" }} />
        ))}
      </Box>
    </Box>
  );
}
