import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from "@mui/material/Paper";
import { ClickAwayListener, Fade, List, Popper } from "@mui/material";
import { NavBarButton } from "./NavBar";
import { MenuItem } from "./MenuItem";
import { useMenu } from "../../contexts/Alert/useMenu";

export const BurgerButton: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const refMenuButton = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <NavBarButton
        ref={refMenuButton}
        aria-label="menu"
        size="small"
        onClick={() => setShowMenu(!showMenu)}
      >
        <MenuIcon fontSize="small" />
      </NavBarButton>
      <BurgerMenu
        show={showMenu}
        refMenuButton={refMenuButton}
        onItemClick={(link) => {
          if (link) navigate(link);
          setShowMenu(false);
        }}
      />
    </React.Fragment>
  );
};

interface BurgerMenuProps {
  show: boolean;
  refMenuButton: React.RefObject<HTMLButtonElement>;
  onItemClick: (link: string | null) => void;
}

const BurgerMenu: FC<BurgerMenuProps> = ({
  show: showMenu,
  refMenuButton,
  onItemClick,
}) => {
  const menuItems = useMenu();

  return (
    <Popper
      open={showMenu}
      anchorEl={refMenuButton.current}
      placement="bottom-start"
      sx={{ zIndex: 20, width: "160px" }}
      transition
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={() => onItemClick(null)}>
          <Fade {...TransitionProps} timeout={150}>
            <Paper>
              <List>
                {menuItems.map((item) => (
                  <MenuItem
                    item={item}
                    expanded={true}
                    onClick={() => onItemClick(item.link)}
                  />
                ))}
              </List>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
