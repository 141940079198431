import { Outlet, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import { Box, useTheme } from "@mui/material/";
import { NavBar } from "../components/Menus/NavBar";
import useMobileView from "../hooks/useMobileView";
import MenuDrawer, { useMenuDrawer } from "../components/Menus/MenuDrawer";
import useAppBarHeight from "../hooks/useAppBarHeight";
import { CustomScrollBox } from "../components/CustomScrollBox";
import { useState } from "react";
import { alpha } from "@mui/material/styles";
import useParseKindle from "../hooks/useParseKindle";
import { c_log } from "../shared/console";
import backgroundImage from "../assets/background.jpg";
import { useCustomTheme } from "../contexts/CustomTheme/CustomThemeContext";
import { darken } from "@mui/material/styles";
import { useAlert } from "../contexts/Alert/AlertContext";

function App() {
  const { showAlert } = useAlert();
  const location = useLocation();
  const isMobile = useMobileView();
  const menuDrawerState = useMenuDrawer();
  const appBarHeight = useAppBarHeight();
  const { theme } = useCustomTheme();
  const parseKindle = useParseKindle();

  const [isDragging, setIsDragging] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100dvh" }}>
      <NavBar menuDrawerState={menuDrawerState} />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          overflow: "auto",
          backgroundImage: `url(${backgroundImage})`,
          backgroundBlendMode:
            theme.palette.mode === "light" ? "screen" : "multiply",
          backgroundColor: darken(
            theme.palette.primary.light,
            theme.palette.mode === "light" ? 0.1 : 0.87
          ),
        }}
      >
        {/* onKeyDown=
        {async (event) => {
          event.preventDefault();
          let charCode = String.fromCharCode().toLowerCase();
          if ((event.ctrlKey || event.metaKey) && charCode === "v") {
            const text = await navigator.clipboard.readText();
            c_log(text);
            navigate("/importimage", { state: { text: prepareText(text) } });
          }
        }} */}
        {!isMobile && <MenuDrawer use={menuDrawerState} />}
        <CustomScrollBox
          onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            if (location.pathname === "/import") setIsDragging(true);
          }}
          onDragLeave={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            if (location.pathname === "/import") setIsDragging(false);
          }}
          onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            if (location.pathname !== "/import") return;

            try {
              setIsDragging(false);

              if (e.dataTransfer.files.length === 0) return;

              const droppedFiles = Array.from(e.dataTransfer.files);
              c_log(droppedFiles);

              // TODO: check the extension of a file, check the MIME type
              // and trigger appropriate action (kindle parse, kobo parse etc)
              await parseKindle(droppedFiles[0]);
            } catch (error) {
              const { message } = error as Error;
              showAlert(message, "error");
            }
          }}
          scroll={{ marginTop: appBarHeight, width: 6 }}
          component="main"
          width="100%"
        >
          {isDragging && (
            <Box
              sx={{
                position: "fixed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                zIndex: 100,
                backgroundColor: alpha(theme.palette.secondary.main, 0.5),
              }}
            />
          )}
          <Container
            sx={{
              p: 0,
              height: "100%",
              paddingTop: `${appBarHeight}px`,
            }}
          >
            <Outlet />
          </Container>
        </CustomScrollBox>
      </Box>
    </Box>
  );
}

export default App;
