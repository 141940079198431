import { Box, SxProps, Theme } from "@mui/material";
import React, { useRef } from "react";
import useIntersecting from "./useIntersecting";

interface LazyBackgroundImage {
  src: string;
  alt: string;
  fallback?: string;
  disabled?: boolean;
  isDecolorized?: boolean;
  sx?: SxProps<Theme>;
}

const LazyBackgroundImage: React.FC<LazyBackgroundImage> = ({
  src,
  alt,
  fallback,
  disabled = false,
  isDecolorized = false,
  sx,
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const isVisible = useIntersecting(imageRef);

  const img = `url(${src})` + (fallback ? `, url(${fallback})` : null);

  return (
    <Box
      ref={imageRef}
      component={"div"}
      sx={{
        backgroundImage: isVisible ? img : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        userSelect: "none",
        width: "128px",
        filter:
          isDecolorized || disabled
            ? disabled
              ? `grayscale(1) brightness(10%)`
              : `grayscale(1)`
            : "normal",
        mixBlendMode: disabled ? "difference" : "normal",
        ...sx,
      }}
      aria-label={alt}
    />
  );
};

export default LazyBackgroundImage;
