import { FC, RefObject, useRef, useState } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface DragHandleProps {
  itemRef: RefObject<HTMLElement>;
  onDragStart: () => void;
  onDrag: (yOffset: number) => boolean;
  onDragEnd: () => void;
  visible?: boolean;
  invert?: boolean;
  sx?: BoxProps["sx"];
}

export const DragHandle: FC<DragHandleProps> = ({
  itemRef,
  onDragStart,
  onDrag,
  onDragEnd,
  visible = true,
  invert = false,
  sx,
}) => {
  const initialY = useRef(0);
  const [dragging, setDragging] = useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.cursor = "grabbing";
    initialY.current = e.clientY;
    setDragging(true);
    onDragStart();
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.cursor = "grab";
    itemRef.current!.style.top = `0px`;
    setDragging(false);
    onDragEnd();
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.cursor = "grabbing";

    // Calculate the vertical movement since drag start
    const verticalMovement = e.clientY - initialY.current;
    // console.log("offset:", verticalMovement);

    // Adjust the top position of the dragged item
    // draggableRef.current!.style.top = `${
    //   draggableRef.current!.offsetTop + verticalMovement
    // }px`;
    itemRef.current!.style.top = `${verticalMovement}px`;
    if (onDrag(verticalMovement)) {
      initialY.current = e.clientY;
    }
  };

  return (
    <Box
      component={"div"}
      draggable
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      sx={{
        position: "absolute",
        cursor: "grab",
        top: "50%",
        ...(!invert
          ? { left: 0, transform: "translateX(-100%) translateY(-50%)" }
          : { right: 0, transform: "translateX(100%) translateY(-50%)" }),
        color: ({ palette }) => palette.primary.dark,
        // opacity: visible && !dragging ? 1 : 0,
        opacity: visible ? 1 : 0,
        transition: (theme) =>
          theme.transitions.create("opacity", { duration: "0.2s" }),
        ...sx,
      }}
    >
      <DragIndicatorIcon fontSize="large" />
    </Box>
  );
};
