import { FC } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CardAudioState, useCardAudio } from "./useCardAudio";
import { CircularProgress } from "@mui/material";
import { tCard, tDeck } from "../../shared/types";
import { usePrimaryColor } from "./CardExamples";

interface SynonymProps {
  synonym: string;
  deck: tDeck;
  card: tCard;
  idx: number;
  minimal?: boolean;
}

export const Synonym: FC<SynonymProps> = ({
  synonym,
  deck,
  card,
  idx,
  minimal = false,
}) => {
  const color = usePrimaryColor();
  const audio = useCardAudio();

  const handleClick = () => {
    audio.play({ text: synonym, lang: deck.info.lang }, card.id, {
      name: "synonyms",
      idx,
    });
  };

  return !minimal ? (
    <Box sx={{ position: "relative" }}>
      <Chip
        key={idx}
        variant="filled"
        label={synonym}
        sx={{
          m: "0.25rem",
          textTransform: "lowercase",
          backgroundColor: alpha(color, 0.1),
        }}
        onClick={handleClick}
      />
      {audio.state === CardAudioState.Fetching && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            width: "16px",
            height: "16px",
          }}
        >
          <CircularProgress size="16px" />
        </Box>
      )}
    </Box>
  ) : (
    <Typography
      variant="body2"
      onClick={handleClick}
      sx={{
        textAlign: "left",
        mr: 1,
        // color: (theme) => theme.palette.primary.dark,
        color: (theme) => theme.palette.text.secondary,
        cursor: "pointer",
      }}
    >
      {synonym}
    </Typography>
  );
};
