import { useRef } from "react";
import Box from "@mui/material/Box";
import { StackedSection } from "../LandingPage/StackedSection";
import { SectionHeader } from "../LandingPage/SectionHeader";
import { useTranslation } from "react-i18next";
import { useImportPageItems } from "./useImportPageItems";

export const ImportPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "import_page" });
  const { t: tMenu } = useTranslation("translation", { keyPrefix: "menu" });
  const refBox = useRef<HTMLDivElement>(null);
  const items = useImportPageItems();

  return (
    <Box sx={{ width: "100%", m: "auto", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: { xs: undefined, md: "fixed" },
            width: { xs: "100%", md: "350px" },
            paddingLeft: { xs: 2, sm: 0 },
            paddingRight: { xs: 0, md: 2 },
          }}
        >
          <SectionHeader
            title={tMenu("import")}
            sx={{ paddingTop: 8, paddingRight: 8 }}
          >
            {t("source_selection")}
          </SectionHeader>
          <StackedSection items={items} outletRef={refBox} />
        </Box>
        <Box
          ref={refBox}
          sx={{
            marginLeft: { xs: 0, md: "375px" },
            marginTop: { xs: 0, md: "auto" },
            marginBottom: { xs: 0, md: "auto" },
            width: "100%",
            // backgroundColor: theme.palette.primary.light,
          }}
        />
      </Box>
      {/* <Typography sx={{ my: 2 }}>
        Introducing a revolutionary new feature for Kindle users - the ability
        to effortlessly convert your favorite books and documents into
        flashcards! With this feature, you can easily extract key information
        and concepts from your Kindle device and create customized flashcards to
        help you study and remember important details.
      </Typography>
      <Typography sx={{ my: 2 }}>
        Whether you're a student, professional, or just someone who loves to
        learn, this feature is the perfect tool for anyone looking to improve
        their memory and retention. Simply select the text you want to include
        on your flashcards, and our system will convert it into a format that's
        easy to study and review. Gone are the days of tedious note-taking and
        manual flashcard creation - with this feature, you can create customized
        study materials in just a few clicks.
      </Typography>
      <Typography sx={{ my: 2 }}>
        And best of all, our system is designed to work seamlessly with your
        Kindle device, so you can access your flashcards anytime, anywhere. So
        why wait? Start taking your studying to the next level today with this
        game-changing feature for Kindle. Try it out now and see the difference
        it can make in your learning and retention!
      </Typography>
      <SelectFileButton
        component={<Button />}
        multiple
        onFileSelected={parseImage}
      >
        Import Image
      </SelectFileButton> */}
    </Box>
  );
};
