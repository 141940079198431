import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAlert } from "../../contexts/Alert/AlertContext";
import { c_log } from "../../shared/console";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UrlLink from "./UrlLink";
import { useCustomTheme } from "../../contexts/CustomTheme/CustomThemeContext";
import { useTranslation } from "react-i18next";
import LingyLogo from "../../components/LingyLogo";
import backgroundImage from "../../assets/background.jpg";
import { darken } from "@mui/material/styles";
import { DialogSubmitCompletion } from "../../dialogs/DialogSubmitCompletion/DialogSubmitCompletion";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

export const SubscribePage: React.FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "subscription_page",
  });
  const { refreshUserToken, user, subscribed } = useAuth();
  const [searchParams] = useSearchParams();
  const { showAlert, showDialog } = useAlert();
  const { theme } = useCustomTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (subscribed) navigate("/decks");
  }, [subscribed]);

  useEffect(() => {
    c_log("searchParams", searchParams.get("status"));
    if (searchParams.get("status")) {
      refreshUserToken();
      showAlert(`User ${user?.displayName} subscription's status changed`);
    }
  }, [searchParams]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundBlendMode:
          theme.palette.mode === "light" ? "screen" : "multiply",
        backgroundColor: darken(
          theme.palette.primary.light,
          theme.palette.mode === "light" ? 0.1 : 0.87
        ),
      }}
    >
      <Container
        sx={{ display: "flex", flexDirection: "column", height: "100dvh" }}
      >
        <Box
          component="header"
          sx={{ display: "flex", justifyContent: "space-between", m: 2 }}
        >
          <LingyLogo />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: { xs: "wrap", md: "nowrap" },
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // flexBasis: "300px",
              flexGrow: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontWeight: 800,
                mb: 4,
                mr: "auto",
              }}
            >
              Subscribe to{" "}
              <span style={{ color: theme.palette.primary.main }}>
                Lingy.app
              </span>
              <br />
              US$4.99 per month
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mr: "auto" }}>
              {t("trial_over")}
            </Typography>
            <Typography sx={{ pb: 2 }}>{t("trial_over_desc")}</Typography>
            <Typography variant="h6" gutterBottom sx={{ mr: "auto" }}>
              {t("subscription")}
            </Typography>
            <Typography sx={{ pb: 1 }}>{t("subscription_desc")}</Typography>
            {/* <Button
            variant="contained"
            sx={{ m: 2 }}
            onClick={(event) => {
              event.preventDefault();
              // navigate("/subscription?status=success");
              navigate({
                pathname: "/subscription",
                search: createSearchParams({
                  status: "success",
                }).toString(),
              });
            }}
          >
            SUCCESS
          </Button>
          <Button
            variant="contained"
            sx={{ m: 2 }}
            onClick={(event) => {
              event.preventDefault();
              navigate({
                pathname: "/subscription",
                search: createSearchParams({
                  status: "failure",
                }).toString(),
              });

              // navigate("/subscription?status=success", {
              //   state: { status: "success" },
              // });
            }}
          >
            FAILURE
          </Button> */}
          </Box>
          <Elements stripe={stripePromise}>
            <CheckoutForm
              onSubmit={async (result) => {
                if (result === null) return;
                console.log(result);
                const closeDialog = showDialog(
                  <DialogSubmitCompletion
                    data={result}
                    onClickOk={async (subscriptionActive) => {
                      closeDialog();
                      if (subscriptionActive) await refreshUserToken();
                    }}
                  />
                );
              }}
            />
          </Elements>
        </Box>
        <Box
          component="footer"
          sx={{ display: "flex", justifyContent: "space-between", m: 2 }}
        >
          <Typography variant="body2">Copyright © 2023 Lingy.app</Typography>
          <Box>
            <UrlLink
              title="Terms of Service"
              url="https://stripe.com/en-gb-pl/legal/end-users"
            />
            <Box component="span" mx={1} />
            <UrlLink
              title="Privacy Policy"
              url="https://stripe.com/en-gb-pl/privacy"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
