import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint, useTheme } from "@mui/material";

const useMobileView = (breakpoint: Breakpoint | number = "sm"): boolean => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up(breakpoint));

  return !isDesktop;
};

export default useMobileView;
